import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "tasks",
    "chat",
    "contentContainer",
    "firstTab",
    "firstTabContent",
    "secondTab",
    "secondTabContent",
    "milesChart",
    "kmsChart"
  ];

  initialize() {
    if (
      this.hasContentContainerTarget &&
      this.contentContainerTarget.dataset.view == "chat"
    ) {
      this.toggleSecondTab();
    }
  }

  toggleFirstTab() {
    if (this.hasTasksTarget) {
      // For supporter task tab switch
      const background = this.contentContainerTarget;
      if (background.classList.contains("bg-white")) {
        background.classList.remove("bg-white");
        background.classList.add("bg-gray-4");
      }
      this.switchTab(
        this.chatTarget,
        this.tasksTarget,
        this.secondTabContentTarget,
        this.firstTabContentTarget
      );
      document.getElementById("title").classList.add("bg-gray-4");
      document.getElementById("bar").classList.add("bg-gray-4");
      document
        .getElementById("content-container")
        .classList.add("lg:grid", "grid-cols-1", "lg:grid-cols-3");
    } else {
      // For other generic tab switches
      this.switchTab(
        this.secondTabTarget,
        this.firstTabTarget,
        this.secondTabContentTarget,
        this.firstTabContentTarget
      );
    }
  }

  toggleSecondTab() {
    if (this.hasTasksTarget) {
      this.switchTab(
        this.tasksTarget,
        this.chatTarget,
        this.firstTabContentTarget,
        this.secondTabContentTarget
      );

      // For supporter task tab switch
      const background = this.contentContainerTarget;
      if (background.classList.contains("bg-gray-4")) {
        background.classList.remove("bg-gray-4");
        background.classList.add("bg-white");
      }

      document.getElementById("title").classList.remove("bg-gray-4");
      document.getElementById("bar").classList.remove("bg-gray-4");
      document
        .getElementById("content-container")
        .classList.remove("lg:grid", "grid-cols-1", "lg:grid-cols-3");
    } else {
      // For other generic tab switches
      this.switchTab(
        this.firstTabTarget,
        this.secondTabTarget,
        this.firstTabContentTarget,
        this.secondTabContentTarget
      );
    }
  }

  // Changes button colors and switches icons
  switchTab(inactiveTab, activeTab, hideContent, showContent) {
    showContent.classList.remove("hidden");
    hideContent.classList.add("hidden");

    if (activeTab.classList.contains("bg-transparent")) {
      activeTab.classList.remove("bg-transparent");
      activeTab.classList.remove("text-brand-blue");

      activeTab.classList.add("bg-brand-blue");
      activeTab.classList.add("text-white");

      inactiveTab.classList.remove("bg-brand-blue");
      inactiveTab.classList.remove("text-white");

      inactiveTab.classList.add("bg-transparent");
      inactiveTab.classList.add("text-brand-blue");
    }
  }

  switchChart(event) {
    if (event.currentTarget.value == "km") {
      this.kmsChartTarget.classList.remove("hidden");
      this.milesChartTarget.classList.add("hidden");
    } else {
      this.kmsChartTarget.classList.add("hidden");
      this.milesChartTarget.classList.remove("hidden");
    }
  }
}
