import Daily_actions_controller from '../daily_actions_controller'
export default class extends Daily_actions_controller {
  static targets = [
    'sloganOption',
    'sloganOptionError',
    'displaySlogan',
    'sloganOptionCustom',
    'mostHelpful',
    'leastHelpful',
    'helpfulError',
    'accomplishment',
    'accomplishmentCustom',
    'accomplishmentsError',
    'experience',
    'experienceError',
    'recommendation',
    'recommendationError'
  ]

  clearSlogans () {
    const radioButton = this.sloganOptionTargets.find(
      (target) => target.checked == true
    )
    if (!radioButton) {
      return
    }
    radioButton.checked = false
    this.clearRadioButton()
    this.sloganOptionCustomTarget.getElementsByTagName('input')[0].focus()
  }

  clearCustomField () {
    const customTarget = this.sloganOptionCustomTarget.getElementsByTagName('input')[0]
    customTarget.value = ''
    customTarget.name = ''
    this.sloganOptionCustomTarget.querySelector('#char-counter').innerHTML = 0
  }

  checkSlogan (event) {
    let filled = false
    const customTarget = this.sloganOptionCustomTarget.getElementsByTagName('input')[0]

    this.sloganOptionTargets.forEach((option) => {
      if (option.checked) {
        filled = true
        this.displaySloganTarget.innerHTML = option.value
      }
    })

    if (!filled && this.customTarget.value.length !== 0 ) {
      filled = true
      this.displaySloganTarget.innerHTML = this.customTarget.value
      customTarget.name = this.sloganOptionTarget.name
    }

    if (!filled) {
      this.throwError(event, this.sloganOptionErrorTarget)
    } else {
      this.sloganOptionErrorTarget.style.display = 'none'
    }
  }

  checkAccomplishments (event) {
    let filled = false

    const defaultInputs = this.accomplishmentTargets.filter(
      (target) => target.checked == true
    )

    const customInput = this.accomplishmentCustomTargets.find(
      (target) => target.value.length > 0
    )

    if (
      defaultInputs.length > 1 ||
      (defaultInputs.length == 1 && customInput)
    ) {
      filled = true
    }

    if (!filled) {
      this.throwError(event, this.accomplishmentsErrorTarget)
    } else {
      this.accomplishmentsErrorTarget.style.display = 'none'
    }
  }

  checkLastStep (event) {
    let filled = true

    if (this.experienceTarget.value.length == 0) {
      filled = false
      this.throwError(event, this.experienceErrorTarget)
      this.recommendationErrorTarget.style.display = 'none'
      this.showRequiredField(this.experienceTarget)
      return
    } else {
      this.experienceErrorTarget.style.display = 'none'
    }

    const checkedTarget = this.recommendationTargets.find(
      (target) => target.checked == true
    )

    if (!checkedTarget) {
      filled = false
      this.throwError(event, this.recommendationErrorTarget)
    } else {
      this.recommendationErrorTarget.style.display = 'none'
    }
  }

  checkHelpfulForm (event) {
    let filled = true
    const errors_to_add = []
    const errors_to_clear = []
    const elements_to_highlight = []

    if (this.mostHelpfulTarget.value.trim().length <= 0) {
      filled = false
      this.showRequiredField(this.mostHelpfulTarget)
    }

    if (this.leastHelpfulTarget.value.trim().length <= 0) {
      filled = false
      this.showRequiredField(this.leastHelpfulTarget)
    }

    if (!filled) {
      this.throwError(event, this.helpfulErrorTarget)
    } else {
      this.helpfulErrorTarget.style.display = 'none'
    }
  }

  throwError (event, target) {
    event.stopImmediatePropagation()
    target.style.display = 'block'
    target.scrollIntoView({ behavior: 'smooth' })
  }

  showRequiredField (target) {
    target.focus()
    target.blur()
  }

  selectOption (event) {
    // For setting color of custom radio button
    this.clearRadioButton()

    event.currentTarget.querySelector('circle').id = 'selected-flag'
    event.currentTarget.querySelector('circle').setAttribute('fill', '#503E9D')
  }

  clearRadioButton() {
    // For setting color of custom radio button
    if (document.querySelector('#selected-flag')) {
      document.querySelector('#selected-flag').setAttribute('fill', '#C6C5CE')
      document.querySelector('#selected-flag').id = ''
    }
  }

  enableSupporterResponseModal (event) {
    event.stopImmediatePropagation()
    MicroModal.show('enable-supporter-response')
  }
}
