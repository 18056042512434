import MicroModal from 'micromodal'
import Daily_actions_controller from '../daily_actions_controller'

export default class extends Daily_actions_controller {
  static targets = [
    'surgerySayingOption',
    'surgerySayingCustom',
    'customTextarea',
    'surgerySayingError',
    'displaySaying'
  ]

  checkSaying (event) {
    let filled = false
    let selected = ''

    if (this.surgerySayingCustomTarget.value.length !== 0) {
      filled = true
    }

    this.surgerySayingOptionTargets.forEach((option) => {
      if (option.checked) {
        filled = true
        selected = option.value
      }
    })

    if (!filled) {
      event.stopImmediatePropagation()
      this.surgerySayingErrorTarget.style.display = 'block'
      this.surgerySayingErrorTarget.scrollIntoView({ behavior: 'smooth' })
    } else {
      this.surgerySayingErrorTarget.style.display = 'none'
    }
  }

  clearSurgerySaying () {
    this.surgerySayingOptionTargets.forEach((option) => {
      option.checked = ''
    })
  }

  clearCustomField () {
    this.surgerySayingCustomTarget.value = ''

    this.customTextareaTarget.getElementsByClassName(
      'mdc-text-field-character-counter'
    )[0].innerHTML = '0 / 100'
  }

  fillSaying () {
    let saying = ''

    if (this.surgerySayingCustomTarget.value.length !== 0) {
      saying = this.surgerySayingCustomTarget.value
    } else {
      this.surgerySayingOptionTargets.forEach((option) => {
        if (option.checked) {
          saying = option.labels[0].innerText
        }
      })
    }

    this.displaySayingTarget.innerHTML = '"' + saying + '"'
  }

  enableSupporterResponseModal (event) {
    event.stopImmediatePropagation()
    MicroModal.show('enable-supporter-response')
  }
}
