// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// Shared imports between multiple entrypoints
import '../shared/shared_imports.js';

// Rails functionality
import * as ActiveStorage from '@rails/activestorage'
import LocalTime from 'local-time'

import '../channels'
import 'trix'
import '@rails/actiontext'

// Stimulus controllers
import '../controllers'

import '../components/screen-height-offset.js'
import '../components/custom-slider.js'
import '../components/auto-timeout.js'
import 'animate.css'
import '../components/template-input.js'

import.meta.glob('../src/*.js', { eager: true });

ActiveStorage.start()
LocalTime.start()

// ADD YOUR JAVASCRIPT HERE

// Start Rails UJS
// Rails.start()

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'
