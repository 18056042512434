import Daily_actions_controller from "../daily_actions_controller";
import MicroModal from "micromodal";

export default class extends Daily_actions_controller {
  static targets = [
    "initialModalContent",
    "yesModalContent",
    "noModalContent",
    "nextStepModalButton",
  ];

  openModal(event) {
    event.stopImmediatePropagation();
    MicroModal.show("nature-modal");
  }

  showYesContent() {
    this.initialModalContentTarget.style.display = "none";
    this.yesModalContentTarget.style.display = "block";
    this.nextStepModalButtonTarget.style.display = "block";
    document.getElementById("feelingOption").value = "yes";
  }

  showNoContent() {
    this.initialModalContentTarget.style.display = "none";
    this.noModalContentTarget.style.display = "block";
    this.nextStepModalButtonTarget.style.display = "block";
    document.getElementById("feelingOption").value = "no";
  }

  closeModal() {
    MicroModal.close("nature-modal");
  }
}
