import { Controller } from "stimulus";
import mixpanel from 'mixpanel-browser';

export default class extends Controller {
  connect() {
    mixpanel.init(gon.mixpanel_token)

    if (gon.user_id) {
      // Identify user after first sign in or sign up
      mixpanel.identify(gon.user_id)
    } else {
      // Track registration page view if on sign up page
      mixpanel.track('registration_page_view', { "version": gon.version })
    }
  }
}
