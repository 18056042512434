import { Controller } from 'stimulus'
import Rails from '@rails/ujs'
import MicroModal from 'micromodal'
import { parsePhoneNumber } from 'libphonenumber-js'

export default class extends Controller {
  static targets = [
    'phoneInput',
    'verifyButton',
    'resendButton',
    'codeInvalidMessage',
    'codeSentMessage',
    'nextButton',
    'invalidNumberError'
  ]

  initialize () {
    MicroModal.init({
      openTrigger: 'data-custom-open', // [3]
      closeTrigger: 'data-custom-close', // [4]
      openClass: 'is-open', // [5]
      disableScroll: true, // [6]
      disableFocus: false, // [7]
      awaitOpenAnimation: false, // [8]
      awaitCloseAnimation: false, // [9]
      debugMode: true // [10]
    })

    this.phoneInputTargets.forEach((target) => {
      intlTelInput(target, {
        // separateDialCode:true,
        utilsScript:
          'https://cdn.jsdelivr.net/npm/intl-tel-input@17.0.3/build/js/utils.js'
      })
    })
  }

  appendCode (event) {
    const input = event.currentTarget
    const iti = window.intlTelInputGlobals.getInstance(input)

    window.iti = iti

    if (!this.throwNumberError(window.iti.getNumber(), input)) {
      return
    }

    input.value = window.iti.getNumber()
  }

  verify (event) {
    const input = this.phoneInputTargets.find(
      (target) => target.id == 'phone' || target.id == 'phone-settings'
    )
    const iti = window.intlTelInputGlobals.getInstance(input)

    window.iti = iti
    const formData = new FormData()
    formData.append('mobile_number_unverified', window.iti.getNumber())

    if (!this.throwNumberError(window.iti.getNumber(), input)) {
      return
    }

    document
      .getElementById('verify-phone-number')
      .querySelector('#phone-number').innerHTML = window.iti.getNumber()
    MicroModal.show('verify-phone-number')

    Rails.ajax({
      type: 'post',
      url: event.target.dataset.path,
      data: formData,
      success: function (data) {
        data = JSON.parse(data)
        document
          .getElementById('verify-phone-number')
          .querySelector('#sid').value = data[1]
      },
      failure: function (data) {
        alert('Oops, something went wrong.')
      }
    })
  }

  resendCode (event) {
    const formData = new FormData()
    const successMessage = this.codeSentMessageTarget
    const errorMessage = this.codeInvalidMessageTarget

    formData.append('mobile_number_unverified', window.iti.getNumber())

    Rails.ajax({
      type: 'post',
      url: event.target.dataset.path,
      data: formData,
      success: function (data) {
        data = JSON.parse(data)
        document
          .getElementById('verify-phone-number')
          .querySelector('#sid').value = data[1]
        document
          .getElementById('verify-phone-number')
          .querySelector('#phone-number').innerHTML = window.iti.getNumber()

        successMessage.classList.remove('hidden')

        if (!errorMessage.classList.contains('hidden')) {
          errorMessage.classList.add('hidden')
        }
      },
      failure: function (data) {
        alert('Oops, something went wrong.')
      }
    })
  }

  throwNumberError (number, target) {
    // Checks if the phone number is valid and doesn't show
    // modal if invalid and makes the input border red
    try {
      const phoneNumber = parsePhoneNumber(number)
      if (phoneNumber.isValid() === false) {
        target.classList.add('phone-error')
        this.invalidNumberErrorTarget.classList.remove('invisible')
        return
      }
    } catch (err) {
      target.classList.add('phone-error')
      this.invalidNumberErrorTarget.classList.remove('invisible')
      return false
    }
    target.classList.remove('phone-error')
    if (this.hasInvalidNumberErrorTarget) {
      this.invalidNumberErrorTarget.classList.add('invisible')
    }

    return true
  }

  checkInput (event) {
    if (event.currentTarget.value.length < 7) {
      this.verifyButtonTarget.disabled = true
    } else {
      this.verifyButtonTarget.disabled = false
    }
  }

  disable (event) {
    if (event.currentTarget.checked) {
      this.phoneInputTarget.disabled = true
      this.phoneInputTarget.classList.add('bg-disable-light')
      this.disableButton(this.verifyButtonTarget)
      this.nextButtonTarget.disabled = false
      if (this.hasInvalidNumberErrorTarget) {
        this.invalidNumberErrorTarget.classList.add('invisible')
      }
    } else {
      if (this.phoneInputTarget.dataset.verifiedTarget != 'verified') {
        this.phoneInputTarget.disabled = false
        this.nextButtonTarget.disabled = true
        if (this.phoneInputTarget.value.length > 7) {
          this.verifyButtonTarget.disabled = false
        }
      }
      this.phoneInputTarget.classList.remove('bg-disable-light')
    }
  }

  disableButton (target) {
    target.disabled = true
    // Setting color incase of red border
    this.phoneInputTarget.classList.remove('phone-error')
  }
}
