import Daily_actions_controller from "../daily_actions_controller";

export default class extends Daily_actions_controller {
  static targets = [
    "breathingBreakOption",
    "breathingBreakCustom",
    "breathingBreakError",
  ];

  checkBreathingBreak(event) {
    let filled = false;

    if (
      this.breathingBreakCustomTarget.getElementsByTagName("input")[0].value
        .length !== 0
    ) {
      filled = true;
    }

    this.breathingBreakOptionTargets.forEach((option) => {
      if (option.checked) {
        filled = true;
      }
    });

    if (!filled) {
      event.stopImmediatePropagation();
      this.breathingBreakErrorTarget.style.display = "block";
      this.breathingBreakErrorTarget.scrollIntoView({ behavior: "smooth" });
    } else {
      this.breathingBreakErrorTarget.style.display = "none";
    }
  }

  clearBreathingItems() {
    this.breathingBreakOptionTargets.forEach((option) => {
      option.checked = "";
    });
  }

  clearCustomField() {
    this.breathingBreakCustomTarget.getElementsByTagName("input")[0].value = "";

    this.breathingBreakCustomTarget.querySelector(
      '#char-counter'
    ).innerHTML = "0";
  }
}
