import { Controller } from 'stimulus'

export default class extends Controller {
  email (event) {
    const errorTarget =
      event.currentTarget.parentElement.parentElement.querySelector(
        '.error-message'
      )
    const validRegex = /^[\w-\.+]+@([\w-]+\.)+[\w-]{2,4}$/

    if (
      event.currentTarget.value.match(validRegex) ||
      event.currentTarget.value === ''
    ) {
      if (errorTarget) {
        event.currentTarget.offsetParent.children[1].classList.remove(
          'mdc-text-field--invalid'
        )
        event.currentTarget.setCustomValidity('')
        errorTarget.remove()
      }
    } else if (!errorTarget) {
      event.currentTarget.offsetParent.children[1].classList.add(
        'mdc-text-field--invalid'
      )
      event.currentTarget.setCustomValidity('invalid email')
      const error = document.createElement('div')
      error.innerHTML = 'Enter a valid email address'
      error.classList.add(
        'text-sm',
        'text-system-red',
        'error-message',
        'absolute'
      )

      event.currentTarget.parentElement.after(error)
    }
  }
}
