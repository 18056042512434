import Daily_actions_controller from "../daily_actions_controller";

export default class extends Daily_actions_controller {
  static targets = ["input", "submitButton"];

  checkInputs() {
    let filled = true;

    this.inputTargets.forEach((element) => {
      if (element.value.length === 0) {
        filled = false;
        return;
      }
    });

    if (filled) {
      this.submitButtonTarget.disabled = false;
    } else {
      this.submitButtonTarget.disabled = true;
    }
  }
}
