// Used for bottom text shown on edit and delete icons on hover
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["text"];
  showText() {
    this.textTarget.classList.remove("sm:invisible");
  }

  hideText() {
    this.textTarget.classList.add("sm:invisible");
  }
}
