import Daily_actions_controller from '../daily_actions_controller'

export default class extends Daily_actions_controller {
  static targets = [
    'overallInput',
    'overallInputCustom',
    'overallInputError',
    'recoveryInput',
    'recoveryInputCustom',
    'recoveryInputError',
    'medicationName',
    'medicationDosage',
    'medicationFrequency',
    'medicationsListError',
    'medicationInput',
    'medicationInputCustom',
    'displayOverallQuestions',
    'displayRecoveryQuestions',
    'displayMedicationQuestions',
    'displayQuestionCard',
    'medication',
    'medicationTable',
    'medicationTableRow',
    'medicationTableBody'
  ]

  initialize(){
    this.autofillMedications()
  }

  displayInputs () {
    const questionCard =
      this.displayQuestionCardTarget.content.querySelector('div')
    this.displayOverallQuestionsTarget.innerHTML = ''
    this.displayRecoveryQuestionsTarget.innerHTML = ''
    this.displayMedicationQuestionsTarget.innerHTML = ''
    this.medicationTableBodyTarget.innerHTML = ''

    let overallInputsPresent = false
    this.overallInputTargets.forEach((checkbox) => {
      if (checkbox.checked) {
        overallInputsPresent = true
        // creates new card with values
        const newCard = questionCard.cloneNode(true)
        newCard.innerHTML = checkbox.labels[0].innerText

        // appends it to step 4 location
        this.displayOverallQuestionsTarget.appendChild(newCard)
      }
    })

    this.overallInputCustomTargets.forEach((input) => {
      if (input.value.length === 0) return

      overallInputsPresent = true
      const newCard = questionCard.cloneNode(true)
      newCard.innerHTML = input.value

      // appends it to step 4 location
      this.displayOverallQuestionsTarget.appendChild(newCard)
    })

    if (overallInputsPresent === true) {
      this.displayOverallQuestionsTarget.parentElement.style.display = 'block'
    } else {
      this.displayOverallQuestionsTarget.parentElement.style.display = 'none'
    }

    let recoveryInputsPresent = false
    this.recoveryInputTargets.forEach((checkbox) => {
      if (checkbox.checked) {
        recoveryInputsPresent = true

        // creates new card with values
        const newCard = questionCard.cloneNode(true)
        newCard.innerHTML = checkbox.labels[0].innerText

        // appends it to step 4 location
        this.displayRecoveryQuestionsTarget.appendChild(newCard)
      }
    })

    this.recoveryInputCustomTargets.forEach((input) => {
      if (input.value.length === 0) return
      recoveryInputsPresent = true

      const newCard = questionCard.cloneNode(true)
      newCard.innerHTML = input.value

      // appends it to step 4 location
      this.displayRecoveryQuestionsTarget.appendChild(newCard)
    })

    if (recoveryInputsPresent === true) {
      this.displayRecoveryQuestionsTarget.parentElement.style.display = 'block'
    } else {
      this.displayRecoveryQuestionsTarget.parentElement.style.display = 'none'
    }

    // Medication Section
    let medicationInputsPresent = false
    let medicationsPresent = false

    this.medicationInputTargets.forEach((checkbox) => {
      if (checkbox.checked) {
        medicationInputsPresent = true

        // creates new card with values
        const newCard = questionCard.cloneNode(true)
        newCard.innerHTML = checkbox.labels[0].innerText

        // appends it to step 4 location
        this.displayMedicationQuestionsTarget.appendChild(newCard)
      }
    })

    this.medicationInputCustomTargets.forEach((input) => {
      if (input.value.length === 0) return
      medicationInputsPresent = true

      const newCard = questionCard.cloneNode(true)
      newCard.innerHTML = input.value

      // appends it to step 4 location
      this.displayMedicationQuestionsTarget.appendChild(newCard)
    })

    this.medicationTargets.forEach((med) => {
      let valuesPresent = true
      const displayText = []
      med.querySelectorAll('input, select').forEach((input) => {
        if (input.value.length === 0) {valuesPresent = false}

        let value = input.value
        if (input.classList.contains('frequency-field')) {
          value = input.querySelector(`option[value="${input.value}"]`).textContent
        }

        displayText.push(value)
      })

      if (valuesPresent === false) return

      medicationsPresent = true
      medicationInputsPresent = true

      const newRow = this.medicationTableRowTarget.cloneNode(true)

      let i = 0
      newRow.querySelectorAll('td').forEach((col) => {
        col.innerHTML = displayText[i]
        i++
      })

      this.medicationTableBodyTarget.appendChild(newRow)
    })

    if (medicationInputsPresent === true) {
      this.displayMedicationQuestionsTarget.parentElement.style.display =
        'block'
    } else {
      this.displayMedicationQuestionsTarget.parentElement.style.display =
        'none'
    }

    if (medicationsPresent === true) {
      const medicationTable = this.medicationTableTarget.cloneNode(true)
      medicationTable.style.display = 'block'
      this.displayMedicationQuestionsTarget.prepend(medicationTable)
    } else {
      this.medicationTableTarget.style.display = 'none'
    }
  }

  checkInput (event) {
    const targetName = event.currentTarget.dataset.target
    let filled = false

    const errorTarget = this[`${targetName}ErrorTarget`]

    const defaultInputs = this[`${targetName}Targets`].filter(
      (target) => target.checked == true
    )
    const customInputs = this[`${targetName}CustomTargets`].filter(
      (target) => target.value.length > 0
    )

    if (defaultInputs.length > 0 || customInputs.length > 0) {
      filled = true
    }

    if (!filled) {
      this.throwError(event, errorTarget)
    } else {
      errorTarget.style.display = 'none'
    }
  }

  checkMedications (event) {
    const nameCount = this.medicationNameTargets.filter(
      (el) => el.value != ''
    ).length
    const doseCount = this.medicationDosageTargets.filter(
      (el) => el.value != ''
    ).length
    const freqCount = this.medicationFrequencyTargets.filter(
      (el) => el.value != ''
    ).length

    if (nameCount == doseCount && doseCount == freqCount) {
      this.hideError(this.medicationsListErrorTarget)
    } else {
      this.throwError(event, this.medicationsListErrorTarget, 'center')
    }
  }

  hideError (target) {
    target.style.display = 'none'
  }

  throwError (event, target, position) {
    event.stopImmediatePropagation()
    target.style.display = 'block'
    target.scrollIntoView({ behavior: 'smooth', block: position })
  }

  autofillMedications (event) {
    if (localStorage.getItem(window.location) != null){
      const data = JSON.parse(localStorage.getItem(window.location));

      let rows = Math.max(data['medication[dosage][]'].length, data['medication[frequency][]'].length, data['medication[name][]'].length)
      
      for (let i = 0; i < rows; i++) {
        if(i==0) {
          document.querySelector('input[name="medication[name][]"]').value = data['medication[name][]'][i]
          document.querySelector('input[name="medication[dosage][]"]').value = data['medication[dosage][]'][i]
          document.querySelector('select[name="medication[frequency][]"]').value = data['medication[frequency][]'][i]
        }else{
          let new_row = document.getElementById('medication-input-remplate').content.cloneNode(true)
          new_row.querySelector('input[name="medication[name][]"]').value = data['medication[name][]'][i]
          new_row.querySelector('input[name="medication[dosage][]"]').value = data['medication[dosage][]'][i]
          new_row.querySelector('select[name="medication[frequency][]"]').value = data['medication[frequency][]'][i]

          document.getElementById('medication-inputs').appendChild(new_row)
        }
      }
    }
  }
}
