import { Controller } from 'stimulus'
import MicroModal from 'micromodal'

export default class extends Controller {
  static targets = [
    'movementForm',
    'hiddenForms',
    'durationHr',
    'durationMin',
    'distanceUnitInput',
    'measurement',
    'measurementToggle',
    'measurementUnit',
    'measurementUnitInput',
    'measurementType',
    'customDurationHr',
    'customDurationMin',
    'customMeasurement',
    'customMeasurementUnit',
    'saveButton',
    'customName',
    'movementsList',
    'movementDescription',
    'movementIcon',
    'movementError',
    'movementUnitError',
    'customMovementError',
    'customMovementUnitError',
    'customMovementNameError',
    'fieldClass',
    'customFieldClass'
  ]

  newCustomMovement () {
    // Index for custom movement labels, IDs...etc
    const index = this.movementsListTarget.childNodes.length - 10
    if (
      this.hiddenFormsTarget.querySelector(`.customMovement${index}`) !== null
    ) {
      this.hiddenFormsTarget.querySelector(`.customMovement${index}`).remove()
      return false
    }
    // Clear disabled fields for new movement
    this.customFieldClassTarget.querySelectorAll('input').forEach((target) => {
      target.disabled = false
      target.classList.remove('bg-disable-light')
    })

    const newForm = this.movementFormTarget.content.cloneNode(true)
    const formDiv = document.createElement('div')

    formDiv.classList.add(`customMovement${index}`)
    formDiv.appendChild(newForm)
    formDiv.querySelector('.movement-name').remove()

    MicroModal.show('custom-mindful-movement', {disableScroll: true})
    document.activeElement.blur()

    this.saveButtonTarget.dataset.movementName = `customMovement${index}`

    if (this.hiddenFormsTarget.lastChild === null) {
      formDiv.dataset.count = 0
    } else {
      formDiv.dataset.count =
        this.hiddenFormsTarget.lastChild.dataset.count + 1
    }

    this.hiddenFormsTarget.appendChild(formDiv)
    const withIndex = this.hiddenFormsTarget.lastChild.innerHTML.replace(
      /TEMPLATE_RECORD/g,
      this.hiddenFormsTarget.lastChild.dataset.count
    )
    this.hiddenFormsTarget.lastChild.innerHTML = withIndex
    // this.hiddenFormsTarget.lastChild.querySelector('.movement-name').value = event.currentTarget.dataset.movementName
  }

  newMovement (event) {
    // To know which movement checkbox to uncheck if user hits Cancel
    this.fieldClassTarget.dataset.target =
      event.currentTarget.getAttribute('for')

    // If user clicks activity when already checked, uncheck the checkbox
    const checkboxId = event.currentTarget.getAttribute('for')
    const checkbox = document.getElementById(checkboxId)

    // If user clicks activity card while it's checked, don't open modal
    if (checkbox.checked == true) {
      this.cancelMovement(event, true)
      return
    }

    const fields = [
      this.durationHrTarget,
      this.durationMinTarget,
      this.measurementTarget
    ]
    this.clearFields(fields)
    const movementName = event.currentTarget.dataset.movementName
    const newForm = this.movementFormTarget.content.cloneNode(true)
    const formDiv = document.createElement('div')
    this.movementErrorTarget.classList.add('hidden')

    // Clear disabled fields for new movement
    this.fieldClassTarget.querySelectorAll('input').forEach((target) => {
      target.disabled = false
      target.classList.remove('bg-disable-light')
    })

    // Check if activity was previously filled and remove those values
    if (this.hiddenFormsTarget.getElementsByClassName(`${movementName}`)[0]) {
      this.hiddenFormsTarget
        .getElementsByClassName(`${movementName}`)[0]
        .remove()
    }

    formDiv.classList.add(movementName)
    formDiv.appendChild(newForm)

    // NOTE: Mindful movement mobile bug
    // Strange bug on mobile browsers where the form gets duplicated and doesn't submit the values
    // Removing the last 5 fields which are duplicates so that the form is correct
    if (formDiv.childElementCount > 6) {
      for (let i = 0; i < 5; i++) {
        formDiv.removeChild(formDiv.lastElementChild)
      }
    }

    formDiv.querySelector('.movement-name').value = movementName
    formDiv.querySelector('.custom-movement-name').remove()

    // Set description of movement
    this.movementDescriptionTarget.innerHTML =
      event.currentTarget.dataset.movementDescription
    // Set icon of movement
    this.movementIconTarget.src =
      event.currentTarget.querySelector('.movement-icon').src

    // Checks the type of measurement
    if (!event.currentTarget.dataset.measurementToggle) {
      // If no measurement required, hide measurement toggle
      this.measurementToggleTarget.classList.add('hidden')
      this.fieldClassTarget.className = 'flex justify-center'
    } else {
      // Set the label of the measurement field. eg: reps, sets...etc
      this.measurementTypeTarget.innerHTML =
        event.currentTarget.dataset.measurementToggle
      if (event.currentTarget.dataset.measurementToggle == 'Distance') {
        // If distance, show distance units (km, mi)
        this.distanceUnitInputTarget.classList.remove('hidden')
        this.measurementTarget.setAttribute('inputmode', 'decimal')
        this.measurementTarget.maxLength = 6
        this.measurementTarget.pattern = '^\\d*(\\.\\d{0,2})?$'
        this.measurementTarget.placeholder = '00.00'
      } else {
        // If not distance type, hide distance units (km, mi)
        this.distanceUnitInputTarget.classList.add('hidden')
        this.measurementTarget.setAttribute('inputmode', 'numeric')

        this.measurementTarget.maxLength = this.fieldClassTarget.dataset.target == 'stairs' ? 3 : 2
        this.measurementTarget.pattern = '\\d*'
        this.measurementTarget.placeholder = this.fieldClassTarget.dataset.target == 'stairs' ? '000' : '00'
      }

      this.measurementToggleTarget.classList.remove('hidden')
      this.fieldClassTarget.className = 'grid grid-cols-1 gap-3 sm:grid-cols-2'
    }

    MicroModal.show('mindful-movement', {disableScroll: true})
    document.activeElement.blur()

    this.saveButtonTarget.dataset.movementName = movementName

    if (this.hiddenFormsTarget.lastChild === null) {
      formDiv.dataset.count = 0
    } else {
      formDiv.dataset.count =
        this.hiddenFormsTarget.lastChild.dataset.count + 1
    }

    this.hiddenFormsTarget.appendChild(formDiv)

    const withIndex = this.hiddenFormsTarget.lastChild.innerHTML.replace(
      /TEMPLATE_RECORD/g,
      this.hiddenFormsTarget.lastChild.dataset.count
    )
    this.hiddenFormsTarget.lastChild.innerHTML = withIndex
    this.hiddenFormsTarget.lastChild.querySelector('.movement-name').value =
      movementName
  }

  saveModalData (event) {
    const hiddenForm = this.hiddenFormsTarget.getElementsByClassName(
      `${this.saveButtonTarget.dataset.movementName}`
    )[0]

    this.validateAndFillForm(
      event,
      this.durationHrTarget,
      this.durationMinTarget,
      this.measurementTarget,
      hiddenForm,
      this.measurementUnitTargets,
      this.movementErrorTarget,
      this.movementUnitErrorTarget,
      null
    )
  }

  saveCustomModalData (event) {
    this.hiddenFormsTarget.lastChild.querySelector(
      '.custom-movement-name'
    ).value = this.customNameTarget.value

    // Index for custom movement labels, IDs...etc
    const index = this.movementsListTarget.childNodes.length - 10
    const hiddenForm = this.hiddenFormsTarget.querySelector(
      `.customMovement${index}`
    )

    const validated = this.validateAndFillForm(
      event,
      this.customDurationHrTarget,
      this.customDurationMinTarget,
      this.customMeasurementTarget,
      hiddenForm,
      this.customMeasurementUnitTargets,
      this.customMovementErrorTarget,
      this.customMovementUnitErrorTarget,
      this.customNameTarget
    )

    if (!validated) {
    }
  }

  validateAndFillForm (
    event,
    hourTarget,
    minuteTarget,
    measurementTarget,
    hiddenForm,
    unitTargets,
    errorTarget,
    unitErrorTarget,
    customNameTarget
  ) {
    let duration = null
    let measurement = null
    const measurementType =
      this.measurementTypeTargets[event.currentTarget.dataset.targetIndex]

    if (!hourTarget.disabled) {
      duration = this.getDuration(hourTarget, minuteTarget)
    }
    if (!measurementTarget.disabled) {
      measurement = measurementTarget.value
    }

    hiddenForm.querySelector('.movement-duration').value = duration
    hiddenForm.querySelector('.movement-measurement').value = measurement

    let unitType, unitTarget

    if (measurementType) {
      if (measurementType.innerHTML == 'Distance') {
        unitTarget = unitTargets.find(
          (target) => !target.disabled && target.checked
        )
        unitType = unitTarget?.value
      } else {
        unitType = measurementType.innerHTML.toLowerCase()
      }
    }

    const unit = unitType || null
    const validated = this.validateForm(
      duration,
      measurement,
      unit,
      event,
      errorTarget,
      unitErrorTarget,
      customNameTarget
    )
    if (!validated) {
      return false
    }

    this.setMeasurementUnit(unit, hiddenForm)

    // NOTE: this applies to custom activity only
    if (customNameTarget) {
      const index = this.movementsListTarget.childNodes.length - 10
      // Clone custom activity card with checkbox
      const template = Array.from(
        this.movementsListTarget.querySelectorAll('span')
      ).pop()
      const newMovement = template.cloneNode(true)
      this.movementsListTarget.insertBefore(newMovement, template)

      // Show cloned card, set IDs for checkboxes, add movement name on card
      const label = newMovement.getElementsByTagName('label')[0]
      const input = newMovement.getElementsByTagName('input')[0]
      label.classList.remove('hidden')
      label.setAttribute('for', `custom${index}`)

      this.fieldClassTarget.dataset.target = label.getAttribute('for')
      // Set ID for name and activity value display in custom card
      label.querySelector('.value-display').id = `custom${index}-card-display`
      label.querySelector('.optionText').id = `custom${index}-name`

      // Set custom name on activity card. Truncated to 24 characters
      label.querySelector('.optionText').innerHTML =
        this.customNameTarget.value.length > 24
          ? this.customNameTarget.value.substring(0, 24) + '...'
          : this.customNameTarget.value

      input.id += index
      input.value += index
      input.checked = true

      // Clear name input for next modal
      this.customNameTarget.value = ''
    }

    // Display entered value in the card under activity name
    this.showValueDisplay(
      hourTarget,
      minuteTarget,
      measurementTarget,
      unitTargets
    )

    // Clear modal for next mindful movement
    const fields = [hourTarget, minuteTarget, measurementTarget]
    this.clearFields(fields)
    if (unitTarget) {
      unitTarget.checked = false
    }
    // Scroll the movement card into view after saving
    const labelId = event.currentTarget.dataset.movementName
    const movementCard = document.querySelector('label[for=' + labelId + ']')
    if (movementCard) {
      movementCard.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }

    return true
  }

  showValueDisplay (hour, minute, measure, measure_unit) {
    // Shows the distance or duration on the activity card and change activity text
    const activityName = this.fieldClassTarget.dataset.target
    const nameTarget = document.getElementById(`${activityName}-name`)
    const displayTarget = document.getElementById(
      `${activityName}-card-display`
    )

    nameTarget.classList.remove('text-very-dark-gray', 'text-lg')
    nameTarget.classList.add('text-dark-gray', 'text-base')

    if (measure.disabled) {
      const hourDisplay = hour.value.length > 0 ? `${hour.value}h ` : ''
      const minDisplay = minute.value.length > 0 ? `${minute.value}m` : ''
      displayTarget.innerHTML = hourDisplay + minDisplay
    } else {
      let unit
      // If movement has a regular unit, use unit the checkbox value
      if (!measure_unit[0].parentNode.classList.contains('hidden')) {
        unit = measure_unit.find((target) => target.checked).value
      } else {
        unit = this.measurementTypeTarget.innerHTML.toLowerCase()
      }

      displayTarget.innerHTML = `${measure.value} ${unit}`
    }
    displayTarget.classList.remove('hidden')
  }

  clearFields (fields) {
    fields.forEach((field) => {
      field.value = null
    })
  }

  getDuration (hrTarget, minTarget) {
    if (hrTarget.value.length > 0 || minTarget.value.length > 0) {
      return (
        parseInt(hrTarget.value || '0') * 60 + parseInt(minTarget.value || '0')
      )
    } else {
      return null
    }
  }

  setMeasurementUnit (unit, hiddenForm) {
    // index to identify which unit checkbox to check
    if (!unit) {
      return
    }

    const unitInput = Array.from(
      hiddenForm.querySelectorAll('.movement-measurement-unit')
    ).find((target) => target.value == unit)

    unitInput.checked = true
  }

  cancelMovement (event, uncheck) {
    // Close current modal
    const closeModal = event.currentTarget.closest('.micromodal-slide')
    if (closeModal) MicroModal.close(closeModal.id)

    // Remove the distance or duration being displayed on the activity card and revert activity text styles
    const activityName = this.fieldClassTarget.dataset.target
    const nameTarget = document.querySelector(`#${activityName}-name`)
    const displayTarget = document.querySelector(
      `#${activityName}-card-display`
    )
    displayTarget.classList.add('hidden')
    nameTarget.classList.add('text-very-dark-gray', 'text-lg')
    nameTarget.classList.remove('text-dark-gray', 'text-base')

    // If user hits cancel, uncheck the checkbox
    const checkbox = document.getElementById(
      this.fieldClassTarget.dataset.target
    )

    // Uncheck only if dismissing modal.
    if (!uncheck) {
      checkbox.checked = false
    }

    // Check if activity was previously filled and remove those values
    if (this.hiddenFormsTarget.querySelector(`.${activityName}`) !== null) {
      this.hiddenFormsTarget.querySelector(`.${activityName}`).remove()
    }
  }

  validateForm (
    duration,
    measurement,
    unit,
    event,
    error,
    unitError,
    activityNameTarget
  ) {
    error.classList.add('hidden')
    unitError.classList.add('hidden')
    this.customMovementNameErrorTarget.classList.add('hidden')
    if (activityNameTarget) {
      activityNameTarget.classList.remove('custom-field-error')
    }

    if (!duration && !measurement) {
      error.classList.remove('hidden')
      event.stopImmediatePropagation()
      return false
    } else if (measurement && !unit) {
      unitError.classList.remove('hidden')
      event.stopImmediatePropagation()
      return false
    } else if (activityNameTarget && !activityNameTarget.value) {
      activityNameTarget.classList.add('field-error')
      this.customMovementNameErrorTarget.classList.remove('hidden')
      event.stopImmediatePropagation()
      return false
    } else {
      return true
    }
  }

  validateMaxValue (event) {
    const formTarget = event.currentTarget.closest('.movement-form')
    const minuteInput = formTarget.querySelector('#minute-field')
    const errorTarget = formTarget.querySelector('#max-value-error-target')
    // Hide all errors
    this.hideMovementErrors(event)
    // If minute input is disabled, don't show error
    if (minuteInput.disabled == true) {
      errorTarget.classList.add('hidden')
    } else if (parseInt(minuteInput.value) > parseInt(minuteInput.max)) {
      // If minute input is greater than max value, show error and stop
      errorTarget.classList.remove('hidden')
      event.stopImmediatePropagation()
    }
  }

  formatInput (event) {
    const input = event.currentTarget
    const inputType = input.getAttribute('inputmode')
    if (inputType == 'numeric') {
      // Prevent entering non-integer values
      setTimeout(function () {
        const oldVal = input.value
        const value = parseInt(oldVal)
        input.value = value
        if (Number.isInteger(value)) return
        input.value = ''
      }, 1)
    } else {
      // Allow decimals upto 2 decimals with a max value of 999.99
      const oldVal = input.value
      const regex = new RegExp(input.getAttribute('pattern'), 'g')
      setTimeout(function () {
        // Timeout is required to receive the new input
        const newVal = input.value
        if (
          !regex.test(newVal) ||
          (!newVal.includes('.') && newVal.length > 3)
        ) {
          input.value = oldVal
        }
      }, 25)
    }
  }

  toggleDuration (event) {
    if (event.currentTarget.disabled) return
    this.hideMovementErrors(event)
    if (event.currentTarget.id.includes('custom')) {
      this.disableMeasurement(
        this.customMeasurementTarget,
        this.customMeasurementUnitTargets
      )
      if (this.customDurationHrTarget.disabled == true) {
        this.enableDuration(
          this.customDurationHrTarget,
          this.customDurationMinTarget
        )
      }
    } else {
      this.disableMeasurement(
        this.measurementTarget,
        this.measurementUnitTargets
      )
      if (this.durationHrTarget.disabled == true) {
        this.enableDuration(this.durationHrTarget, this.durationMinTarget)
      }
    }
  }

  toggleMeasurement (event) {
    if (event.currentTarget.disabled) return
    this.hideMovementErrors(event)

    if (event.currentTarget.id.includes('custom')) {
      this.disableDuration(
        this.customDurationHrTarget,
        this.customDurationMinTarget
      )
      if (this.customMeasurementTarget.disabled == true) {
        this.enableMeasurement(
          this.customMeasurementTarget,
          this.customMeasurementUnitTargets
        )
      }
    } else {
      this.disableDuration(this.durationHrTarget, this.durationMinTarget)
      if (this.durationHrTarget.disabled == true) {
        this.enableMeasurement(
          this.measurementTarget,
          this.measurementUnitTargets
        )
      }
    }
  }

  disableMeasurement (measurement, measurementUnits) {
    this.disableField(measurement)
    measurementUnits.forEach((target) => {
      this.disableField(target)
      if (target.checked) {
        // Radio button disabled style while checked
        target.style.color = '#6b7280'
      }
    })
  }

  enableMeasurement (measurement, measurementUnits) {
    this.enableField(measurement)
    measurementUnits.forEach((target) => {
      this.enableField(target)
      if (target.checked) {
        // Radio button revert checked styling
        target.style.color = '#2563eb'
      }
    })
  }

  disableDuration (hour, minute) {
    this.disableField(hour)
    this.disableField(minute)
  }

  enableDuration (hour, minute) {
    this.enableField(hour)
    this.enableField(minute)
  }

  enableField (field) {
    field.disabled = false
    field.classList.remove('bg-disable-light')
  }

  disableField (field) {
    field.disabled = true
    field.classList.add('bg-disable-light')
  }

  clearCustomError (event) {
    if (event.currentTarget.value != '') {
      this.customNameTarget.classList.remove('field-error')
      this.customMovementNameErrorTarget.classList.add('hidden')
    }
  }

  hideMovementErrors (event) {
    const errorTarget = event.currentTarget
      .closest('.movement-form')
      .querySelector('#max-value-error-target')
    errorTarget.classList.add('hidden')
    let nextError = errorTarget.nextElementSibling
    while (nextError) {
      nextError = nextError.nextElementSibling
      errorTarget.nextElementSibling.classList.add('hidden')
    }
  }
}
