import Daily_actions_controller from '../daily_actions_controller'

export default class extends Daily_actions_controller {
  static targets = [
    'checkmark',
    'dateInput',
    'dateError',
    'surgeryDateError',
    'fillRecoveryGoal',
    'copyRecoveryGoal',
    'recoveryFeeling',
    'goalError',
    'feelingPhysically',
    'feelingEmotionally',
    'feelingEmotionallyFilter',
    'feelingPhysicallyFilter'
  ]

  toggleDateInput (event) {
    const nextButton = document.getElementsByClassName('date-next-button')[0]
    // Show/Hide Check Mark and Enable/Disable Input Form
    if (this.checkmarkTarget.classList.contains('hidden')) {
      this.checkmarkTarget.classList.remove('hidden')

      this.dateInputTarget.querySelectorAll('select').forEach((select) => {
        select.disabled = true
      })

      this.dateInputTarget
        .querySelectorAll('.mdc-text-field')
        .forEach((field) => {
          field.classList.add('mdc-text-field--disabled')
        })
      this.toggleButton(nextButton, 'enable')
      this.surgeryDateErrorTarget.style.display = 'none'
      this.dateErrorTarget.style.display = 'none'
    } else {
      this.checkmarkTarget.classList.add('hidden')

      this.dateInputTarget.querySelectorAll('select').forEach((select) => {
        select.disabled = false
      })

      this.dateInputTarget
        .querySelectorAll('.mdc-text-field')
        .forEach((field) => {
          field.classList.remove('mdc-text-field--disabled')
        })
      this.toggleButton(nextButton, 'disable')
    }
    this.checkDateValid(event)
  }

  fillRecoveryGoal (event) {
    const story =
      this.copyRecoveryGoalTarget.getElementsByTagName('textarea')[0].value
    this.fillRecoveryGoalTarget.innerHTML =
      '"After I recover from surgery, my goal is: ' + story + '"'
  }

  checkDateValid (event) {
    const dateKnown = this.checkmarkTarget.classList.contains('hidden')

    if (!dateKnown) {
      return
    }

    const nextButton = document.getElementsByClassName('date-next-button')[0]
    const date = this.dateInputTarget.getElementsByTagName('select')

    // Don't validate until all fields are filled
    if (date[0].value == '' || date[1].value == '' || date[2].value == '') {
      this.toggleButton(nextButton, 'disable')
      return
    }

    const surgeryDate = new Date(this.dateInputTarget.dataset.target)
    const todayEndOfDay = new Date(Date.now())
    const enteredDateStartOfDay = new Date(date[2].value, date[0].value - 1, date[1].value)
    // Check if entered date is in future or is before surgery date.
    if (enteredDateStartOfDay < todayEndOfDay) {
      this.throwError(event, this.dateErrorTarget, this.surgeryDateErrorTarget)
      this.toggleButton(nextButton, 'disable')
    } else if (enteredDateStartOfDay <= surgeryDate) {
      this.throwError(event, this.surgeryDateErrorTarget, this.dateErrorTarget)
      this.toggleButton(nextButton, 'disable')
    } else {
      this.toggleButton(nextButton, 'enable')
      this.surgeryDateErrorTarget.style.display = 'none'
      this.dateErrorTarget.style.display = 'none'
    }
  }

  checkValidGoal (event) {
    let filled = true

    if (
      this.copyRecoveryGoalTarget.getElementsByTagName('textarea')[0].value ===
      ''
    ) {
      filled = false
      this.showRequiredField(
        this.copyRecoveryGoalTarget.getElementsByTagName('textarea')[0]
      )
    }

    if (this.recoveryFeelingTarget.value === '') {
      filled = false
      this.showRequiredField(this.recoveryFeelingTarget)
    }

    if (!filled) {
      event.stopImmediatePropagation()
      this.goalErrorTarget.style.display = 'block'
    } else {
      this.goalErrorTarget.style.display = 'none'
    }
  }

  checkValidFeeling () {
    const lastButton = document.querySelector('#finalSubmitButton')
    let emotionallyFilled = false
    let physicallyFilled = false

    this.feelingEmotionallyTargets.forEach((target) => {
      if (target.checked) {
        emotionallyFilled = true
      }
    })

    this.feelingPhysicallyTargets.forEach((target) => {
      if (target.checked) {
        physicallyFilled = true
      }
    })

    if (emotionallyFilled && physicallyFilled) {
      lastButton.disabled = false
      lastButton.classList.remove('bg-disable', 'pointer-events-none')
      lastButton.classList.add('bg-violet', 'cursor-pointer')
    }
  }

  showRequiredField (target) {
    target.focus()
    target.blur()
  }

  throwError (event, target, secondTarget) {
    event.stopImmediatePropagation()
    target.style.display = 'block'
    target.scrollIntoView({ behavior: 'smooth' })
    secondTarget.style.display = 'none'
  }

  toggleButton (target, action) {
    if (action == 'enable') {
      target.disabled = false
      target.classList.remove('bg-disable', 'pointer-events-none')
      target.classList.add('bg-violet', 'cursor-pointer')
    } else {
      target.disabled = true
      target.classList.add('bg-disable', 'pointer-events-none')
      target.classList.remove('bg-violet', 'cursor-pointer')
    }
  }

  toggleSmiley (event) {
    let targetName = event.currentTarget.getAttribute('data-daily-actions--3-target');
    this.targets.findAll(targetName).forEach((target) => {
      target.classList.remove('hidden');
    });

    event.currentTarget.classList.add("hidden");
  }
}
