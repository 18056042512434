// Reconnect ActionCable after switching accounts

import MicroModal from 'micromodal'
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'quote',
    'content',
    'navigation',
    'wizard',
    'step',
    'progressBar',
    'line',
    'progress',
    'check',
    'unlockDay',
    'lockedModalContent',
    'availableModalContent',
    'hhLink',
    'daLink'
  ]

  static values = { stepIndex: Number }

  start() {
    window.scroll(0, 0)
    this.contentTarget.classList.remove('hidden')
    this.navigationTarget.classList.remove('hidden')
    this.wizardTarget.classList.remove('hidden')
    this.quoteTarget.classList.add('hidden')

    if (this.hasProgressBarTarget) {
      for (let i = 0; i < this.stepTargets.length - 1; i++) {
        this.progressBarTarget.insertAdjacentHTML(
          'beforeend',
          this.progressTarget.outerHTML
        )
        if (i != this.stepTargets.length - 2) {
          this.progressBarTarget.insertAdjacentHTML(
            'beforeend',
            this.lineTarget.outerHTML
          )
        }
      }
    }

    // Hide progress bar if only single step
    if (this.stepTargets.length == 1 && this.hasProgressBarTarget) {
      this.progressBarTarget.classList.add('hidden')
    } else {
      this.updateProgressBar()
    }
  }

  next(event) {
    // Hide current step and show next step
    const currentStep = this.stepTargets[this.stepIndexValue]
    this.stepIndexValue++
    const nextStep = this.stepTargets[this.stepIndexValue]
    const scrollAnchor = nextStep.querySelector('.scroll-anchor')

    if (event.currentTarget.id == 'response-modal-next') {
      MicroModal.close('enable-supporter-response')
    }
      
    if (currentStep) {
      currentStep.classList.add('hidden')
      if (this.stepIndexValue !== 0) currentStep.scrollIntoView()
    }

    if (nextStep) {
      nextStep.classList.remove('hidden')
      if (scrollAnchor) scrollAnchor.scrollIntoView()
    }

    this.updateProgressBar()
  }

  previous() {
    this.stepIndexValue--
    if (this.stepIndexValue < 0) this.stepIndexValue = 0

    // Hide current step and show previous step
    const currentStep = this.stepTargets[this.stepIndexValue + 1]
    const prevStep = this.stepTargets[this.stepIndexValue]

    if (prevStep) prevStep.classList.remove('hidden')

    currentStep.classList.add('hidden')
    if (this.stepIndexValue !== 0) currentStep.scrollIntoView()

    this.updateProgressBar()
  }

  updateProgressBar() {
    this.progressTargets.forEach((element, index) => {
      if (index <= this.stepIndexValue) {
        element.classList.remove('bg-lavender-gray', 'h-2', 'w-2')
        element.classList.add('bg-violet', 'h-6', 'w-6')
      } else {
        element.classList.remove('bg-violet', 'h-6', 'w-6')
        element.classList.add('bg-lavender-gray', 'h-2', 'w-2')
      }
    })

    this.checkTargets.forEach((element, index) => {
      if (index <= this.stepIndexValue) {
        element.classList.remove('hidden')
      } else {
        element.classList.add('hidden')
      }
    })

    this.lineTargets.forEach((element, index) => {
      if (index <= this.stepIndexValue && this.stepIndexValue != 0) {
        element.style.width = '12px'
      } else {
        element.style.width = '20px'
      }
    })
  }

  lockedModal(event) {
    const unlocksOn = event.currentTarget.dataset.unlock
    if (unlocksOn == 'available') {
      this.lockedModalContentTarget.classList.add('hidden')
      this.availableModalContentTarget.classList.remove('hidden')
    } else {
      this.lockedModalContentTarget.classList.remove('hidden')
      this.availableModalContentTarget.classList.add('hidden')

      if (unlocksOn) {
        this.unlockDayTarget.innerHTML = "It " + event.currentTarget.dataset.unlock + "."
      }
    }

    MicroModal.show('locked-da')
  }
}
