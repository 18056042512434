import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "form",
    "step",
    "back",
    "progressBar",
    "line",
    "renderProgressBar",
    "progress",
    "check",
  ];

  static values = { index: Number };

  initialize() {
    if (this.hasProgressBarTarget) {
      for (let i = 0; i < this.stepTargets.length - 1; i++) {
        this.progressBarTarget.insertAdjacentHTML(
          "beforeend",
          this.progressTarget.outerHTML
        );
        if (i != this.stepTargets.length - 2) {
          this.progressBarTarget.insertAdjacentHTML(
            "beforeend",
            this.lineTarget.outerHTML
          );
        }
      }
    }
    this.updateProgressBar();
  }

  next(event) {
    event.preventDefault();
    event.stopImmediatePropagation();

    this.indexValue++;
    // Check if we have reached the end of the form and submit
    if (this.indexValue >= this.stepTargets.length) {
      this.formTarget.submit();
    } else {
      // Hide current step and show next step
      const currentStep = this.stepTargets[this.indexValue - 1];
      currentStep.classList.add("hidden");
      const nextStep = this.stepTargets[this.indexValue];
      nextStep.classList.remove("hidden");

      this.updateProgressBar();
    }
  }

  previous(event) {
    event.preventDefault();
    event.stopImmediatePropagation();

    this.indexValue--;

    // Hide current step and show previous step
    const currentStep = this.stepTargets[this.indexValue + 1];
    currentStep.classList.add("hidden");
    const prevStep = this.stepTargets[this.indexValue];
    if (prevStep) prevStep.classList.remove("hidden");

    this.updateProgressBar();
  }

  updateProgressBar() {
    this.progressTargets.forEach((element, index) => {
      if (index <= this.indexValue) {
        element.classList.remove("bg-lavender-gray", "h-2", "w-2");
        element.classList.add("bg-violet", "h-6", "w-6");
      } else {
        element.classList.remove("bg-violet", "h-6", "w-6");
        element.classList.add("bg-lavender-gray", "h-2", "w-2");
      }
    });

    this.checkTargets.forEach((element, index) => {
      if (index <= this.indexValue) {
        element.classList.remove("hidden");
      } else {
        element.classList.add("hidden");
      }
    });

    this.lineTargets.forEach((element, index) => {
      if (index <= this.indexValue && this.indexValue != 0) {
        element.style.width = "12px";
      } else {
        element.style.width = "20px";
      }
    });

    this.renderProgressBarTargets.forEach((element) => {
      const progress = this.progressBarTarget.cloneNode(true);
      progress.classList.remove("hidden");

      element.innerHTML = "";
      element.appendChild(progress);
    });

    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }
}
