import { Controller } from "stimulus";
import { MaterialForms } from "../src/material_forms.js";

export default class extends Controller {
  connect() {
    // simple form does not play well with our custom array input type.
    // it adds the input fields in the same wrapper. but we need a wrapper for each.
    // our code below do that.

    const arrayFields = [].map.call(
      this.element.querySelectorAll(".form-field"),
      function (el) {
        const wrapper = el.parentNode;

        const inputFields = el.querySelectorAll("input");
        inputFields.forEach((item, i) => {
          const label = "Name";

          const singleWrapper = document.createElement("div");
          singleWrapper.className = "form-field mb-6";

          singleWrapper.appendChild(
            MaterialForms.generateMdcTextField(item, label)
          );

          singleWrapper.innerHTML +=
            '<a data-action="array-input#removeItem" href="#">Remove</a>';

          wrapper.insertBefore(singleWrapper, el);
        });

        el.remove();
      }
    );

    MaterialForms.init();
  }

  addItem(event) {
    event.preventDefault();

    const newItem = this.element
      .querySelector(".form-field:last-of-type")
      .cloneNode(true);
    newItem.querySelector("input").value = "";

    event.target.insertAdjacentElement("beforebegin", newItem);

    MaterialForms.init();
  }

  removeItem(event) {
    event.preventDefault();

    if (this.element.querySelectorAll(".form-field").length <= 1) {
      // prevent removing the last one
      return false;
    }

    const item = event.target.closest(".form-field");
    item.remove();
  }
}
