import { Controller } from "stimulus";
import { MaterialForms } from "../src/material_forms.js";

export default class extends Controller {
  static targets = ["list", "input", "add"];

  add() {
    let count = this.listTarget.childElementCount;
    const limit =
      this.listTarget.dataset.limit == null
        ? 20
        : this.listTarget.dataset.limit;

    if (count < limit) {
      const newInput = this.inputTarget.cloneNode(true);

      newInput.lastElementChild.style.display = "block";
      newInput.querySelector("input").value = "";
      this.listTarget.append(newInput);

      MaterialForms.init();
      count += 1;
      if (count == limit) this.addTarget.classList.add("hidden");
    }
  }

  remove(event) {
    event.currentTarget.parentElement.remove();
    this.addTarget.classList.remove("hidden");
  }

  disable(event) {
    // Final page target to be hidden if 'going by myself'
    const idName = event.currentTarget.dataset.target;
    const finalTarget = document.getElementById(idName);

    if (event.currentTarget.checked) {
      this.listTarget.classList.add("hidden");
      this.listTarget
        .querySelectorAll("input")
        .forEach((input) => (input.disabled = true));
      finalTarget.classList.add("hidden");
      if (this.hasAddTarget) {
        this.addTarget.classList.add("hidden");
      }
    } else {
      this.listTarget.classList.remove("hidden");
      this.listTarget
        .querySelectorAll("input")
        .forEach((input) => (input.disabled = false));
      finalTarget.classList.remove("hidden");
      if (this.hasAddTarget) {
        this.addTarget.classList.remove("hidden");
      }
    }
  }
}
