import Daily_actions_controller from '../daily_actions_controller'
import MicroModal from 'micromodal'

export default class extends Daily_actions_controller {
  validate (event) {
    let checkboxes = document.getElementsByClassName('contact-checkbox')
    checkboxes = Array.prototype.slice.call(checkboxes, 0)

    for (let i = 0, l = checkboxes.length; i < l; i++) {
      if (checkboxes[i].checked) {
        document.getElementById('error-message').classList.add('hidden')
        return true
        break
      }
    }

    document.getElementById('error-message').classList.remove('hidden')
    event.stopImmediatePropagation()
  }
}
