import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['show', 'hide', 'input']

  togglePasswordVisibility () {
    if (this.inputTarget.value === "") { return }

    if (this.inputTarget.type === 'password') {
      this.inputTarget.type = 'text'
      this.showTarget.classList.toggle('hidden')
      this.hideTarget.classList.toggle('hidden')
    } else {
      this.inputTarget.type = 'password'
      this.showTarget.classList.toggle('hidden')
      this.hideTarget.classList.toggle('hidden')
    }
  }
}
