import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "correct",
    "incorrect",
    "correctText",
    "incorrectText",
    "letter",
    // "next",
  ];

  // NOTE: the stuff thats commented out is for the next button. If we want it to be disabled before the user has answered the question, we can use this.

  // initialize() {
  //   this.nextTarget.classList.add("pointer-events-none", "bg-disable");
  // }

  correct() {
    this.correctTarget.classList.remove("text-very-dark-gray");
    this.correctTarget.classList.add("bg-system-green", "text-white");
    this.showTick("White");

    this.correctTextTarget.classList.remove("hidden");
    this.disableOtherAnswers();
    // this.enableButton();
  }

  incorrect(event) {
    event.target.classList.add("bg-system-red", "text-white");
    event.target.getElementsByClassName("letter")[0].classList.add("hidden");
    event.target
      .getElementsByClassName("incorrectIcon")[0]
      .classList.remove("hidden");

    this.correctTarget.classList.add("border-system-green");
    this.showTick("Green");

    this.incorrectTextTarget.classList.remove("hidden");
    this.disableOtherAnswers();
    // this.enableButton();
  }

  showTick(colour) {
    this.correctTarget
      .getElementsByClassName("letter")[0]
      .classList.add("hidden");
    this.correctTarget
      .getElementsByClassName(`correctIcon${colour}`)[0]
      .classList.remove("hidden");
  }

  disableOtherAnswers() {
    this.incorrectTargets.forEach((answer) => {
      delete answer.dataset.action;
    });
    delete this.correctTarget.dataset.action;
  }

  // enableButton() {
  //   this.nextTarget.classList.remove("pointer-events-none", "bg-disable");
  // }
}
