import { Controller } from 'stimulus'
import Rails from '@rails/ujs'
export default class extends Controller {
  static targets = [
    'form',
    'name',
    'assistanceType',
    'timeUnknown',
    'startToggle',
    'endToggle',
    'toMin',
    'fromMin',
    'toHour',
    'fromHour',
    'errorMessageTime',
    'errorMessageDate',
    'startDate',
    'endDate',
    'saveButton'
  ]

  initialize () {
    MicroModal.init({ disableScroll: true, disableFocus: true })
  }

  // Increment end-time of task by one hour relative to its start-time
  updateToHour () {
    // Update UI display time
    if (this.fromHourTarget.selectedIndex != 11) {
      this.toHourTarget.selectedIndex = this.fromHourTarget.selectedIndex + 1
    } else {
      this.toHourTarget.selectedIndex = this.fromHourTarget.selectedIndex
    }

    // AM/PM Toggle
    if (this.fromHourTarget.selectedIndex < 10) {
      this.endToggleTarget.checked = this.startToggleTarget.checked
    } else {
      this.endToggleTarget.checked = !this.startToggleTarget.checked
    }

    const hourValue =
      this.fromHourTarget.options[this.toHourTarget.selectedIndex].value
    // Update real-input
    if (this.startToggleTarget.checked) {
      const pmTime =
        hourValue != '12' ? String(parseInt(hourValue) + 12) : '00'
      this.endDateTargets[0].value = pmTime
    } else {
      this.endDateTargets[0].value = hourValue
    }
  }

  updateToMin () {
    // Update UI display time
    this.toMinTarget.selectedIndex = this.fromMinTarget.selectedIndex

    // Update real-input
    this.endDateTargets[1].value =
      this.fromMinTarget.options[this.toMinTarget.selectedIndex].value
  }

  validateForm (event) {
    this.clearErrorMessages()
    this.validateDate(event)

    if (this.timeUnknownTarget.checked == false) {
      this.validateTimes(event)
    }
  }

  clearErrorMessages () {
    this.errorMessageTimeTarget.classList.add('hidden')
    this.errorMessageDateTarget.classList.add('hidden')
  }

  validateDate (event) {
    const year = this.startDateTarget.querySelector(
      '#supporter_task_start_time_1i'
    ).value

    const month =
      parseInt(
        this.startDateTarget.querySelector('#supporter_task_start_time_2i')
          .value
      ) - 1

    const day = this.startDateTarget.querySelector(
      '#supporter_task_start_time_3i'
    ).value

    let enteredDate, today;

    // If anytime task, only compare dates
    if (this.timeUnknownTarget.checked == true) {
      enteredDate = new Date(year, month, day).setHours(0, 0, 0, 0)
      today = new Date().setHours(0, 0, 0, 0)
    } else {
      // If timed task, compare date and time
      let hours = parseInt(this.fromHourTarget.value)
      const minutes = this.fromMinTarget.value

      if (this.startToggleTarget.checked && hours <= 11) {
        hours += 12
      }

      if (!this.startToggleTarget.checked && hours == 12) {
        hours = 0
      }

      enteredDate = new Date(year, month, day, hours, minutes)
      today = new Date()
    }

    if (enteredDate < today) {
      this.errorMessageDateTarget.classList.remove('hidden')
      event.preventDefault()
    }
  }

  validateTimes (event) {
    // Check if both times are in AM/PM by checking the AM/PM toggle
    const bothTimesInAmOrPm =
      this.startToggleTarget.checked == this.endToggleTarget.checked

    // If the times are both in AM/PM, validate that the start time is before end time
    if (bothTimesInAmOrPm) {
      const fromTime = new Date().setHours(this.fromHourTarget.value, this.fromMinTarget.value)
      const toTime = new Date().setHours(this.toHourTarget.value, this.toMinTarget.value)

      if (fromTime > toTime) {
        this.errorMessageTimeTarget.classList.remove('hidden')
        event.preventDefault()
      }
    }
  }

  showNameField () {
    const assistanceSelect = this.assistanceTypeTarget

    if (
      assistanceSelect.options[assistanceSelect.selectedIndex].text == 'Other'
    ) {
      this.nameTarget.classList.remove('hidden')
    } else {
      this.nameTarget.classList.add('hidden')
    }
  }

  submitForm () {
    Rails.ajax({
      type: 'post',
      url: this.formTarget.action,
      data: new FormData(this.formTarget)
    })
  }

  confirmBooking (event) {
    MicroModal.show(event.target.dataset.modal)
    // event.target.previousElementSibling
  }

  cancelBooking (event) {
    MicroModal.show(event.target.dataset.modal)
  }

  disableSaveButton () {
    const target = this.saveButtonTarget
    target.disabled = true
    target.classList.add('bg-disable')
    target.classList.remove(
      'bg-dark-violet',
      'hover:bg-transparent',
      'hover:text-violet',
      'cursor-pointer',
      'border',
      'border-violet'
    )
  }

  enableSaveButton () {
    const target = this.saveButtonTarget
    target.disabled = false
    target.classList.remove('bg-disable')
    target.classList.add(
      'bg-dark-violet',
      'hover:bg-transparent',
      'hover:text-violet',
      'cursor-pointer',
      'border',
      'border-violet'
    )
  }

  checkInput () {
    if (this.assistanceTypeTarget.value == '') {
      this.disableSaveButton()
    } else {
      this.enableSaveButton()
    }
  }
}
