import Daily_actions_controller from '../daily_actions_controller'

export default class extends Daily_actions_controller {
  static targets = [
    'surgeryStoryError',
    'customStory',
    'templateStory',
    'draftStory',
    'draftStoryError',
    'finalStory',
    'finalStoryDisplay',
    'choice'
  ]

  checkStory (event) {
    const template_story_selected =
      this.customStoryTarget.classList.contains('hidden')
    const defaultInput =
      this.customStoryTarget.getElementsByTagName('textarea')[0]
    let show_error = false
    if (!template_story_selected && defaultInput.value.length < 100) {
      this.surgeryStoryErrorTarget.innerHTML =
        'Please write a surgery story with at least 100 characters.'
      show_error = true
    } else if (template_story_selected) {
      for (let i = 0; i < this.choiceTargets.length; i++) {
        if (this.choiceTargets[i].innerHTML.includes('&nbsp;')) {
          this.surgeryStoryErrorTarget.innerHTML =
            'Please fill in all the blanks to complete your surgery story.'
          show_error = true
          break
        }
      }
    }

    if (show_error == true) {
      event.stopImmediatePropagation()
      this.surgeryStoryErrorTarget.style.display = 'block'
      defaultInput.parentNode.nextElementSibling.classList.add(
        'mdc-text-field--invalid'
      )
      this.surgeryStoryErrorTarget.scrollIntoView({ behavior: 'smooth' })
    } else {
      defaultInput.parentNode.nextElementSibling.classList.remove(
        'mdc-text-field--invalid'
      )
      this.surgeryStoryErrorTarget.style.display = 'none'
    }
  }

  fillStory (event) {
    let story = ''

    // If not using our template
    if (!this.customStoryTarget.classList.contains('hidden')) {
      document.getElementById('finalStory').setAttribute('storyType', 'custom')

      story = this.customStoryTarget.getElementsByTagName('textarea')[0].value
      this.draftStoryTarget.innerHTML = story
      this.fillFinalStory()

      document.getElementById('next-btn').click()
    } else {
      // If using our template
      document
        .getElementById('finalStory')
        .setAttribute('storyType', 'template')
      story = this.templateStoryTarget.innerHTML
      // Strip divs, newlines..etc
      story = story
        .replace(
          /<template data-dropdown-fill-target="dropdown">((.|\n)*?)[[$&+,:;=?@#|'<>.^*()%!-]?.?(<\/template>)/g,
          ''
        )
        .replace(/<.*>/g, '')
        .replace(/\n+/g, ' ')
        .replace("It's scheduled", "\n\nIt's scheduled")
        .replace("I'm confident", "\n\nI'm confident")
        .trim()

      this.draftStoryTarget.innerHTML = story
      this.draftStoryTarget.value = story

      // Update character counter
      const letterCount =
        this.draftStoryTarget.innerHTML.length > 500
          ? 500
          : this.draftStoryTarget.innerHTML.length
      this.draftStoryTarget.nextSibling.innerHTML = `${letterCount} / ${this.draftStoryTarget.maxLength}`
    }
  }

  fillFinalStory () {
    // Update the value and displayed text to the draft story entered on the previous page
    this.finalStoryDisplayTarget.innerHTML = this.finalStoryTarget.value =
      this.draftStoryTarget.value
  }

  checkDraftStory (event) {
    if (this.draftStoryTarget.value.length < 100) {
      event.stopImmediatePropagation()
      this.draftStoryErrorTarget.style.display = 'block'
      this.draftStoryErrorTarget.scrollIntoView({ behavior: 'smooth' })
    } else {
      this.draftStoryErrorTarget.style.display = 'none'
    }
  }

  showOrSkipEditView () {
    // If custom story, skip 3rd step if clicking back on last step
    if (!this.customStoryTarget.classList.contains('hidden')) {
      document.getElementById('back-btn').click()
    }
  }
}
