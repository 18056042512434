import Daily_actions_controller from '../daily_actions_controller'
import { MaterialForms } from "../../src/material_forms.js"
import MicroModal from 'micromodal'

export default class extends Daily_actions_controller {
  static targets = [
    'supporterRow',
    'supporterCol',
    'displayRow',
    'displayCol',
    'email',
    'firstName',
    'lastName',
    'messageRealInput',
    'messageInput',
    'saveButtons',
    'editButton',
    'form',
    'counter',
    'defaultText',
    'revertButton',
    'supporterListError'
  ]

  initialize () {
    if (this.hasMessageInputTarget) {
      this.updateCounter()
    }

    this.autofillSupporters()
  }

  addSupporterRow () {
    const newRow = this.supporterRowTargets[0].cloneNode(true)

    newRow.querySelectorAll('img')[0].classList.remove('invisible')
    newRow.querySelectorAll('img')[1].classList.remove('invisible')

    newRow.querySelectorAll('input').forEach(function (item, index) {
      item.value = ''
      item.parentElement.children[1].classList.remove(
        'mdc-text-field--invalid'
      )
    })
    this.supporterColTarget.appendChild(newRow)

    MaterialForms.init()
  }

  deleteRow (event) {
    event.currentTarget.parentElement.parentElement.remove()
  }

  displayDetails (event) {
    const self = this
    if (this.checkInputs(event, self)) {
      self.supporterListErrorTarget.classList.add('hidden')
      this.sendNotices(event, self)

      this.displayColTarget.innerHTML = ''

      let i = 0
      this.emailTargets.forEach(function (item, index) {
        if (item.value === '') {
          return
        }

        i++
        const newRow = self.displayRowTarget.content.cloneNode(true)

        newRow.querySelector('.display-index').innerHTML = `${i}.`
        newRow.querySelector(
          '.full-name'
        ).innerHTML = `${self.firstNameTargets[index].value} ${self.lastNameTargets[index].value}`
        newRow.querySelector('.display-email').innerHTML = item.value

        self.displayColTarget.appendChild(newRow)
      })
    } else {
      self.supporterListErrorTarget.classList.remove('hidden')
    }
  }

  checkInputs (event, self) {
    this.resetFieldErrors(event)
    const fieldCount = self.firstNameTargets.length
    let filled = true
    let failedIndex
    const emails = []
    const currentUserEmail = this.supporterColTarget.dataset.currentUserEmail

    // Checks if first name and email fields are valid
    for (let i = 0; i < fieldCount; i++) {
      if (self.emailTargets[i].value != '') {
        emails.push(self.emailTargets[i].value)
      }

      if (self.emailTargets[i].value === currentUserEmail) {
        self.emailTargets[i].offsetParent.children[1].classList.add('mdc-text-field--invalid');
        const error = self.createErrorMessage('You cannot invite yourself to be a supporter.')
        self.emailTargets[i].parentElement.after(error)
        self.emailTargets[i].scrollIntoView({ behavior: 'smooth', block: 'center' })
        event.stopImmediatePropagation()
        return // Early return to prevent further processing
      }

      if (
        (self.firstNameTargets[i].value.length > 0 &&
          self.emailTargets[i].value.length > 0 &&
          self.emailTargets[i].checkValidity()) ||
        (self.firstNameTargets[i].value.length == 0 &&
          self.emailTargets[i].value.length == 0)
      ) {
        continue
      } else {
        filled = false
        failedIndex = i
        break
      }
    }

    // Check for duplicate emails
    if (new Set(emails).size != emails.length) {
      const unique_emails = []
      this.emailTargets.forEach(function (item, index) {
        item.offsetParent.children[1].classList.remove(
          'mdc-text-field--invalid'
        )
        if (item.value == '') {
          return
        }
        if (
          new Set(emails).size ==
          new Set(emails.slice(0, index).concat(emails.slice(index + 1))).size
        ) {
          item.offsetParent.children[1].classList.add(
            'mdc-text-field--invalid'
          )
        }

        if (unique_emails.includes(item.value)) {
          // Set duplicate email error
          const error = self.createErrorMessage(
            'Email already listed.'
          )
          item.parentElement.after(error)
          item.offsetParent.children[1].classList.add(
            'mdc-text-field--invalid'
          )
          item.scrollIntoView({ behavior: 'smooth', block: 'center' })
          event.stopImmediatePropagation()
        } else {
          unique_emails.push(item.value)
        }
      })
    } else if (!filled) {
      // Set first name and/or email errors
      const nameErrorTarget = document.querySelector(
        `#first-name-${failedIndex}`
      )
      const emailErrorTarget = document.querySelector(`#email-${failedIndex}`)
      if (
        this.throwError(nameErrorTarget) ||
        this.throwError(emailErrorTarget)
      ) {
        event.stopImmediatePropagation()
      }
    } else {
      return true
    }
  }

  sendNotices (event, self) {
    const count = this.supporterCount()

    if (count === 0) {
      MicroModal.show('no-supporters')
      event.stopImmediatePropagation()
    } else if (count < 5) {
      MicroModal.show('less-supporters')
      event.stopImmediatePropagation()
    }
  }

  editMessage () {
    this.messageInputTarget.setAttribute('contenteditable', true)
    this.saveButtonsTarget.style.display = 'flex'
    this.editButtonTarget.style.display = 'none'
    this.counterTarget.parentElement.style.display = 'block'
    this.revertButtonTarget.style.display = 'flex'

    this.messageInputTarget.focus()
    this.messageInputTarget.classList.add(
      'focus:outline-none',
      'border',
      'border-dark-gray',
      'rounded-md',
      'p-2'
    )
    document.getElementById('wizardButtons').style.display = 'none'
  }

  cancelEdit () {
    this.messageInputTarget.setAttribute('contenteditable', false)
    this.saveButtonsTarget.style.display = 'none'
    this.editButtonTarget.style.display = 'block'
    this.counterTarget.parentElement.style.display = 'none'
    this.revertButtonTarget.style.display = 'none'

    this.messageInputTarget.classList.remove(
      'focus:outline-none',
      'border',
      'border-dark-gray',
      'rounded-md',
      'p-2'
    )
    document.getElementById('wizardButtons').style.display = 'flex'
  }

  saveMessage () {
    this.messageInputTarget.setAttribute('contenteditable', false)
    this.saveButtonsTarget.style.display = 'none'
    this.editButtonTarget.style.display = 'block'
    this.counterTarget.parentElement.style.display = 'none'
    this.revertButtonTarget.style.display = 'none'

    this.messageRealInputTarget.getElementsByTagName('textarea')[0].value =
      this.messageInputTarget.innerHTML

    this.messageInputTarget.classList.remove(
      'focus:outline-none',
      'border',
      'border-dark-gray',
      'rounded-md',
      'p-2'
    )
    document.getElementById('wizardButtons').style.display = 'flex'
  }

  updateCounter () {
    const letterCount = this.messageInputTarget.innerText.length
    this.counterTarget.innerHTML = letterCount

    if (letterCount >= 1000) {
      this.counterTarget.parentElement.classList.add('text-system-red')
    } else {
      this.counterTarget.parentElement.classList.remove('text-system-red')
    }
  }

  restoreDefaultText () {
    this.messageInputTarget.innerHTML = this.defaultTextTarget.innerHTML
  }

  clearError (event, el) {
    // Clear red border, remove custom validity and remove error text from field
    const target = el || event.currentTarget
    const error = target.parentNode.nextElementSibling

    target.setCustomValidity('')
    if (target.offsetParent.children[1]) {
      target.offsetParent.children[1].classList.remove(
        'mdc-text-field--invalid'
      )
    }

    if (error && error.classList.contains('error-message')) {
      error.remove()
    }
  }

  resetFieldErrors (event) {
    // Reset field errors before re-validating
    const fieldCount = this.firstNameTargets.length

    for (let i = 0; i < fieldCount; i++) {
      this.clearError(event, this.firstNameTargets[i])
      if (!this.emailTargets[i].checkValidity()) return
      this.clearError(event, this.emailTargets[i])
    }
  }

  throwError (target) {
    // Add error text and make border red for error
    if (target.type == 'text' && target.value != '') return false
    if (target.type == 'email' && target.value != '') return true
    const error = this.createErrorMessage(
      target.type == 'text'
        ? 'Please enter a first name'
        : 'Please enter an email'
    )
    target.parentElement.after(error)
    target.offsetParent.children[1].classList.add('mdc-text-field--invalid')
    target.scrollIntoView({ behavior: 'smooth', block: 'center' })
    return true
  }

  createErrorMessage (message) {
    // Creates new DIV with error message under field
    const error = document.createElement('div')
    error.classList.add(
      'text-sm',
      'text-system-red',
      'error-message',
      'absolute',
      'left-0',
      '-bottom-5'
    )
    error.innerHTML = message
    return error
  }

  autofillSupporters (event) {
    if (localStorage.getItem(window.location) != null){
      const data = JSON.parse(localStorage.getItem(window.location));

      let rows = Math.max(data['daily_action[actionable_attributes][first_names][]'].length, data['daily_action[actionable_attributes][last_names][]'].length, data['daily_action[actionable_attributes][emails][]'].length)
      let supporterRows = this.supporterRowTargets

      for (let i = 0; i < rows; i++) {
        if(i < supporterRows.length){
          supporterRows[i].querySelector('input[name="daily_action[actionable_attributes][first_names][]"]').value = data['daily_action[actionable_attributes][first_names][]'][i]
          supporterRows[i].querySelector('input[name="daily_action[actionable_attributes][last_names][]"]').value = data['daily_action[actionable_attributes][last_names][]'][i]
          supporterRows[i].querySelector('input[name="daily_action[actionable_attributes][emails][]"]').value = data['daily_action[actionable_attributes][emails][]'][i]
        }else{
          let new_row = this.supporterRowTarget.cloneNode(true)
          new_row.querySelector('input[name="daily_action[actionable_attributes][first_names][]"]').value = data['daily_action[actionable_attributes][first_names][]'][i]
          new_row.querySelector('input[name="daily_action[actionable_attributes][last_names][]"]').value = data['daily_action[actionable_attributes][last_names][]'][i]
          new_row.querySelector('input[name="daily_action[actionable_attributes][emails][]"]').value = data['daily_action[actionable_attributes][emails][]'][i]

          this.supporterColTarget.appendChild(newRow)
        }
      }
    }
  }

  supporterCount() {
    let count = 0

    this.emailTargets.forEach(function (item, index) {
      if (item.value !== '') {
        count++
      }
    })

    return count
  }

  nextStepOrSubmitForm (event) {
    if (this.supporterCount() == 0) { 
      event.stopImmediatePropagation()
      this.formTarget.submit() 
    }
  }
}
