import { Controller } from 'stimulus'

export default class extends Controller {
  initialize () {
    const firstModal = document
      .getElementById('modal-collection')
      .querySelectorAll('.micromodal-slide')[0]
    MicroModal.show(firstModal.id)
  }
}
