// Used for bottom text shown on edit and delete icons on hover
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['month', 'day', 'year']

  initialize () {
    this.updateFields()
  }

  updateFields () {
    const thirtyDayMonths = [4, 6, 9, 11]
    const thirtyOneDayMonths = [1, 3, 5, 7, 8, 10, 12]
    const monthNumber = parseInt(this.monthTarget.value)

    // Enable all options before update
    const disabledDays = [29, 30, 31]
    disabledDays.forEach((day) => {
      this.dayTarget.querySelector(
        'option[value=' + CSS.escape(day) + ']'
      ).disabled = false
    })

    if (thirtyDayMonths.includes(monthNumber)) {
      // Disable day 31 if month has 30 days
      this.dayTarget.querySelector('option[value="31"]').disabled = true
    } else if (thirtyOneDayMonths.includes(monthNumber)) {
      // Enable day 31 if month has 31 days
      this.dayTarget.querySelector('option[value="31"]').disabled = false
    } else {
      // February
      const leap_year = parseInt(this.yearTarget.value) % 4 == 0
      const days_to_exclude = leap_year ? [30, 31] : [29, 30, 31]

      // Disable days in days_to_exclude Array
      days_to_exclude.forEach((day) => {
        this.dayTarget.querySelector(
          'option[value=' + CSS.escape(day) + ']'
        ).disabled = true
      })
    }
  }
}
