import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['realInputName', 'hours', 'mins', 'unit']
  static values = { location: String }
  initialize () {
    this.updateInput()
  }

  updateInput () {
    // const hoursInput = document.getElementById(
    //   `${this.realInputNameTarget.dataset.name}_4i`
    // );

    // const minsInput = document.getElementById(
    //   `${this.realInputNameTarget.dataset.name}_5i`
    // );

    const hoursInput = this.getFormField(
      this.locationValue,
      `${this.realInputNameTarget.dataset.name}_4i`
    )

    const minsInput = this.getFormField(
      this.locationValue,
      `${this.realInputNameTarget.dataset.name}_5i`
    )

    if (hoursInput) {
      if (this.unitTarget.checked && this.hoursTarget.value !== '12') {
        hoursInput.value = String(parseInt(this.hoursTarget.value) + 12)
      } else if (
        !this.unitTarget.checked &&
        parseInt(this.hoursTarget.value) === 12
      ) {
        hoursInput.value = '00'
      } else {
        hoursInput.value = this.hoursTarget.value
      }
    }

    if (minsInput) {
      minsInput.value = this.minsTarget.value
    }
  }

  getFormField (location, name) {
    if (location == 'create-modal') {
      return document.getElementById('create-task').querySelector(`#${name}`)
    } else if (location == 'edit-modal') {
      return document.getElementById('edit-task').querySelector(`#${name}`)
    } else if (location != '' && location != null && location != 'default') {
      return document.getElementById(location).querySelector(`#${name}`)
    } else {
      return document.getElementById(name)
    }
  }
}
