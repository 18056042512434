import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['modal']

  initialize() {
    if (this.hasModalTarget) {
      this.openModal(this.modalTarget.id);
    }
  }

  openModal(modalName) {
    const delayInMilliseconds = 2000; // 3 second

    setTimeout(function () {

      // if #locked-da is open, close it
      if (document.getElementById('locked-da') && document.getElementById('locked-da').classList.contains('is-open')) {
        MicroModal.close('locked-da');
      }

      MicroModal.show(modalName);
    }, delayInMilliseconds);
  }

  openSecondModal(event) {
    const closeModal = event.currentTarget.closest(".micromodal-slide");
    MicroModal.close(closeModal.id);
    MicroModal.show("access-restricted");
  }
}
