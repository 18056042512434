import Daily_actions_controller from '../daily_actions_controller'
import Rails from '@rails/ujs'
import MicroModal from 'micromodal'
import flatpickr from 'flatpickr'
import { MaterialForms } from "../../src/material_forms.js"

let task_id = 0
let editing = false

export default class extends Daily_actions_controller {
  static targets = [
    'name',
    'nameEdit',
    'assistanceType',
    'assistanceTypeEdit',
    'location',
    'locationEdit',
    'notes',
    'notesEdit',
    'taskForm',
    'editForm',
    'tasksList',
    'errorMessage',
    'editTaskForm',
    'startDateEdit',
    'endDate',
    'endDateEdit',
    'minsEdit',
    'hoursEdit',
    'unitEdit',
    'checkbox',
    'checkboxEdit'
  ]

  connect(){
    this.fillTimeToggles();
  }

  // Increment end-time of task by one hour relative to its start-time
  updateToHour (event) {
    const fromHour = document
      .getElementById('first-task')
      .querySelector('#from_hour')
    const toHour = document
      .getElementById('first-task')
      .querySelector('#to_hour')
    const startToggle = document
      .getElementById('first-task')
      .querySelector('#supporter_task_start_time')
    const endToggle = document
      .getElementById('first-task')
      .querySelector('#supporter_task_end_time')

    // Update UI display time
    if (fromHour.selectedIndex != 11) {
      toHour.selectedIndex = fromHour.selectedIndex + 1
    } else {
      this.hoursEditTargets[1].selectedIndex = toHour.selectedIndex
    }

    // AM/PM Toggle
    if (fromHour.selectedIndex < 10) {
      endToggle.checked = startToggle.checked
    } else {
      endToggle.checked = !startToggle.checked
    }

    const hourValue = fromHour.options[toHour.selectedIndex].value

    // Update real-input
    if (startToggle.checked) {
      const pmTime =
        hourValue != '12' ? String(parseInt(hourValue) + 12) : '00'
      this.endDateTargets[0].value = pmTime
    } else {
      this.endDateTargets[0].value = hourValue
    }
  }

  // Copy start-time minutes to end-time minutes
  updateToMin (event) {
    const fromMin = document
      .getElementById('first-task')
      .querySelector('#from_min')
    const toMin = document
      .getElementById('first-task')
      .querySelector('#to_min')
    toMin.selectedIndex = fromMin.selectedIndex

    this.endDateTargets[1].value = fromMin.options[toMin.selectedIndex].value
  }

  saveTask (event) {
    const location = event.target.dataset.location

    const taskForm = this.getElement('new_supporter_task', location)
    Rails.ajax({
      type: 'post',
      url: taskForm.action,
      data: new FormData(taskForm)
    })

    this.clearErrorMessages(location)
  }

  updateTask (event) {
    const location = event.target.dataset.location

    Rails.ajax({
      type: 'put',
      url: `${this.editTaskFormTarget.action.replace(
        /task.*$/i,
        `tasks/${task_id}`
      )}`,
      data: new FormData(this.editTaskFormTarget)
    })
    MicroModal.close('edit-task')

    this.clearErrorMessages(location)
  }

  deleteTask () {
    Rails.ajax({
      type: 'delete',
      url: `${this.editTaskFormTarget.action.replace(
        /task.*$/i,
        `tasks/${task_id}`
      )}`
    })
    MicroModal.close('delete-task-confirmation')
  }

  validateForm (event) {
    const location = event.target.dataset.location

    this.clearErrorMessages(location)
    this.validateDate(event, location)
    this.validateTime(event, location)

    this.validateTaskType(event, location)

    // validate task type
  }

  clearErrorMessages (location) {
    this.getElement('error-message', location).classList.add('hidden')
    this.getElement('error-message-time', location).classList.add('hidden')
    this.getElement('error-message-date', location).classList.add('hidden')
  }

  validateTaskType (event, location) {
    if (!this.getElement('supporter_task_task_type', location).value.trim()) {
      this.getElement('error-message', location).classList.remove('hidden')
      this.showRequiredField(
        this.getElement('supporter_task_task_type', location)
      )
      event.stopImmediatePropagation()
    }
  }

  validateDate (event, location) {
    const year = this.getElement(
      'supporter_task_start_time_1i',
      location
    ).value

    const month =
      parseInt(
        this.getElement('supporter_task_start_time_2i', location).value
      ) - 1

    const day = this.getElement('supporter_task_start_time_3i', location).value

    let hours = parseInt(this.getElement('from_hour', location).value)

    const minutes = this.getElement('from_min', location).value

    if (this.getToggleElement('start', location).checked && hours <= 11) {
      hours += 12
    }

    if (!this.getToggleElement('start', location).checked && hours == 12) {
      hours = 0
    }

    const dateTime = new Date(year, month, day, hours, minutes)

    const today = new Date()

    if (dateTime < today) {
      this.getElement('error-message-date', location).classList.remove(
        'hidden'
      )
      event.stopImmediatePropagation()
    }
  }

  validateTime (event, location) {
    let checkboxId = ''
    if (location == 'create-modal') {
      checkboxId = 'create-task-modal-time-unknown'
    } else if (location == 'edit-modal'){
      checkboxId = 'supporter_task_time_unknown'
    } else {
      checkboxId = 'create-task-time-unknown'
    }

    if (this.getElement(checkboxId, location).checked == true) { return }

    const same_am_pm =
      this.getToggleElement('start', location).checked ==
      this.getToggleElement('end', location).checked
    const from_hour = parseInt(
      this.getElement('from_hour', location).options[
        this.getElement('from_hour', location).selectedIndex
      ].value
    )
    const from_min = parseInt(
      this.getElement('from_min', location).options[
        this.getElement('from_min', location).selectedIndex
      ].value
    )

    const to_hour = parseInt(
      this.getElement('to_hour', location).options[
        this.getElement('to_hour', location).selectedIndex
      ].value
    )
    const to_min = parseInt(
      this.getElement('to_min', location).options[
        this.getElement('to_min', location).selectedIndex
      ].value
    )

    if (same_am_pm) {
      if (from_hour == 12) {
        return
      }
      if (to_hour < from_hour || (to_hour >= from_hour && to_min < from_min)) {
        this.getElement('error-message-time', location).classList.remove(
          'hidden'
        )
        event.stopImmediatePropagation()
      }
    }
  }

  getToggleElement (time, location) {
    if (location == 'create-modal') {
      return document
        .getElementById('create-task')
        .querySelector(`#supporter_task_${time}_time_3`)
    } else if (location == 'edit-modal') {
      return document
        .getElementById('edit-task')
        .querySelector(`#supporter_task_${time}_time_2`)
    } else {
      return document.getElementById(`supporter_task_${time}_time`)
    }
  }

  getElement (id, location) {
    if (location == 'create-modal') {
      return document.getElementById('create-task').querySelector(`#${id}`)
    } else if (location == 'edit-modal') {
      return document.getElementById('edit-task').querySelector(`#${id}`)
    } else {
      return document.getElementById(id)
    }
  }

  showConfirmationModal () {
    MicroModal.show('confirmation-message')
  }

  showEditModal (event) {
    // prefill input fields
    task_id = event.target.dataset.taskid
    editing = true

    const task = document.getElementById(`new-task${task_id}`)

    // prefill location
    if (
      task.getElementsByClassName('task-location')[0].textContent.trim() ==
      'Not specified'
    ) {
      this.locationEditTarget.value = ''
    } else {
      this.locationEditTarget.value = task
        .getElementsByClassName('task-location')[0]
        .textContent.trim()
    }

    this.notesEditTarget.value = task
      .getElementsByClassName('task-notes')[0]
      .textContent.trim()

    this.nameEditTarget.value = task
      .getElementsByClassName('task-name')[0]
      .textContent.trim()

    // prefill assistance type
    const index = task
      .getElementsByClassName('task-type')[0]
      .textContent.trim()
    this.assistanceTypeEditTarget.selectedIndex = parseInt(index, 10) + 1

    // prefill checkbox
    if (
      task.getElementsByClassName('task-time-unknown')[0].textContent.trim() ==
      'true'
    ) {
      this.checkboxEditTarget.checked = false
      this.checkboxEditTarget.click()
    } else {
      this.checkboxEditTarget.checked = true
      this.checkboxEditTarget.click()
    }
    //

    // prefill start date, start time
    let dateTime = task
      .getElementsByClassName('task-start-time')[0]
      .textContent.trim()
      .split(' ')
    this.prefillDateTime(
      dateTime,
      this.startDateEditTargets,
      this.unitEditTargets[0],
      this.hoursEditTargets[0],
      this.minsEditTargets[0],
      'start'
    )

    // prefill end date, end time
    dateTime = task
      .getElementsByClassName('task-end-time')[0]
      .textContent.trim()
      .split(' ')
    this.prefillDateTime(
      dateTime,
      this.endDateEditTargets,
      this.unitEditTargets[1],
      this.hoursEditTargets[1],
      this.minsEditTargets[1],
      'end'
    )

    this.showNameField()

    MaterialForms.init()

    // show form
    MicroModal.show('edit-task')
  }

  showCreateModal (event) {
    document
      .getElementById('create-task')
      .querySelector('#new_supporter_task')
      .reset()
    MicroModal.show('create-task')
  }

  prefillDateTime (dateTime, editDateFields, unitToggle, hour, minutes, time) {
    const endDate = dateTime[0].split('-')
    const endTime = dateTime[1].split(':')

    if (time == 'start') {
      editDateFields[0].selectedIndex = parseInt(endDate[1]) - 1
      editDateFields[1].selectedIndex = parseInt(endDate[2]) - 1
      editDateFields[2].value = parseInt(endDate[0])
    }

    if (parseInt(endTime[0]) < 12) {
      unitToggle.checked = false
      // Select 11th index(12) if 12AM
      hour.selectedIndex = endTime[0] == '00' ? 11 : parseInt(endTime[0]) - 1
    } else {
      unitToggle.checked = true
      // Select 11th index(12) if 12PM
      hour.selectedIndex =
        endTime[0] == '12' ? 11 : parseInt(endTime[0]) - 12 - 1
    }
    minutes.selectedIndex = parseInt(endTime[1])
  }

  showDeleteConfirmationModal (event) {
    task_id = event.target.dataset.taskid
    MicroModal.show('delete-task-confirmation')
  }

  showNameField () {
    if (event.target.dataset.value == 'edit' || editing == true) {
      const assistanceSelect = this.assistanceTypeEditTarget
      if (
        assistanceSelect.options[assistanceSelect.selectedIndex].text == 'Other'
      ) {
        document.getElementById('nameEdit').classList.remove('hidden')
      } else {
        document.getElementById('nameEdit').classList.add('hidden')
      }
      editing = false
    } else {
      const assistanceSelect = this.assistanceTypeTarget
      if (
        assistanceSelect.options[assistanceSelect.selectedIndex].text == 'Other'
      ) {
        document.getElementById('name').classList.remove('hidden')
      } else {
        document.getElementById('name').classList.add('hidden')
      }
    }
  }

  showRequiredField (target) {
    target.focus()
    target.blur()
  }

  fillTimeToggles (){
    if (localStorage.getItem(window.location) != null){
      const data = JSON.parse(localStorage.getItem(window.location));
      const timeToggles = document.querySelectorAll('.dn');
      timeToggles.forEach((toggle) => {
        const name = toggle.dataset.name;
        const value = data[name];
        if (value){
          toggle.checked = true;
        }else{
          toggle.checked = false;
        }
      });
    }
  }
}
