document.addEventListener("turbo:load", function (event) {
  const targetNode = document.getElementById("customTemplate");

  if (targetNode) {
    const config = { childList: true, subtree: true };

    const callback = function (mutationsList, observer) {
      for (const mutation of mutationsList) {
      }
    };

    const observer = new MutationObserver(callback);
    observer.observe(targetNode, config);
  }
});
