import Daily_actions_controller from '../daily_actions_controller'
import Amplitude from 'amplitudejs'
import { MaterialForms } from "../../src/material_forms.js"

export default class extends Daily_actions_controller {
  static targets = [
    'surgeryStoryResponse',
    'surgeryStoryResponseOutput',
    'surgeryStoryResponseError',
    'funnyResponse',
    'mobileFunnyResponse',
    'diplomaticResponse',
    'mobileDiplomaticResponse',
    'apologeticResponse',
    'mobileApologeticResponse',
    'responseError'
  ]

  initialize () {
    let locale = document.getElementById('locale').dataset.locale
    Amplitude.init({
      songs: [
        {
          name: 'Recording #1',
          url: window.location.origin + `/audios/${locale}/DA6_Script_1.mp3`
        },
        {
          name: 'Recording #2',
          url: window.location.origin + `/audios/${locale}/DA6_Script_2.mp3`
        },
        {
          name: 'Recording #3',
          url: window.location.origin + `/audios/${locale}/DA6_Script_3.mp3`
        }
      ],
      continue_next: false,
      callbacks: {
        play: function () {
          document
            .getElementById(`playBtn${Amplitude.getActiveIndex()}`)
            .classList.add('hidden')
          document
            .getElementById(`stopBtn${Amplitude.getActiveIndex()}`)
            .classList.remove('hidden')
        },

        pause: function () {
          document
            .getElementById(`stopBtn${Amplitude.getActiveIndex()}`)
            .classList.add('hidden')
          document
            .getElementById(`playBtn${Amplitude.getActiveIndex()}`)
            .classList.remove('hidden')
        }
      }
    })

    // for (let i = 0; i < 3; i++) {
    for (let i = 0; i < 1; i++) {
      document
        .getElementById(`song-played-progress-${i}`)
        .addEventListener('click', function (e) {
          if (Amplitude.getActiveIndex() == i) {
            const offset = this.getBoundingClientRect()
            const x = e.pageX - offset.left

            Amplitude.setSongPlayedPercentage(
              (parseFloat(x) / parseFloat(this.offsetWidth)) * 100
            )
          }
        })
    }
  }

  displayInputs () {
    this.surgeryStoryResponseOutputTarget.innerHTML =
      this.surgeryStoryResponseTarget.value
  }

  checkResponse (event) {
    if (this.surgeryStoryResponseTarget.value.length == 0) {
      this.showRequiredField(this.surgeryStoryResponseTarget)
      this.throwError(event, this.surgeryStoryResponseErrorTarget)
    } else {
      this.surgeryStoryResponseErrorTarget.style.display = 'none'
    }
  }

  changeDesktopResponse (event) {
    const responseType = event.target.dataset.responseType

    if (responseType == 'funny') {
      this.funnyResponseTarget.click()
      this.funnyResponseTarget.selectedIndex = event.target.selectedIndex
    } else if (responseType == 'diplomatic') {
      this.diplomaticResponseTarget.selectedIndex = event.target.selectedIndex
    } else if (responseType == 'apologetic') {
      this.apologeticResponseTarget.selectedIndex = event.target.selectedIndex
    }
    MaterialForms.init()
  }

  changeMobileResponse (event) {
    const responseType = event.target.dataset.responseType

    if (responseType == 'funny') {
      this.funnyResponseTarget.click()
      this.mobileFunnyResponseTarget.selectedIndex = event.target.selectedIndex
    } else if (responseType == 'diplomatic') {
      this.mobileDiplomaticResponseTarget.selectedIndex =
        event.target.selectedIndex
    } else if (responseType == 'apologetic') {
      this.mobileApologeticResponseTarget.selectedIndex =
        event.target.selectedIndex
    }
    MaterialForms.init()
  }

  checkResponses (event) {
    let filled = true

    if (this.funnyResponseTarget.value.length == 0) {
      filled = false
    }

    this.showRequiredField(this.funnyResponseTarget)
    this.showRequiredField(this.mobileFunnyResponseTarget)

    if (this.diplomaticResponseTarget.value.length == 0) {
      filled = false
    }

    this.showRequiredField(this.diplomaticResponseTarget)
    this.showRequiredField(this.mobileDiplomaticResponseTarget)

    if (this.apologeticResponseTarget.value.length == 0) {
      filled = false
    }

    this.showRequiredField(this.apologeticResponseTarget)
    this.showRequiredField(this.mobileApologeticResponseTarget)

    if (!filled) {
      this.throwError(event, this.responseErrorTarget)
    } else {
      this.responseErrorTarget.style.display = 'none'
    }
  }

  showRequiredField (target) {
    target.focus()
    target.blur()
  }

  throwError (event, target) {
    event.stopImmediatePropagation()
    target.style.display = 'block'
    target.scrollIntoView({ behavior: 'smooth' })
  }
}
