
    // app/javascript/controllers/auto_save_controller.js
    import { Controller } from "stimulus";
    import { MaterialForms } from "../src/material_forms.js";
    
    export default class extends Controller {
      static targets = ["form"];
    
      connect() {
        // Create a unique key to store the form data into localStorage.
        // This could be anything as long as it's unique.
        // https://developer.mozilla.org/en-US/docs/Web/API/Window/localStorage
        this.localStorageKey = window.location;
    
        // Retrieve data from localStorage when the Controller loads.
        this.setFormData();

        MaterialForms.init();
      }
    
      clearLocalStorage() {
        // See if there is data stored for this particular form.
        if (localStorage.getItem(this.localStorageKey) != null) {
          // Clear data from localStorage when the form is submitted.
          localStorage.removeItem(this.localStorageKey);
        }
      }
    
      getFormData() {
        // Construct a set of of key/value pairs representing form fields and their values.
        // https://developer.mozilla.org/en-US/docs/Web/API/FormData
        const form = new FormData(this.formTarget);
        let data = {}
    
        // Loop through each key/value pair.
        // https://developer.mozilla.org/en-US/docs/Web/API/FormData/entries#example
        for (var pair of form.entries()) {
            var pattern = /\[\]$/;

            // check if the string ends with [] for an array
            var hasEmptyBrackets = pattern.test(pair[0]);

            if (hasEmptyBrackets) {
              if(data[pair[0]] != undefined) {
                data[pair[0]].push(pair[1]);
              }else{
                data[pair[0]] = [pair[1]];
              }
            } else if(pair[0] != "authenticity_token") {
              data[pair[0]] = pair[1];
            }


          // // We don't want to save the authenticity_token to localStorage since that is generated by Rails.
          // // https://guides.rubyonrails.org/security.html#cross-site-request-forgery-csrf
          // if (pair[0] != "authenticity_token") {
          //   data.push([pair[0], pair[1]]);
          // }
        }
    
        // Return the key/value pairs as an Object. Each key is a field name, and each value is the field value.
        // https://developer.mozilla.org/en-us/docs/Web/JavaScript/Reference/Global_Objects/Object/fromEntries
        // return Object.fromEntries(data);
        return data
      }
    
      saveToLocalStorage() {
        const data = this.getFormData();
        // Save the form data into localStorage. We need to convert the data Object into a String.
        localStorage.setItem(this.localStorageKey, JSON.stringify(data));
      }
    
      setFormData() {
        // See if there is data stored for this particular form.
        if (localStorage.getItem(this.localStorageKey) != null) {
          // We need to convert the String of data back into an Object.
          const data = JSON.parse(localStorage.getItem(this.localStorageKey));
          // This allows us to have access to this.formTarget in the loop below.
          const form = this.formTarget;

          for (const key in data) {
            let name = key;

            if(this.skipValues().includes(name)) {
              continue;
            }
            
            let value = data[key];
            let input = form.querySelector(`[name='${name}']`);

            var pattern = /\[\]$/;

            // check if the string ends with [] for an array
            var hasEmptyBrackets = pattern.test(name);

            if(input == null){
              continue;
            }

            if(hasEmptyBrackets && input.tagName == "TEXTAREA") {
              let inputs = form.querySelectorAll(`[name='${name}']`);
              inputs.forEach((input, index) => {
                input.value = value[index];
              });

            }else if(input.tagName == "SELECT") {

              let inputs = form.querySelectorAll(`[name='${name}']`);

              inputs.forEach((input, index) => {
                let options = input.querySelectorAll("option");
                for (const option of options) {
                  if (value.includes(option.value)) {
                    option.selected = true;
                  }
                }
              });
            }
            else if (input.type == "checkbox") {
              let inputs = form.querySelectorAll(`[name='${name}']`);

              for (const input of inputs) {
                if (value.includes(input.value)) {
                  input.checked = true;
                }
              }
            }else if (input.type == "radio") {
              value = CSS.escape(value)
              let input = form.querySelector(`[name='${name}'][value='${value}']`);
              input && (input.checked = true);
            }
            else{
              let inputs = form.querySelectorAll(`[name='${name}']`);

              for(const input of inputs) {
                input && (input.value = value);
              }

            }
          }

          // Loop through each key/value pair and set the value on the corresponding form field.
          // Object.entries(data).forEach((entry) => {
          //   let name = entry[0];
          //   let value = entry[1];
          //   let input = form.querySelector(`[name='${name}']`);
          //   input && (input.value = value);
          // });
        }
      }

      skipValues(){
        return [
          'medication[dosage][]',
          'medication[frequency][]',
          'medication[name][]',
          'daily_action[actionable_attributes][first_names][]',
          'daily_action[actionable_attributes][last_names][]',
          'daily_action[actionable_attributes][emails][]',
          'daily_action[actionable_attributes][going_hospital_with][]'
        ]
      }
    }
  