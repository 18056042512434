import Daily_actions_controller from "../daily_actions_controller";

export default class extends Daily_actions_controller {
  static targets = [
    "option",
    "optionCustom",
    "optionError",
    "screenOffTimeError",
    "bedtimeError",
    "wakeUpTimeError"
  ];

  connect(){
    this.fillTimeToggles()
  }

  checkOptions(event) {
    let filled = false;

    this.optionCustomTargets.forEach((option) => {
      if (option.value.length !== 0) {
        filled = true;
        return;
      }
    });

    this.optionTargets.forEach((option) => {
      if (option.checked) {
        filled = true;
        return;
      }
    });

    if (!filled) {
      event.stopImmediatePropagation();
      this.optionErrorTarget.style.display = "block";
    } else {
      this.optionErrorTarget.style.display = "none";
    }
  }


  fillTimeToggles(){
    if (localStorage.getItem(window.location) != null){
      const data = JSON.parse(localStorage.getItem(window.location));
      const timeToggles = document.querySelectorAll('.dn');
      timeToggles.forEach((toggle) => {
        const name = toggle.dataset.name;
        const value = data[name];
        if (value){
          toggle.checked = true;
        }else{
          toggle.checked = false;
        }
      });
    }
  }

  checkScreenOffTime (event) {
    const screenOffTimeTarget = document.getElementById('screen-off-time').querySelectorAll('select')
    const screenOffHour = screenOffTimeTarget[0].value
    const screenOffMin = screenOffTimeTarget[1].value

    if(screenOffHour == '' || screenOffMin == '') {
      event.stopImmediatePropagation();
      this.screenOffTimeErrorTarget.style.display = "block";
    } else {
      this.screenOffTimeErrorTarget.style.display = "none";
    }
  }

  checkBedtime (event) {
    const bedtimeTarget = document.getElementById('bedtime').querySelectorAll('select')
    const bedtimeHour = bedtimeTarget[0].value
    const bedtimeMin = bedtimeTarget[1].value

    if(bedtimeHour == '' || bedtimeMin == '') {
      event.stopImmediatePropagation();
      this.bedtimeErrorTarget.style.display = "block";
    } else {
      this.bedtimeErrorTarget.style.display = "none";
    }
  }

  checkWakeUpTime (event) {
    const wakeUpTimeTarget = document.getElementById('wake-up-time').querySelectorAll('select')
    const wakeUpHour = wakeUpTimeTarget[0].value
    const wakeUpMin = wakeUpTimeTarget[1].value

    if(wakeUpHour == '' || wakeUpMin == '') {
      event.stopImmediatePropagation();
      this.wakeUpTimeErrorTarget.style.display = "block";
    } else {
      this.wakeUpTimeErrorTarget.style.display = "none";
    }
  }
}
