// Reconnect ActionCable after switching accounts

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "demoError" ]

  initialize() {
    window.fbAsyncInit = function () {
      FB.init({
        appId: 7650946938278879,
        autoLogAppEvents: true,
        xfbml: true,
        version: "v14.0",
      });
    };
  }

  share() {
    if(this.hasDemoErrorTarget) {
      this.demoErrorTarget.classList.remove("hidden");
      return;
    }

    FB.ui(
      {
        method: "share",
        href: "https://readysetrecover.com",
      },
      function (response) {}
    );
  }
}
