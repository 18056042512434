import { Controller } from 'stimulus'
import MicroModal from 'micromodal'

export default class extends Controller {
  static targets = ['sendContent']

  send (event) {
    if (event.currentTarget.dataset.response) {
      // If sending via supporter response modals
      MicroModal.close('video-review')
      MicroModal.show('video-request-thanks')
    } else {
      // Thanks message
      document.getElementById('surprise-video-modal-content').innerHTML =
        this.sendContentTarget.innerHTML
      // Delay close modal
      setTimeout(function () {
        MicroModal.close('surprise-video')
      }, 4000)
      // Remove CTA on dashboard
      document.getElementById('surprise-video-cta').remove()
    }
  }

  deleteConfirmation (event) {
    MicroModal.init({disableScroll: true})
    const modalName = event.currentTarget.dataset.response
      ? 'video-review'
      : 'surprise-video'
    MicroModal.close(modalName)
    MicroModal.show('delete-confirmation')
  }
}
