import { Controller } from 'stimulus'

export default class extends Controller {
  static values = { pageIndex: Number }
  static targets = [
    'page',
    'nextButton',
    'backButton',
    'dotIndicator',
    'pageTop'
  ]

  initialize () {
    this.showPage(0)

    // Make first dot active
    if (this.dotIndicatorTargets.length > 0) {
      this.makeActive(this.dotIndicatorTargets[0])
    }
  }

  next (event) {
    this.scrollIntoView(this.pageTopTarget)
    this.pageIndexValue++
    this.hidePage(this.pageIndexValue - 1)
    this.showPage(this.pageIndexValue)
    this.makeInactive(this.dotIndicatorTargets[this.pageIndexValue - 1])
    this.makeActive(this.dotIndicatorTargets[this.pageIndexValue])

    // Enable back button
    if (this.backButtonTarget.classList.contains('disabled-page-button')) {
      this.enableButton(this.backButtonTarget)
    }
    // Disable next button
    if (this.pageIndexValue + 1 == this.pageTargets.length) {
      this.disableButton(this.nextButtonTarget)
    }
  }

  previous (event) {
    this.scrollIntoView(this.pageTopTarget)
    this.pageIndexValue--
    this.hidePage(this.pageIndexValue + 1)
    this.showPage(this.pageIndexValue)
    this.makeInactive(this.dotIndicatorTargets[this.pageIndexValue + 1])
    this.makeActive(this.dotIndicatorTargets[this.pageIndexValue])

    // Enable next button
    if (this.nextButtonTarget.classList.contains('disabled-page-button')) {
      this.enableButton(this.nextButtonTarget)
    }
    // Disable back button
    if (this.pageIndexValue == 0) {
      this.disableButton(this.backButtonTarget)
    }
  }

  hidePage (index) {
    this.pageTargets[index].classList.add('hidden')
  }

  showPage (index) {
    this.pageTargets[index].classList.remove('hidden')
  }

  makeActive (target) {
    target.classList.remove('bg-gray-1')
    target.classList.add('bg-violet')
  }

  makeInactive (target) {
    target.classList.add('bg-gray-1')
    target.classList.remove('bg-violet')
  }

  disableButton (target) {
    target.classList.add('disabled-page-button')
  }

  enableButton (target) {
    target.classList.remove('disabled-page-button')
  }

  scrollIntoView (target) {
    let currentHeaderPosition = window.getComputedStyle(document.querySelector('#TopMenu')).position
    if (currentHeaderPosition == 'fixed') {
      target.scrollIntoView({ behavior: 'smooth' })
    }
  }
}
