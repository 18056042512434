document.addEventListener("turbo:load", function (event) {
  if (document.getElementById("content-container")) {
    resizeContainer();
    window.onresize = resizeContainer;
  }

  function resizeContainer() {
    const container = document.getElementById("content-container");

    // If chat sidebar isn't in view, don't dynamic adjust the container height
    if (document.getElementById("chat-sidebar")) {
      let displayMode = window.getComputedStyle(
        document.getElementById("chat-sidebar"),
        null
      ).display;

      if (displayMode == "none") {
        container.style.height = "";
        return;
      }

      const newSize =
        window.innerHeight - container.getBoundingClientRect().top;

      if (newSize > 600) {
        container.style.height = newSize + "px";
      }
    }
  }
});
