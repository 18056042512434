import { Controller } from "stimulus";
import MicroModal from "micromodal";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [
    "code",
    "modalContent",
    "successContent",
    "nextButton",
    "verifyButton",
    "input",
    "codeInvalidMessage",
    "codeSentMessage",
  ];

  verify(event) {
    const formData = new FormData();
    const sid = document
      .getElementById("verify-phone-number")
      .querySelector("#sid").value;

    formData.append("mobile_number_verification_token", this.codeTarget.value);
    formData.append("id", sid);
    const modalContent = this.modalContentTargets[0];
    const successContent = this.successContentTargets[0];
    const nextButton = this.nextButtonTargets[0];
    const verifyButton = this.verifyButtonTargets[0];
    const inputField = this.inputTargets[0];
    const successMessage = this.codeSentMessageTargets[0];
    const errorMessage = this.codeInvalidMessageTargets[0];
    const animation = document.getElementById("number-verified-animation");

    Rails.ajax({
      type: "put",
      url: `/sms/${sid}`,
      data: formData,
      success: function (data) {
        if (data === "approved") {
          modalContent.innerHTML = successContent.innerHTML;
          animation.play();
          if (nextButton) {
            nextButton.disabled = false;
          }
          if (verifyButton) {
            verifyButton.disabled = true;
          }
          // Disable field only if not profile page
          if (inputField && !inputField.dataset.profile) {
            inputField.disabled = true;
            inputField.dataset.verifiedTarget = "verified";
          }

          setTimeout(function () {
            MicroModal.close("verify-phone-number");
          }, 4000);
        } else {
          errorMessage.classList.remove("hidden");
          if (!successMessage.classList.contains("hidden")) {
            successMessage.classList.add("hidden");
          }
        }
      },
    });
  }
}
