import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "input",
    "inputField",
    "template",
    "ownButton",
    "templateButton",
    "templateInput",
  ];

  connect() {}

  // Switch to template
  fill() {
    document.getElementById("storyError").style.display = "none";
    this.highlight(this.templateButtonTarget);
    this.unhighlight(this.ownButtonTarget);
    this.inputTarget.classList.add("hidden");

    if (this.hasInputFieldTarget) {
      this.inputFieldTarget.required = false;
    }
    this.templateInputTarget.classList.remove("hidden");

    // this.inputTarget.value = this.templateTarget.innerHTML;
    // this.inputTarget.rows = '10'
  }

  // Switch to custom
  remove() {
    document.getElementById("storyError").style.display = "none";
    this.highlight(this.ownButtonTarget);
    this.unhighlight(this.templateButtonTarget);
    this.inputTarget.value = "";
    this.inputTarget.parentElement.classList.remove("hidden");
    this.inputTarget.classList.remove("hidden");
    if (this.hasInputFieldTarget) {
      this.inputFieldTarget.required = true;
    }
    this.templateInputTarget.classList.add("hidden");
  }

  highlight(button) {
    button.classList.remove("text-brand-blue");
    button.classList.add("bg-brand-blue", "text-white");
  }

  unhighlight(button) {
    button.classList.remove("bg-brand-blue", "text-white");
    button.classList.add("text-brand-blue", "border", "border-brand-blue");
  }
}
