import { Controller } from 'stimulus'
import { MaterialForms } from "../src/material_forms.js"
import Rails from '@rails/ujs'
import MicroModal from 'micromodal'

export default class extends Controller {
  static targets = [
    'template',
    'renderForms',
    'form',
    'name',
    'email',
    'submit',
    'nameError',
    'emailError',
    'formError',
    'noteMessage',
    'noteMessageError'
  ]

  openModal() {
    this.clearModal()
    MicroModal.show('new-contact')
  }

  select(event) {
    const row = event.currentTarget
    const checkbox = row.querySelector('.contact-checkbox')
    const fullName = row.querySelector('.contact-name').innerHTML.trim()
    const email = row.querySelector('.contact-email').dataset.email

    row.classList.contains('bg-gray-4')
      ? row.classList.remove('bg-gray-4')
      : row.classList.add('bg-gray-4')

    if (event.target !== checkbox) {
      checkbox.checked ? (checkbox.checked = false) : (checkbox.checked = true)
    }

    if (checkbox.checked) {
      const newForm = this.templateTarget.content.cloneNode(true)

      if (this.renderFormsTarget.lastElementChild === null) {
        newForm.firstElementChild.dataset.count = 0
      } else {
        newForm.firstElementChild.dataset.count =
          this.renderFormsTarget.lastElementChild.dataset.count + 1
      }

      newForm.firstElementChild.innerHTML =
        newForm.firstElementChild.innerHTML.replace(
          /TEMPLATE_RECORD/g,
          newForm.firstElementChild.dataset.count
        )

      newForm.firstElementChild.querySelector('.name').value = fullName
      newForm.firstElementChild.querySelector('.email').value = email

      this.renderFormsTarget.appendChild(newForm)

      this.renderFormsTarget.lastElementChild.id = email
      this.renderFormsTarget.lastElementChild.querySelector(
        '.form-title'
      ).innerHTML = `Your note to ${fullName}`

      MaterialForms.init()
    } else {
      document.getElementById(email).remove()
    }
  }

  sort() {
    const order = Array.from(
      document.getElementsByClassName('contact-email')
    ).map((x) => x.innerText)

    let toSort = document.getElementById('render-forms-section').children
    toSort = Array.prototype.slice.call(toSort, 0)

    toSort.sort(function (a, b) {
      const aord = order.indexOf(a.id)
      const bord = order.indexOf(b.id)

      return aord > bord ? 1 : -1
    })

    const parent = document.getElementById('render-forms-section')
    parent.innerHTML = ''

    for (let i = 0, l = toSort.length; i < l; i++) {
      parent.appendChild(toSort[i])
    }
  }

  validateForm(event) {
    if (
      event.currentTarget == this.nameTarget ||
      event.currentTarget == this.submitTarget
    ) {
      if (this.nameTarget.checkValidity() == false) {
        this.throwError(this.nameTarget, this.nameErrorTarget)
      } else {
        this.nameErrorTarget.classList.add('hidden')
      }
    }

    if (
      event.currentTarget == this.emailTarget ||
      event.currentTarget == this.submitTarget
    ) {
      this.formErrorTarget.classList.add('hidden')
      if (this.emailTarget.checkValidity() == false) {
        this.throwError(this.emailTarget, this.emailErrorTarget)
      } else {
        this.emailErrorTarget.classList.add('hidden')
      }
    }
  }

  submitForm() {
    const formErrorTarget = this.formErrorTarget
    const contactError = document.querySelector('#empty-contacts')

    Rails.ajax({
      type: 'post',
      url: this.formTarget.action,
      data: new FormData(this.formTarget),
      success: function (data) {
        data = JSON.parse(data)
        MicroModal.close('new-contact')
        formErrorTarget.classList.add('hidden')

        // Add new contact to list by cloning template
        const newContact = document
          .getElementById('contact-template')
          .content.cloneNode(true)

        newContact.querySelector('.contact-name').innerHTML = data.name
        newContact.querySelector('.contact-email').innerHTML = data.email
        newContact.querySelector('.contact-email').dataset.email = data.email

        if (contactError && !contactError.classList.contains('hidden')) {
          contactError.classList.add('hidden')
        }

        document.getElementById('contact-list').appendChild(newContact)

        // Click newly added contact and show field for sending note.
        setTimeout(() => {
          const newContactList = document.getElementById('contact-list')
          newContactList.lastElementChild
            .querySelector('.contact-checkbox')
            .click()
        }, '50')
      },
      error: function (data) {
        // Throw error for contact already exists
        if (JSON.parse(data).error[0].includes('exists')) {
          formErrorTarget.innerHTML = JSON.parse(data).error[0]
          formErrorTarget.classList.remove('hidden')
        }
      }
    })
  }

  checkNotes(event) {
    const emptyInputs = this.noteMessageTargets.filter(input => input.value == '')
    if (emptyInputs.length > 0) {
      this.noteMessageErrorTarget.classList.remove('hidden')
      this.noteMessageErrorTarget.scrollIntoView({ behavior: 'smooth' })
      event.preventDefault()
      event.stopImmediatePropagation()
    }
  }

  clearModal() {
    this.nameTarget.value = ''
    this.nameTarget.parentNode.classList.remove('mdc-text-field--invalid')
    this.nameErrorTarget.classList.add('hidden')
    document.getElementById('new-contact').value = ''
    this.emailTarget.value = ''
    this.emailTarget.parentNode.classList.remove('mdc-text-field--invalid')
    this.emailErrorTarget.classList.add('hidden')
    this.formErrorTarget.classList.add('hidden')
  }

  throwError(target, errorTarget) {
    errorTarget.classList.remove('hidden')
    target.focus()
    target.blur()
  }
}
