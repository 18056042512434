import { Controller } from "stimulus";
import MicroModal from "micromodal";
import { MaterialForms } from "../src/material_forms.js";

export default class extends Controller {
  static targets = [
    "checkmark",
    "dateInput",
    "dateSelectInput",
    "dateError",
    "passwordReset",
    "surgeryFill",
  ];

  initialize () {
    // Clear previous traces of dismissing surgery date warning
    this.clearDateWarning();
    let prevDate;

    // Pre-fill date if it was previously selected and backend validation fails
    if (this.dateSelectInputTarget.dataset.previousDate) {
      prevDate = JSON.parse(this.dateSelectInputTarget.dataset.previousDate)
      this.dateSelectInputTargets.forEach((select, index) => {
        select.value = prevDate[index]
      });
    }

    if (this.checkmarkTarget.checked) {
      this.disableSurgeryDateFields();
    } else {
      // Removes surgery deat placeholders on failed form reloads
      MaterialForms.init();
    }
  }

  connect () {
    if (!this.hasPasswordResetTarget) {
      return;
    }

    MicroModal.show("password-reset");
  }

  toggleDateInput () {
    // Show/Hide Check Mark and Enable/Disable Input Form
    if (this.checkmarkTarget.checked == true) {
      // If unknown is checked
      this.skipDateWarning();
      this.dateErrorTarget.classList.add("invisible");
      this.disableSurgeryDateFields();
    } else {
      this.clearDateWarning();
      this.dateSelectInputTargets.forEach((select) => {
        select.disabled = false;
      });
      this.surgeryDateWrapperElements().forEach((field) => {
        field.classList.remove("mdc-text-field--disabled");
      });
    }
  }

  checkDate (event) {
    const skipWarning = localStorage.getItem("surgeryDateWarning");
    this.dateErrorTarget.classList.add("invisible");

    // Return if no date field present
    if (!this.hasDateInputTarget) {
      return;
    }

    if (skipWarning == "skip" || this.checkmarkTarget.checked == true) {
      return;
    }

    const date = this.dateSelectInputTargets;
    let enteredDate;

    if (this.throwDateError(date)) {
      this.dateErrorTarget.classList.add("invisible");
      enteredDate = new Date(
        `${date[2].value}-${date[0].value}-${date[1].value}`
      );
    } else {
      this.throwError(event);
    }

    const todayDate = new Date(Date.now());

    const weekFromTodayDate = new Date(Date.now());
    weekFromTodayDate.setDate(weekFromTodayDate.getDate() + 7);
    const options = {
      weekday: "short",
      month: "short",
      day: "numeric",
    };

    // If date in in past

    let cutoffDateTime = new Date(
      `${date[2].value}-${date[0].value}-${date[1].value}`
    );

    cutoffDateTime = this.substractDays(cutoffDateTime, 6);
    cutoffDateTime.setTime(cutoffDateTime.getTime() + 12 * 60 * 60 * 1000);

    if (enteredDate < todayDate) {
      this.throwError(event);
      MicroModal.show("date-in-past");
    } else if (todayDate > cutoffDateTime) {
      // If date less than a week
      this.surgeryFillTarget.innerHTML = enteredDate.toLocaleString(
        "en",
        options
      );
      this.throwError(event);
      MicroModal.show("less-than-week");
    }
  }

  throwDateError (inputs) {
    let filledDate = true;

    inputs.forEach((input) => {
      if (input.value == "") {
        filledDate = false;
        input.focus();
        input.blur();
      }
    });
    if (!filledDate) {
      this.dateErrorTarget.classList.remove("invisible");
    }
    return filledDate;
  }

  skipDateWarning () {
    // Clicking Continue purchase on the modal sets this so it isn't show again
    localStorage.setItem("surgeryDateWarning", "skip");
  }

  clearDateWarning () {
    localStorage.removeItem("surgeryDateWarning");
  }

  throwError (event) {
    event.preventDefault();
    event.stopImmediatePropagation();
  }

  substractDays (date, days) {
    date.setDate(date.getDate() - days);

    return date;
  }

  surgeryDateWrapperElements () {
    return this.dateInputTarget.querySelectorAll(".mdc-text-field");
  }

  disableSurgeryDateFields () {
    this.dateSelectInputTargets.forEach((select) => {
      select.disabled = true;
    });

    this.surgeryDateWrapperElements().forEach((field) => {
      field.classList.add("mdc-text-field--disabled");
    });
  }
}
