import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['stepTwo'];

  static values = {
    trial: Boolean,
  };

  validateStep(event) {
    const fields_to_check = [
      "user_first_name", 
      "user_last_name",
      "user_password",
      "user_password_confirmation",
      "user_terms_of_service"
    ];

    if (document.querySelector("#user_agree_to_feedback_meeting")) {
      fields_to_check.push("user_agree_to_feedback_meeting");
    }

    // Prevent submit if email is invalid
    const email = document.getElementById("user_email");

    if (email && !email.value.match(/^[\w-\.+]+@([\w-]+\.)+[\w-]{2,4}$/)) {
      event.stopImmediatePropagation();
      event.preventDefault();
    }

    for (let i = 0; i < fields_to_check.length; i++) {
      const id = fields_to_check[i];
      if (!document.getElementById(id).checkValidity()) {
        event.stopImmediatePropagation();
        break;
      }
    }

    if (
      document.getElementById("user_password").value !=
      document.getElementById("user_password_confirmation").value
    ) {
      document
        .getElementById("user_password_confirmation")
        .setCustomValidity("The password confirmation does not match.");
      event.stopImmediatePropagation();
    } else {
      // input is valid -- reset the error message
      document
        .getElementById("user_password_confirmation")
        .setCustomValidity("");
    }
  }
}
