import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "output", "lowStress", "highStress", "smiley"];

  changeOutput(event) {
    if (event.target.value < 3) {
      this.lowStressTarget.classList.remove("hidden");
      this.highStressTarget.classList.add("hidden");
    } else {
      this.highStressTarget.classList.remove("hidden");
      this.lowStressTarget.classList.add("hidden");
    }

    const new_smiley = this.smileyTargets.find(
      (target) => target.id == `reaction-smiley-${event.target.value}`
    );
    const prev_smiley = this.smileyTargets.find(
      (target) => !target.classList.contains("hidden")
    );
    new_smiley.classList.remove("hidden");
    prev_smiley.classList.add("hidden");
  }
}
