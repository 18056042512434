import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["other", "select"];

  check(event) {
    if (event.currentTarget.value == "Other") {
      this.otherTarget.classList.remove("hidden");
    } else {
      this.otherTarget.classList.add("hidden");
    }
  }

  disable(event) {
    if (event.currentTarget.checked) {
      this.otherTarget.classList.add("hidden");
      this.selectTarget.disabled = true;
    } else {
      if (this.selectTarget.value == "Other") {
        this.otherTarget.classList.remove("hidden");
      }
      this.selectTarget.disabled = false;
    }
  }
}
