import Daily_actions_controller from '../daily_actions_controller'
import MicroModal from 'micromodal'

export default class extends Daily_actions_controller {
  static targets = [
    'recoveryStoryError',
    'customStory',
    'templateStory',
    'draftStory',
    'draftStoryError',
    'finalStory',
    'finalStoryDisplay'
  ]

  checkStory (event) {
    const defaultInput = this.customStoryTarget.querySelector('textarea')
    if (
      !this.customStoryTarget.classList.contains('hidden') &&
      defaultInput.value.length < 100
    ) {
      this.throwError(event, this.recoveryStoryErrorTarget)
      defaultInput.focus()
      defaultInput.blur()
    } else {
      this.recoveryStoryErrorTarget.style.display = 'none'
    }
  }

  fillStory (event) {
    let story = ''
    const draftTarget = this.draftStoryTarget

    // If not using our template
    if (!this.customStoryTarget.classList.contains('hidden')) {
      document.getElementById('finalStory').setAttribute('storyType', 'custom')
      story = this.customStoryTarget.querySelector('textarea').value

      draftTarget.innerHTML = story
      this.fillFinalStory()

      document.getElementById('next-btn').click()
    } else {
      // If using our template
      document
        .getElementById('finalStory')
        .setAttribute('storyType', 'template')
      story = this.templateStoryTarget.innerHTML

      story = story
        .replace(
          /<template data-dropdown-fill-target="dropdown">((.|\n)*?)[[$&+,:;=?@#|'<>.^*()%!-]?.?(<\/template>)/g,
          ''
        )
        .replace(/<.*>/g, '')
        .replace(/\n+/g, ' ')
        .replace('I know', '\n\nI know')
        .replace("I'm working", "\n\nI'm working")
        .trim()

      draftTarget.innerHTML = story
      draftTarget.value = story

      // Update character counter
      const letterCount =
        draftTarget.innerHTML.length > 750 ? 750 : draftTarget.innerHTML.length
      draftTarget.nextSibling.innerHTML = `${letterCount} / ${draftTarget.maxLength}`
    }
  }

  checkDraftStory (event) {
    if (this.draftStoryTarget.value.length < 100) {
      this.throwError(event, this.draftStoryErrorTarget)
    } else {
      this.draftStoryErrorTarget.style.display = 'none'
    }
  }

  fillFinalStory () {
    // Update the final value to the draft story entered on the previous page
    // Format the same text above and update the displayed text
    this.finalStoryTarget.value = this.draftStoryTarget.value
    this.finalStoryDisplayTarget.innerHTML = this.finalStoryTarget.value
      .replace('I know', '</br> </br> I know')
      .replace("I'm working", "</br> </br> I'm working")
  }

  showOrSkipEditView () {
    // If custom story, skip 3rd step if clicking back on last step
    if (!this.customStoryTarget.classList.contains('hidden')) {
      document.getElementById('back-btn').click()
    }
  }

  throwError (event, target) {
    event.stopImmediatePropagation()
    target.style.display = 'block'
    target.scrollIntoView({ behavior: 'smooth' })
  }

  enableSupporterResponseModal (event) {
    event.stopImmediatePropagation()
    MicroModal.show('enable-supporter-response')
  }
}
