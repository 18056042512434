// Reconnect ActionCable after switching accounts

import { Controller } from 'stimulus'
import Chart from 'chart.js/auto'
import annotationPlugin from 'chartjs-plugin-annotation'
Chart.register(annotationPlugin)

export default class extends Controller {
  static targets = ['wellbeing', 'hydration', 'meditation', 'nighttime']

  initialize() {
    if (this.hasWellbeingTarget) {
      this.createWellbeingChart()
    }

    if (this.hasHydrationTarget) {
      this.createMeditationChart()
    }

    if (this.hasMeditationTarget) {
      this.createHydrationChart()
    }
    this.createNighttimeChart()
  }

  createWellbeingChart() {
    const ctx = this.wellbeingTarget.getContext('2d')
    const currentPageIndex = this.wellbeingTarget.dataset.page
    const labels = JSON.parse(this.wellbeingTarget.dataset.labels)[
      currentPageIndex
    ]
    const physicalData = JSON.parse(this.wellbeingTarget.dataset.physical)[
      currentPageIndex
    ]
    const emotionalData = JSON.parse(this.wellbeingTarget.dataset.emotional)[
      currentPageIndex
    ]
    const gradient1 = ctx.createLinearGradient(0, 0, 0, 300)

    gradient1.addColorStop(0, 'rgba(0, 176, 213, 0.32)')
    gradient1.addColorStop(0.5, 'rgba(247, 254, 255, 0.5)')
    gradient1.addColorStop(0.8, 'rgba(255, 255, 255, 0.9)')

    const gradient2 = ctx.createLinearGradient(0, 0, 0, 300)
    gradient2.addColorStop(0, 'rgba(151, 105, 219, 0.29)')
    gradient2.addColorStop(0.5, 'rgba(247, 242, 253, 0.5)')
    gradient2.addColorStop(0.8, 'rgba(255, 255, 255, 0.9)')

    const data = {
      labels,
      datasets: [
        {
          label: 'Physical Wellbeing',
          pointBackgroundColor: '#00B3D9',
          borderColor: '#00B3D9',
          borderWidth: 1,
          radius: 2.5,
          data: physicalData,
          backgroundColor: gradient1,
          spanGaps: true,
          fill: true
          // fill: {
          //   target: 'origin',
          //   above: 'rgba(0, 176, 213, 0.32)',   // Area will be red above the origin
          // }
        },
        {
          label: 'Emotional Wellbeing',
          pointBackgroundColor: '#533DA3',
          borderColor: '#533DA3',
          borderWidth: 1,
          radius: 2.5,
          data: emotionalData,
          backgroundColor: gradient2,
          spanGaps: true,
          fill: true
          // fill: {
          //   target: 'origin',
          //   above: 'rgba(151, 105, 219, 0.29)',   // Area will be red above the origin
          // }
        }
      ]
    }

    const config = {
      type: 'line',
      data,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          },
          autocolors: false
        },
        scales: {
          yAxis: {
            min: 0,
            max: 5,
            grid: {
              borderColor: '#ffffff',
              color: '#EAEAF5'
            },
            ticks: {
              font: {
                size: 22
              },
              callback: function (value) {
                switch (value) {
                  case 0:
                    return '😢'
                  case 1:
                    return '😟'
                  case 2:
                    return '🫤'
                  case 3:
                    return '🙂'
                  case 4:
                    return '😀'
                  case 5:
                    return '😁'
                }
              }
            }
          },
          xAxis: {
            grid: {
              display: false
            }
          }
        }
      }
    }

    const myChart = new Chart(this.wellbeingTarget, config)
    if (Weglot.initialized) {
      this.setSurgeryAnnotation(myChart, labels);
      myChart.update();
    } else {
      Weglot.on("initialized", () => {
        this.setSurgeryAnnotation(myChart, labels);
        myChart.update();
      });
    }
  }

  createHydrationChart() {
    const data = {
      labels: ['Yes', 'No'],
      datasets: [
        {
          label: 'My First Dataset',
          data: [
            this.hydrationTarget.dataset.hydrationYes,
            100 - this.hydrationTarget.dataset.hydrationYes
          ],
          backgroundColor: ['#00B1D9', '#E9E9F5'],
          hoverOffset: 4
        }
      ]
    }

    const config = {
      type: 'doughnut',
      data,
      options: {
        plugins: {
          legend: {
            display: false
          }
        },
        borderColor: '#F4F6FD',
        borderRadius: 10,
        borderWidth: 10,
        cutout: '70%'
      }
    }

    new Chart(this.hydrationTarget, config)
  }

  createMeditationChart() {
    const data = {
      labels: ['Yes', 'No'],
      datasets: [
        {
          label: 'My First Dataset',
          data: [
            this.meditationTarget.dataset.meditationYes,
            100 - this.meditationTarget.dataset.meditationYes
          ],
          backgroundColor: ['#543EA3', '#E9E9F5'],
          hoverOffset: 4
        }
      ]
    }

    const config = {
      type: 'doughnut',
      data,
      options: {
        plugins: {
          legend: {
            display: false
          }
        },
        borderColor: '#F4F6FD',
        borderRadius: 10,
        borderWidth: 10,
        cutout: '70%'
      }
    }

    new Chart(this.meditationTarget, config)
  }

  createNighttimeChart() {
    if (!this.hasNighttimeTarget) {
      return
    }

    const annotation1 = {
      type: 'line',
      scaleID: 'x',
      borderWidth: 1,
      borderColor: 'black',
      borderDash: [8, 8],
      borderDashOffset: 0,
      value: 0
    }

    const labels = JSON.parse(this.nighttimeTarget.dataset.labels)
    const bedtime = this.nighttimeTarget.dataset.bedtime

    const barRadius = {
      topLeft: 50,
      topRight: 50,
      bottomLeft: 50,
      bottomRight: 50
    }
    const data = {
      labels,
      datasets: [
        {
          label: 'Awake',
          backgroundColor: '#ffffff',
          borderColor: '#ffffff',
          data: JSON.parse(this.nighttimeTarget.dataset.awake),
          borderRadius: barRadius,
          borderSkipped: false
        },
        {
          label: 'Time without screens',
          backgroundColor: '#01c2D9',
          borderColor: '#00B3D9',
          data: JSON.parse(this.nighttimeTarget.dataset.screens),
          borderRadius: barRadius,
          borderSkipped: false
        },
        {
          label: 'Sleep',
          backgroundColor: '#503E9D',
          borderColor: '#503E9D',
          data: JSON.parse(this.nighttimeTarget.dataset.sleeping),
          borderRadius: barRadius,
          borderSkipped: false
        },
        {
          label: 'Ideal',
          backgroundColor: '#EAEAF5',
          borderColor: '#EAEAF5',
          data: JSON.parse(this.nighttimeTarget.dataset.ideal),
          borderRadius: barRadius,
          borderSkipped: false
        }
      ]
    }

    const bedtimeCalc = 1317

    const config = {
      type: 'bar',
      data,
      options: {
        maintainAspectRatio: false,
        events: [],
        barThickness: 12,
        scales: {
          x: {
            stacked: true,
            grid: {
              drawBorder: false
            },
            ticks: {
              count: 6,
              precision: 0,
              stepSize: 200,
              callback: function (value, index, ticks) {
                if (index === 0) {
                  return bedtime
                } else {
                  let totalMins = parseInt(bedtimeCalc) + parseInt(value)

                  totalMins = totalMins - 1440

                  const hours = ~~(totalMins / 60)

                  if (totalMins > 720 && totalMins < 1440) {
                    return `${hours - 12} PM`
                  } else {
                    return hours == 0 ? '12 AM' : `${hours} AM`
                  }
                }
              }
            }
          },
          y: {
            stacked: true,
            grid: {
              display: false
            }
          }
        },
        indexAxis: 'y',
        // Elements options apply to all of the options unless overridden in a dataset
        // In this case, we are setting the border of each horizontal bar to be 2px wide
        elements: {
          bar: {
            borderWidth: 2
          }
        },
        responsive: true,
        plugins: {
          legend: {
            display: false
          },
          title: {
            display: false
          },
          annotation: {
            annotations: {
              annotation1
            }
          }
        }
      }
    }

    new Chart(this.nighttimeTarget, config)
  }

  // Hitting next button on chart modal will show next array of data
  nextData(event) {
    const nextButton = event.currentTarget
    const prevButton = nextButton.previousElementSibling
    const chartTarget = nextButton
      .closest('.chart-group')
      .querySelector('canvas')
    const currentPage = parseInt(chartTarget.dataset.page)
    // Index of last array of data
    const lastDataIndex = JSON.parse(chartTarget.dataset.labels).length - 1

    prevButton.classList.remove('disabled-page-button')
    if (currentPage < lastDataIndex) {
      chartTarget.dataset.page = currentPage + 1
    }

    if (chartTarget.dataset.page == lastDataIndex) {
      nextButton.classList.add('disabled-page-button')
    }

    this.updateChart(chartTarget)
  }

  // Hitting previous button on chart modal will show next array of data
  prevData(event) {
    const prevButton = event.currentTarget
    const nextButton = prevButton.nextElementSibling
    const chartTarget = prevButton
      .closest('.chart-group')
      .querySelector('canvas')
    const currentPage = parseInt(chartTarget.dataset.page)

    nextButton.classList.remove('disabled-page-button')
    if (currentPage > 0) {
      chartTarget.dataset.page = currentPage - 1
    }

    if (chartTarget.dataset.page == 0) {
      prevButton.classList.add('disabled-page-button')
    }

    this.updateChart(chartTarget)
  }

  // Updates the datasets and labels passed to the chart instance
  updateChart(chartTarget) {
    const chartInstance = Chart.getChart(chartTarget.id)
    const physicalData = JSON.parse(chartTarget.dataset.physical)
    const emotionalData = JSON.parse(chartTarget.dataset.emotional)
    const labelData = JSON.parse(chartTarget.dataset.labels)
    const dataIndex = chartTarget.dataset.page
    const newLabels = labelData[dataIndex]

    chartInstance.data.labels = newLabels
    chartInstance.data.datasets[0].data = physicalData[dataIndex]
    chartInstance.data.datasets[1].data = emotionalData[dataIndex]
    this.setSurgeryAnnotation(chartInstance, newLabels)
    chartInstance.update()
  }

  async setSurgeryAnnotation(chartInstance, labels) {
    const surgeryDate = this.wellbeingTarget.dataset.surgeryDate
    const surgeryDateExists = labels.some((label) => label === surgeryDate)
    let surgeryLabel = 'Surgery';

    const translatedLabel = await new Promise((resolve) => {
      Weglot.translate(
        {
          'words': [{ "t": 1, "w": surgeryLabel }],
          'languageTo': Weglot.getCurrentLang()
        },
        function(data) {
          resolve(data[0]);
        }
      );
    });

    if (surgeryDate && surgeryDateExists) {
      const annotation_data = {
        annotations: {
          line1: {
            type: 'line',
            font: { size: '10px' },
            xMin: surgeryDate,
            xMax: surgeryDate,
            borderColor: '#EAEAF5',
            borderWidth: 2,
            label: {
              display: true,
              color: '#432F8B',
              backgroundColor: '#EAEAF5',
              position: '10%',
              content: translatedLabel
            }
          }
        }
      }
      chartInstance.config.options.plugins.annotation = annotation_data
    } else {
      chartInstance.config.options.plugins.annotation = null
    }
  }
}