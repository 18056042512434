import Daily_actions_controller from "../daily_actions_controller";

export default class extends Daily_actions_controller {
  static targets = [
    "cookingOption",
    "cookingCustom",
    "cookingError",
    "comfortOption",
    "comfortCustom",
    "comfortError",
    "convenienceOption",
    "convenienceCustom",
    "convenienceError",
    "cautionOption",
    "cautionCustom",
    "cautionError",
    "customDisplayCard",
    "cookingCustomDisplay",
    "comfortCustomDisplay",
    "convenienceCustomDisplay",
    "cautionCustomDisplay",
  ];

  checkCooking(event) {
    let filled = false;
    this.cookingCustomDisplayTarget.replaceChildren();

    this.cookingOptionTargets.forEach((option) => {
      if (option.checked) {
        filled = true;

        const newCard = option.nextElementSibling.cloneNode(true);
        newCard.firstElementChild.remove();
        this.cookingCustomDisplayTarget.appendChild(newCard);
      }
    });

    this.cookingCustomTargets.forEach((option) => {
      if (option.value.length !== 0) {
        filled = true;

        const newCard = this.customDisplayCardTarget.content.cloneNode(true);
        newCard.getElementById("customText").innerHTML = option.value;
        this.cookingCustomDisplayTarget.appendChild(newCard);
      }
    });

    if (!filled) {
      this.throwError(event, this.cookingErrorTarget);
    } else {
      this.cookingErrorTarget.style.display = "none";
    }
  }

  checkComfort(event) {
    let filled = false;
    this.comfortCustomDisplayTarget.replaceChildren();

    this.comfortOptionTargets.forEach((option) => {
      if (option.checked) {
        filled = true;

        const newCard = option.nextElementSibling.cloneNode(true);
        newCard.firstElementChild.remove();
        this.comfortCustomDisplayTarget.appendChild(newCard);
      }
    });

    this.comfortCustomTargets.forEach((option) => {
      if (option.value.length !== 0) {
        filled = true;

        const newCard = this.customDisplayCardTarget.content.cloneNode(true);
        newCard.getElementById("customText").innerHTML = option.value;
        this.comfortCustomDisplayTarget.appendChild(newCard);
      }
    });

    if (!filled) {
      this.throwError(event, this.comfortErrorTarget);
    } else {
      this.comfortErrorTarget.style.display = "none";
    }
  }

  checkConvenience(event) {
    let filled = false;
    this.convenienceCustomDisplayTarget.replaceChildren();

    this.convenienceOptionTargets.forEach((option) => {
      if (option.checked) {
        filled = true;

        const newCard = option.nextElementSibling.cloneNode(true);
        newCard.firstElementChild.remove();
        this.convenienceCustomDisplayTarget.appendChild(newCard);
      }
    });

    this.convenienceCustomTargets.forEach((option) => {
      if (option.value.length !== 0) {
        filled = true;

        const newCard = this.customDisplayCardTarget.content.cloneNode(true);
        newCard.getElementById("customText").innerHTML = option.value;
        this.convenienceCustomDisplayTarget.appendChild(newCard);
      }
    });

    if (!filled) {
      this.throwError(event, this.convenienceErrorTarget);
    } else {
      this.convenienceErrorTarget.style.display = "none";
    }
  }

  checkCaution(event) {
    let filled = false;
    this.cautionCustomDisplayTarget.replaceChildren();

    this.cautionOptionTargets.forEach((option) => {
      if (option.checked) {
        filled = true;

        const newCard = option.nextElementSibling.cloneNode(true);
        newCard.firstElementChild.remove();
        this.cautionCustomDisplayTarget.appendChild(newCard);
      }
    });

    this.cautionCustomTargets.forEach((option) => {
      if (option.value.length !== 0) {
        filled = true;

        const newCard = this.customDisplayCardTarget.content.cloneNode(true);
        newCard.getElementById("customText").innerHTML = option.value;
        this.cautionCustomDisplayTarget.appendChild(newCard);
      }
    });

    if (!filled) {
      this.throwError(event, this.cautionErrorTarget);
    } else {
      this.cautionErrorTarget.style.display = "none";
    }
  }

  throwError(event, target) {
    event.stopImmediatePropagation();
    target.style.display = "block";
    target.scrollIntoView({ behavior: "smooth" });
  }
}
