import { Controller } from "stimulus";
import { MaterialForms } from "../src/material_forms.js";

export default class extends Controller {
  static targets = ["counter", "input"];

  connect() {
    this.updateCounter();
  }

  updateCounter(event) {
    const letterCount = this.inputTarget.value.length;
    this.counterTarget.innerHTML = letterCount;

    if (letterCount > this.inputTarget.maxLength) {
      this.counterTarget.parentElement.classList.add("text-system-red");
    } else {
      this.counterTarget.parentElement.classList.remove("text-system-red");
    }
  }
}
