// Reconnect ActionCable after switching accounts

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["stepOne", "stepTwo"];

  validateStepOne(event) {
    if (!this.stepOneTarget.classList.contains("hidden")) {
      var fields_to_check = [
        "program_gifted_by_first_name",
        "program_gifted_by_email",
        "program_terms",
      ];
    }

    if (!this.stepTwoTarget.classList.contains("hidden")) {
      var fields_to_check = [
        "program_first_name",
        "program_last_name",
        "program_email",
        "program_terms",
      ];
    }

    for (let i = 0; i < fields_to_check.length; i++) {
      const id = fields_to_check[i];
      if (!document.getElementById(id).checkValidity()) {
        event.stopImmediatePropagation();
        break;
      }
    }
  }

  textVisibility() {
    if (this.stepOneTarget.classList.remove("hidden")) {
      document.getElementById("disclaimer").classList.add("hidden");
      document.getElementById("redirectNotice").classList.add("hidden");
    }

    if (this.stepTwoTarget.classList.remove("hidden")) {
      document.getElementById("disclaimer").classList.remove("hidden");
      document.getElementById("redirectNotice").classList.remove("hidden");
    }
  }
}
