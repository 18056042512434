import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'noNotificationsMessage',
    'notifications',
    'notificationIcon',
    'markReadButton'
  ]

  markRead () {
    // Show no notifications message
    this.noNotificationsMessageTarget.classList.remove('hidden')

    this.notificationsTarget.classList.add('hidden')
    if (this.hasNotificationIconTarget) {
      this.notificationIconTarget.classList.add('hidden')
    }
    this.markReadButtonTarget.classList.add('hidden')
  }
}
