import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'bedtimeError',
    'screenOffError',
    'nextButton',
    'noteList',
    'noteView',
    'noteViewDate',
    'noteViewText',
    'noteIcon',
    'bedtimeStepNextButton',
    'bedtimeCheckError',
  ]

  // This might be needed in the future. leave it here for now - John
  // Do a bedtime check when the step is rendered
  // connect(){
  //   const observer = new IntersectionObserver((entries) => {
  //     entries.forEach((entry) => {
  //       if (entry.isIntersecting) {
  //         this.bedtimeCheck()
  //         observer.unobserve(entry.target);
  //       }
  //     });
  //   });

  //   // Get the element you want to observe
  //   const nighttimeStep = document.getElementById('nighttimeStep');
  //   // Start observing the element
  //   observer.observe(nighttimeStep);
  // }

  bedtimeCheck (event) {
    this.bedtimeErrorTarget.classList.add('hidden')
    this.screenOffErrorTarget.classList.add('hidden')

    const screenOffTimeTarget = this.getTimeTarget('screens-off-time')
    const inBedTimeTarget = this.getTimeTarget('in-bed-time')
    const wakeTimeTarget = this.getTimeTarget('wake-up-time')

    const screenOffHour = parseInt(screenOffTimeTarget[0].value)
    const screenOffMin = screenOffTimeTarget[1].value
    const inBedHour = parseInt(inBedTimeTarget[0].value)
    const inBedMin = inBedTimeTarget[1].value
    const wakeHour = parseInt(wakeTimeTarget[0].value)
    const wakeMin = wakeTimeTarget[1].value
    
    // Return if none of the times are filled
    if (
      !wakeHour &&
      wakeMin == '' &&
      !inBedHour &&
      inBedMin == '' &&
      !screenOffHour &&
      screenOffMin == ''
    ) {
      return
    }

    // Correct the times and set the date for accurate comparison
    const wakeTime = this.correctedTime(
      'healthy_habit_wake_up_time',
      wakeHour,
      wakeMin
    )
    const inBedTime = this.correctedTime(
      'healthy_habit_in_bed_time',
      inBedHour,
      inBedMin
    )
    const screenOffTime = this.correctedTime(
      'healthy_habit_screens_off_time',
      screenOffHour,
      screenOffMin
    )

    let error = false
    // Wake up time can't be before bedtime
    if (wakeHour && wakeMin != '' && inBedHour && inBedMin != '' && wakeTime <= inBedTime) {
      this.bedtimeErrorTarget.classList.remove('hidden')
      error = true
    }
    // Screens Off time can't be after bedtime or wake up time
    if (
      (screenOffHour && screenOffMin != '' && inBedHour && inBedMin != '' && screenOffTime > inBedTime) || 
      (screenOffHour && screenOffMin != '' && wakeHour && wakeMin != '' && screenOffTime >= wakeTime)
    ) {
      this.screenOffErrorTarget.classList.remove('hidden')
      error = true
    }

    if (!error){
      this.bedtimeStepNextButtonTarget.disabled = false;
      this.bedtimeCheckErrorTarget.style.display = "none";
      return
    } else {
      this.bedtimeStepNextButtonTarget.disabled = true;
      this.bedtimeCheckErrorTarget.style.display = "block";
    }

    event.stopImmediatePropagation()
    event.preventDefault()
  }

  checkNote (event) {
    if (event.currentTarget.value.length < 1) {
      this.nextButtonTarget.disabled = true
    } else {
      this.nextButtonTarget.disabled = false
    }
  }

  openNote (event) {
    this.noteListTarget.classList.add('hidden')
    this.noteIconTarget.classList.add('hidden')
    this.noteViewTextTarget.innerHTML =
      event.currentTarget.querySelector('#noteText').innerHTML
    this.noteViewDateTarget.innerHTML =
      event.currentTarget.querySelector('#noteDate').innerHTML
    this.noteViewTarget.classList.remove('hidden')
  }

  closeNote () {
    this.noteViewTarget.classList.add('hidden')
    this.noteListTarget.classList.remove('hidden')
    this.noteIconTarget.classList.remove('hidden')
  }

  getTimeTarget (id) {
    return document.getElementById(id).querySelectorAll('select')
  }

  correctedTime (id, hour, min) {
    // Checks if the times are in PM
    const timeInPm = document.getElementById(id).checked
    // Correct time according to AM/PM toggle enabled
    if (timeInPm && hour != 12) {
      hour = hour + 12
    } else if (!timeInPm && hour == 12) {
      hour = hour - 12
    }

    return new Date(`Jan ${timeInPm ? '1' : '2'} 2000 ${hour}:${min}`)
  }
}
