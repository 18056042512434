import Daily_actions_controller from '../daily_actions_controller'

export default class extends Daily_actions_controller {
  static targets = [
    'input',
    'display',
    'timeDisplay',
    'surgeon',
    'surgeonDisplay',
    'comingWith',
    'comingWithDisplay',
    'collecting',
    'collectingDisplay',
    'travelHours',
    'travelMins',
    'mobileInput',
    'collectingNumber',
    'stepOneField',
    'stepOneError',
    'stepTwoField',
    'stepTwoCombinedField',
    'stepTwoError',
    'stepThreeField',
    'stepThreeError',
    'stepFourField',
    'stepFourCombinedField',
    'stepFourError',
    'stepFiveField',
    'stepFiveError',
    'hours',
    'mins',
    'toggle',
    'goHome',
    'optionalField',
    'optionalFieldDisplay'
  ]

  initialize(){
    this.autoFillMultirowList()
    this.fillTimeToggles()
  }

  displayInputs () {
    this.displayTargets.forEach((element, index) => {
      // Targets have hardcoded index to make sure the order never changes
      const inputTarget = this.inputTargets.find(
        (target) => parseInt(target.dataset.indexTarget) == index
      )
      if (!inputTarget) return

      if (inputTarget.name.includes('hospital_address')) {
        element.innerHTML = `${inputTarget.value.trim()},`
      } else if (inputTarget.name.includes('hospital_floor')) {
        const floor_num = inputTarget.value.trim()
        element.innerHTML = floor_num + this.ordinalise(parseInt(floor_num))
      } else {
        element.innerHTML = inputTarget.value.trim()
      }
    })

    this.optionalFieldTargets.forEach((element, index) => {
      if (element.value == '') {
        this.optionalFieldDisplayTargets[index].style.display = 'none'
      } else {
        this.optionalFieldDisplayTargets[index].style.display = 'inline'
      }
    })
  }

  ordinalise (n) {
    if (isNaN(n)) {
      return ''
    }
    return ['st', 'nd', 'rd'][((((n + 90) % 100) - 10) % 10) - 1] || 'th'
  }

  displayTime () {
    // Check if AM/PM is toggled
    const pm = document.getElementById(
      'daily_action_actionable_attributes_arrive'
    ).checked
    let hours = this.hoursTarget.value
    // Add 24 to the hour value if (PM toggled and not 12PM) OR (AM toggled and 12AM)
    if (
      (pm && this.hoursTarget.value != 12) ||
      (!pm && this.hoursTarget.value == 12)
    ) {
      hours = parseInt(hours) + 12
    }
    const arrive = `${hours}:${this.minsTarget.value}`
    const arrive12hr = new Date( '1970-01-01T' + arrive + 'Z' ).toLocaleTimeString(
      window.Weglot.getCurrentLang() || 'en-US', 
      { timeZone: 'UTC', hour: 'numeric', minute: 'numeric' }
    )

    this.timeDisplayTargets[0].innerHTML = arrive12hr

    const arrival = parseInt(hours) * 60 + parseInt(this.minsTarget.value)
    const travel =
      parseInt(this.travelHoursTarget.value) * 60 +
      parseInt(this.travelMinsTarget.value)

    const timeTaken = parseInt(arrival) - parseInt(travel)

    let hour = String(~~(timeTaken / 60))
    if (hour < 10) {
      hour = '0' + hour
    }
    const min = timeTaken % 60 || '00'
    const leave = `${hour}:${min}`

    const leave12hr = new Date('1970-01-01T' + leave + 'Z').toLocaleTimeString(
      window.Weglot.getCurrentLang() || 'en-US',
      { timeZone: 'UTC', hour: 'numeric', minute: 'numeric' }
    )

    this.timeDisplayTargets[1].innerHTML = leave12hr
  }

  displayComingWith () {
    this.comingWithDisplayTarget.innerHTML = ''

    this.comingWithTargets.forEach((element, index) => {
      if (this.comingWithTargets.length == 1) {
        this.comingWithDisplayTarget.insertAdjacentHTML(
          'beforeend',
          `${element.value} is`
        )
      } else if (
        this.comingWithTargets.length > 1 &&
        index === this.comingWithTargets.length - 1
      ) {
        this.comingWithDisplayTarget.insertAdjacentHTML(
          'beforeend',
          `and ${element.value} are`
        )
      } else if (index != this.comingWithTargets.length - 2) {
        this.comingWithDisplayTarget.insertAdjacentHTML(
          'beforeend',
          `${element.value}, `
        )
      } else {
        this.comingWithDisplayTarget.insertAdjacentHTML(
          'beforeend',
          `${element.value} `
        )
      }
    })
  }

  displaySurgeon () {
    this.surgeonDisplayTarget.innerHTML = `${this.surgeonTargets[0].value} ${this.surgeonTargets[1].value}`
  }

  displayCollecting () {
    this.collectingDisplayTarget.innerHTML = ''

    this.collectingTargets.forEach((element, index) => {
      if (this.collectingTargets.length == 1) {
        this.collectingDisplayTarget.insertAdjacentHTML(
          'beforeend',
          `${element.value} `
        )
      } else if (
        this.collectingTargets.length > 1 &&
        index === this.collectingTargets.length - 1
      ) {
        this.collectingDisplayTarget.insertAdjacentHTML(
          'beforeend',
          `and ${element.value}`
        )
      } else if (index != this.collectingTargets.length - 2) {
        this.collectingDisplayTarget.insertAdjacentHTML(
          'beforeend',
          `${element.value}, `
        )
      } else {
        this.collectingDisplayTarget.insertAdjacentHTML(
          'beforeend',
          `${element.value} `
        )
      }
    })
  }

  changeSubmitText (event) {
    if (!event.currentTarget.checked) {
      document.getElementById('finalSubmitButton').innerHTML = 'Email me!'
    } else {
      document.getElementById('finalSubmitButton').innerHTML = 'Next'
    }
  }

  toggleMobileInput (event) {
    if (!this.hasMobileInputTarget) {
      return
    }
    if (event.currentTarget.value == 'true') {
      this.mobileInputTarget.classList.remove('hidden')
    } else {
      this.mobileInputTarget.classList.add('hidden')
    }
  }

  toggleCollectingNumber (event) {
    if (event.currentTarget.checked) {
      this.collectingNumberTarget.classList.add('hidden')
    } else {
      this.collectingNumberTarget.classList.remove('hidden')
    }
  }

  checkStepOneFields (event) {
    let filled = true

    this.stepOneFieldTargets.forEach((option) => {
      if (option.value.length == 0) {
        filled = false
        this.showRequiredField(option)
      }
    })

    if (!filled) {
      this.throwError(event, this.stepOneErrorTarget)
    } else {
      this.stepOneErrorTarget.style.display = 'none'
    }
  }

  checkStepTwoFields (event) {
    let filled = true
    this.stepTwoFieldTargets.forEach((option) => {
      if (!option.disabled && option.value.length == 0) {
        filled = false
        this.showRequiredField(option)
      }
    })

    const checkboxTarget = this.stepTwoCombinedFieldTargets.find(
      (target) => target.id == 'noone_hospital'
    )
    if (filled && !checkboxTarget.checked) {
      this.stepTwoCombinedFieldTargets.forEach((option) => {
        if (option.value.length == 0) {
          filled = false
          this.showRequiredField(option)
        }
      })
    }

    if (!filled) {
      this.throwError(event, this.stepTwoErrorTarget)
    } else {
      this.stepTwoErrorTarget.style.display = 'none'
    }
  }

  checkStepThreeFields (event) {
    const regularFields = this.stepThreeFieldTargets.filter(
      (target) => target.type != 'tel'
    )
    const phoneFields = this.stepThreeFieldTargets.filter(
      (target) => target.type == 'tel'
    )
    const filled = this.checkFilled(regularFields, phoneFields)

    if (!filled) {
      this.throwError(event, this.stepThreeErrorTarget)
    } else {
      this.stepThreeErrorTarget.style.display = 'none'
    }
  }

  checkStepFourFields (event) {
    const regularFields = this.stepFourFieldTargets.filter(
      (target) => !target.disabled && target.type != 'tel'
    )
    const phoneFields = this.stepFourFieldTargets.filter(
      (target) => target.type == 'tel'
    )
    let filled = this.checkFilled(regularFields, phoneFields)

    const checkboxTarget = this.stepFourCombinedFieldTargets.find(
      (target) => target.id == 'noone_home'
    )
    if (filled && !checkboxTarget.checked) {
      this.stepFourCombinedFieldTargets.forEach((option) => {
        if (option.value.length == 0) {
          filled = false
          this.showRequiredField(option)
        }
      })
    }

    if (!filled) {
      this.throwError(event, this.stepFourErrorTarget)
    } else {
      this.stepFourErrorTarget.style.display = 'none'
    }
  }

  checkStepFiveFields (event) {
    const regularFields = this.stepFiveFieldTargets.filter(
      (target) => target.type != 'tel'
    )
    const phoneFields = this.stepFiveFieldTargets.filter(
      (target) => target.type == 'tel'
    )
    const filled = this.checkFilled(regularFields, phoneFields)

    if (!filled) {
      this.throwError(event, this.stepFiveErrorTarget)
    } else {
      this.stepFiveErrorTarget.style.display = 'none'
    }
  }

  disableHomeTime (event) {
    if (event.currentTarget.checked) {
      this.hoursTargets[1].disabled = true
      this.minsTargets[1].disabled = true
      this.toggleTargets[1].disabled = true
      this.hoursTargets[1].classList.add('bg-disable')
      this.minsTargets[1].classList.add('bg-disable')
    } else {
      this.hoursTargets[1].disabled = false
      this.minsTargets[1].disabled = false
      this.toggleTargets[1].disabled = false
      this.hoursTargets[1].classList.remove('bg-disable')
      this.minsTargets[1].classList.remove('bg-disable')
    }
  }

  goHome (event) {
    const type = event.currentTarget.dataset.target
    if (type == 'enable') {
      this.goHomeTarget.classList.remove('hidden')
    } else {
      this.goHomeTarget.classList.add('hidden')
    }
  }

  showRequiredField (target) {
    target.focus()
    target.blur()
  }

  checkFilled (regularFields, phoneFields) {
    let filled = true
    regularFields.forEach((option) => {
      if (option.value.length == 0) {
        filled = false
        this.showRequiredField(option)
      }
    })

    phoneFields.forEach((option) => {
      if (
        option.value.length == 0 ||
        option.classList.contains('phone-error')
      ) {
        filled = false
        option.classList.add('phone-error')
      }
    })

    return filled
  }

  throwError (event, target) {
    event.stopImmediatePropagation()
    target.style.display = 'block'
    target.scrollIntoView({ behavior: 'smooth' })
  }

  autoFillMultirowList() {
    if (localStorage.getItem(window.location) != null){
      const data = JSON.parse(localStorage.getItem(window.location));

      let rows = data['daily_action[actionable_attributes][going_hospital_with][]'].length

      for (let i = 0; i < rows; i++) {
        if(i==0) {
          document.querySelector('input[name="daily_action[actionable_attributes][going_hospital_with][]"]').value = data['daily_action[actionable_attributes][going_hospital_with][]'][i]
        }else{
          let new_row = document.getElementById('hospital-with-template').cloneNode(true)
          new_row.querySelector('input[name="daily_action[actionable_attributes][going_hospital_with][]"]').value = data['daily_action[actionable_attributes][going_hospital_with][]'][i]

          document.getElementById('going-hospital').appendChild(new_row)
        }
      }
    }
  }

  fillTimeToggles() {
    if (localStorage.getItem(window.location) != null){
      const data = JSON.parse(localStorage.getItem(window.location));
      const timeToggles = document.querySelectorAll('.dn');
      timeToggles.forEach((toggle) => {
        const name = toggle.dataset.name;
        const value = data[name];
        if (value){
          toggle.checked = true;
        }else{
          toggle.checked = false;
        }
      });
    }
  }
}
