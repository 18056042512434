const sessionTimeoutPollFrequency = 30;

function pollForSessionTimeout() {
  if (!gon.user_signed_in) return;

  const request = new XMLHttpRequest();
  request.onload = function (event) {
    const status = event.target.status;
    const response = event.target.response;

    // if the remaining valid time for the current user session is less than or equals to 0 seconds.
    if (status === 200 && response <= 0) {
      window.location.href = "/session_timeout";
    }
  };
  request.open("GET", "/check_session_timeout", true);
  request.responseType = "json";
  request.send();
  setTimeout(pollForSessionTimeout, sessionTimeoutPollFrequency * 1000);
}

window.setTimeout(pollForSessionTimeout, sessionTimeoutPollFrequency * 1000);

// Make an Ajax request every 60 seconds to update last_request_time if user is not idle in that time.
document.addEventListener("turbo:load", function (event) {
  if (!gon.user_signed_in) return;

  var idleTime = 0;

  function resetIdleTime() {
    idleTime = 0;
  }

  // Increment idleTime every minute (60000 milliseconds)
  setInterval(function () {
    if (idleTime == 0) {
      Rails.ajax({
        type: "put",
        url: "/update_session_timeout",
      });
    }
    idleTime++;
  }, 60000);

  // Reset idleTime on mouse movement or key press
  document.addEventListener("click", resetIdleTime);
  document.addEventListener("keydown", resetIdleTime);
});
