import { Controller } from 'stimulus'

export default class extends Controller {
  static values = { location: String, checkbox: Boolean }

  prefillForm () {
    const checkbox = document
        .getElementById(this.locationValue)
        .querySelector('#edit-task-time-unknown')

    if (this.checkboxValue == true && !checkbox.checked) {
      checkbox.click()
    }
  }
}
