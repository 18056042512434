import { Controller } from 'stimulus'
import MicroModal from 'micromodal'

export default class extends Controller {
  open (event) {
    MicroModal.show(event.currentTarget.dataset.modalName);
  }

  close (event) {
    MicroModal.close(event.currentTarget.dataset.closeModalName);
  }

  closeSelf (event) {
    const closeModal = event.currentTarget.closest('.micromodal-slide');
    MicroModal.close(closeModal.id);
  }

  freeBetaModal() {
    MicroModal.show('free-program-intro');
  }

  openDemoModal() {
    MicroModal.show(this.element.id);
  }
}
