import { Controller } from "stimulus";
import { MaterialForms } from "../src/material_forms.js";

export default class extends Controller {
  static targets = ["template"];

  addAssociation(event) {
    event.preventDefault();

    var content = this.templateTarget.innerHTML.replace(
      /TEMPLATE_RECORD/g,
      new Date().valueOf()
    );

    event.target.insertAdjacentHTML("beforebegin", content);

    MaterialForms.init();
  }

  removeAssociation(event) {
    event.preventDefault();

    let item = event.target.closest(".form-fields");

    item.querySelector("input[name*='_destroy']").value = 1;
    item.style.display = "none";
  }
}
