// Reconnect ActionCable after switching accounts

import { Controller } from 'stimulus'
import Amplitude from 'amplitudejs'

export default class extends Controller {
  static targets = ['play', 'stop']

  initialize () {
    if (document.getElementById('audioPlayer')) {
      Amplitude.init({
        songs: [
          {
            name: 'Meditation',
            url: document.getElementById('audioPlayer').dataset.fileUrl
          }
        ],
        callbacks: {
          play: function () {
            document.getElementById('playBtn').classList.add('hidden')
            document.getElementById('stopBtn').classList.remove('hidden')
          },

          pause: function () {
            document.getElementById('stopBtn').classList.add('hidden')
            document.getElementById('playBtn').classList.remove('hidden')
          }
        }
      })
    }

    if (!document.getElementById('song-played-progress')) {
      return
    }
    /*
      Handles a click on the song played progress bar.
    */
    document
      .getElementById('song-played-progress')
      .addEventListener('click', function (e) {
        const offset = this.getBoundingClientRect()
        const x = e.pageX - offset.left

        Amplitude.setSongPlayedPercentage(
          (parseFloat(x) / parseFloat(this.offsetWidth)) * 100
        )
      })
  }

  disconnect (event) {
    Amplitude.stop()
  }
}
