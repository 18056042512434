import Daily_actions_controller from "../daily_actions_controller";

export default class extends Daily_actions_controller {
  validateNote(event) {
    const submitButton = document.getElementById("finalSubmitButton");

    if (event.currentTarget.value.length > 0) {
      submitButton.disabled = false;
      submitButton.classList.remove("bg-disable");
      submitButton.classList.remove("pointer-events-none");
      submitButton.classList.add("bg-violet");
    } else if (submitButton.disabled == false) {
      submitButton.disabled = true;
      submitButton.classList.add("bg-disable");
      submitButton.classList.add("pointer-events-none");
      submitButton.classList.remove("bg-violet");
    }
  }
}
