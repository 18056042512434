import { Controller } from 'stimulus'
import Chart from 'chart.js/auto'
import MicroModal from 'micromodal'

export default class extends Controller {
  static targets = ['movementChart']

  initialize () {
    MicroModal.init({disableScroll: true})
    this.movementChartTargets.forEach((target) =>
      this.createMovementChart(target)
    )
  }

  openModal (event) {
    MicroModal.show(
      `mindful-movement-${event.currentTarget.dataset.movementName}`
    )
  }

  createMovementChart (target) {
    const unit = target.dataset.movementUnit
    const lastDataIndex = target.dataset.page
    const labels = JSON.parse(target.dataset.movementLabels)[lastDataIndex]
    const movementData = JSON.parse(target.dataset.movementData)[lastDataIndex]

    const data = {
      labels,
      datasets: [
        {
          backgroundColor: '#503E9D',
          borderColor: '#503E9D',
          barThickness: 12,
          borderRadius: 12.5,
          data: movementData
        }
      ]
    }

    const config = {
      type: 'bar',
      data,
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: false
          },
          title: {
            display: false
          },
          label: {
            display: false
          }
        },
        scales: {
          yAxis: {
            max: this.getMaxYTick(movementData),
            grid: {
              borderColor: '#ffffff',
              color: '#EAEAF5'
            },
            ticks: {
              stepSize: 0.5,
              // Include a dollar sign in the ticks
              callback: function (value) {
                return `${value} ${unit}`
              }
            }
          },
          xAxis: {
            grid: {
              display: false
            }
          }
        }
      }
    }

    new Chart(target, config)
  }

  // Hitting next button on chart modal will show next array of data
  nextData (event) {
    const nextButton = event.currentTarget
    const prevButton = nextButton.previousElementSibling
    const chartTarget = nextButton
      .closest('.chart-group')
      .querySelector('canvas')
    const labelTarget = prevButton.closest('.micromodal-slide').querySelector('#label-text')
    const currentPage = parseInt(chartTarget.dataset.page)
    // Index of last array of data
    const lastDataIndex =
      JSON.parse(chartTarget.dataset.movementData).length - 1

    prevButton.classList.remove('disabled-page-button')
    if (currentPage < lastDataIndex) {
      chartTarget.dataset.page = currentPage + 1
    }

    if (chartTarget.dataset.page == lastDataIndex) {
      nextButton.classList.add('disabled-page-button')
    }

    this.updateChart(chartTarget, labelTarget)
  }

  // Hitting previous button on chart modal will show next array of data
  prevData (event) {
    const prevButton = event.currentTarget
    const nextButton = prevButton.nextElementSibling
    const chartTarget = prevButton
      .closest('.chart-group')
      .querySelector('canvas')
    const labelTarget = prevButton.closest('.micromodal-slide').querySelector('#label-text')
    const currentPage = parseInt(chartTarget.dataset.page)

    nextButton.classList.remove('disabled-page-button')
    if (currentPage > 0) {
      chartTarget.dataset.page = currentPage - 1
    }

    if (chartTarget.dataset.page == 0) {
      prevButton.classList.add('disabled-page-button')
    }

    this.updateChart(chartTarget, labelTarget)
  }

  // Updates the datasets and labels passed to the chart instance
  updateChart (chartTarget, labelTarget) {
    const dataIndex = chartTarget.dataset.page
    const movementData = JSON.parse(chartTarget.dataset.movementData)[dataIndex]
    const labelData = JSON.parse(chartTarget.dataset.movementLabels)
    const chartInstance = Chart.getChart(chartTarget.id)
    const currentLabels = labelData[dataIndex]

    labelTarget.innerText = `${currentLabels[0]} - ${currentLabels[currentLabels.length - 1]}`
    chartInstance.data.labels = currentLabels
    chartInstance.config.options.scales.yAxis.max = this.getMaxYTick(movementData)
    chartInstance.data.datasets[0].data = movementData
    chartInstance.update()
  }

  updateDateRange (event) {
    const modal = event.currentTarget.closest('.micromodal-slide')
    const chartIndex = event.currentTarget.id == 'distance-tab' ? 1 : 0
    const chartTarget = modal.querySelectorAll('canvas')[chartIndex]
    const labelTarget = modal.querySelector('#label-text')
    const dataIndex = chartTarget.dataset.page
    const labelData = JSON.parse(chartTarget.dataset.movementLabels)
    const currentLabels = labelData[dataIndex]
    labelTarget.innerText = `${currentLabels[0]} - ${currentLabels[currentLabels.length - 1]}`
  }

  getMaxYTick(data) {
    const maxYAxisValue = Math.max(...data)
    const midValue = (Math.floor(maxYAxisValue) + Math.ceil(maxYAxisValue)) / 2
    return maxYAxisValue > midValue ? Math.ceil(maxYAxisValue) : midValue
  }
}
