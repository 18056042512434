import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = [
    'form',
    'step',
    'intro',
    'progress',
    'line',
    'check',
    'back',
    'progressBar',
    'renderProgressBar',
    'progressWaveImage',
    'progressWave',
    'progressWaveTop',
    'nextButton',
    'requiredItem',
    'radioButton',
    'radioButtonCustom',
    'surgeryType',
    'surgeryTypeOther',
    'surgeryTypeBlank',
    'dob',
    'error',
    'hours',
    'mins',
    'toggle',
    'reminderError',
    'referredEmail'
  ]

  static values = { index: Number }

  initialize () {
    // check length cause if you go back turbo causes this to rerender
    if (this.hasProgressBarTarget && this.progressTargets.length == 1) {
      for (let i = 0; i < this.stepTargets.length - 1; i++) {
        this.progressBarTarget.insertAdjacentHTML(
          'beforeend',
          this.progressTarget.outerHTML
        )
        // line in between dots
        if (i != this.stepTargets.length - 2) {
          this.progressBarTarget.insertAdjacentHTML(
            'beforeend',
            this.lineTarget.outerHTML
          )
        }
      }
    }

    // Hide progress bar if only single step
    if (this.stepTargets.length == 1 && this.hasRenderProgressBarTarget) {
      this.renderProgressBarTarget.classList.add('hidden')
    }

    if (this.hasIntroTarget) {
      // If intro page(onboarding), need to hide progress bar.
      this.setupIntroSlide()
    } else {
      // Setup the progress bar and waves
      this.updateProgressBar()
    }
  }

  next (event) {
    event.preventDefault()
    event.stopImmediatePropagation()

    if (event.currentTarget.dataset.target == 'intro') {
      this.introTarget.classList.add('hidden')
      if (this.hasRenderProgressBarTarget) {
        this.renderProgressBarTarget.classList.remove('hidden')
      }
      if (this.hasProgressWaveImageTarget) {
        this.progressWaveImageTarget.classList.remove('hidden')
      }
      this.indexValue = 0
    } else {
      this.indexValue++
    }

    // Check if we have reached the end of the form and submit
    if (this.indexValue >= this.stepTargets.length) {
      if (this.data.get('healthyHabit') == 'true') {
        Rails.ajax({
          type: 'post',
          url: this.formTarget.action,
          data: new FormData(this.formTarget),
          success: function (data) {
            MicroModal.show('checkin-submit-modal')
          }
        })
        window.scroll(0, 0)
      } else {
        this.formTarget.submit()
      }
    } else {
      // Hide current slide and show next slide
      const currentStep = this.stepTargets[this.indexValue]
      currentStep.classList.remove('hidden')
      const prevStep = this.stepTargets[this.indexValue - 1]
      if (prevStep) {
        prevStep.classList.add('hidden')
      }
      this.updateProgressBar()
    }
  }

  previous (event) {
    event.preventDefault()
    event.stopImmediatePropagation()
    this.indexValue--

    if (this.indexValue == -1) {
      this.setupIntroSlide()
      const hideStep = this.stepTargets[0]
      hideStep.classList.add('hidden')
    } else {
      // Hide current slide and show previous slide.
      const hideStep = this.stepTargets[this.indexValue + 1]
      hideStep.classList.add('hidden')
      const showStep = this.stepTargets[this.indexValue]
      showStep.classList.remove('hidden')
      this.updateProgressBar()
    }
  }

  // Applies only to onboarding where intro shouldn't show progress bar
  setupIntroSlide () {
    this.introTarget.classList.remove('hidden')
    if (this.hasRenderProgressBarTarget) {
      this.renderProgressBarTarget.classList.add('hidden')
    }
    if (this.hasProgressWaveImageTarget) {
      this.progressWaveImageTarget.classList.add('hidden')
    }
  }

  updateProgressBar () {
    if (!this.hasBackTarget) {
      return
    }

    if (this.indexValue > 0) {
      this.backTarget.classList.remove('hidden')
    } else {
      this.backTarget.classList.add('hidden')
      // If 2 back buttons present on the same step
      if (this.data.get('backDuplicate') == 'true') {
        this.backTargets[1].classList.remove('md:block')
      }
    }

    this.progressTargets.forEach((element, index) => {
      if (index <= this.indexValue) {
        element.classList.remove('bg-lavender-gray', 'h-2', 'w-2')
        element.classList.add('bg-violet', 'h-6', 'w-6')
      } else {
        element.classList.remove('bg-violet', 'h-6', 'w-6')
        element.classList.add('bg-lavender-gray', 'h-2', 'w-2')
      }
    })

    this.checkTargets.forEach((element, index) => {
      if (index <= this.indexValue) {
        element.classList.remove('hidden')
      } else {
        element.classList.add('hidden')
      }
    })

    this.lineTargets.forEach((element, index) => {
      if (index <= this.indexValue && this.indexValue != 0) {
        element.style.width = '12px'
      } else {
        element.style.width = '20px'
      }
    })

    this.renderProgressBarTargets.forEach((element) => {
      const progress = this.progressBarTarget.cloneNode(true)
      progress.classList.remove('hidden')

      element.innerHTML = ''
      element.appendChild(progress)
    })

    window.scroll(0, 0)

    if (this.hasProgressWaveTarget) {
      const height =
        ((screen.height - 200) / this.stepTargets.length) * this.indexValue

      if (this.indexValue >= 0) {
        if (height !== -1) {
          this.progressWaveTarget.style.height = height + 'px'
          this.progressWaveTopTarget.style.display = 'block'
        } else {
          this.progressWaveTarget.style.height = '0px'
          this.progressWaveTopTarget.style.display = 'none'
        }
      } else if (this.indexValue == 1) {
        this.progressWaveTopTarget.style.display = 'block'
        this.progressWaveTarget.style.height = '0px'
      } else {
        this.progressWaveTopTarget.style.display = 'none'
      }
    }
  }

  checkInput (event) {
    const targetName = event.currentTarget.dataset.target
    let field = this.requiredItemTargets.find(
      (target) => target.id == targetName
    )
    if (!field) {
      field = event.currentTarget
    }
    const step = field.dataset.step

    if (field.value.length < 1) {
      this.disableNextButton(step)
    } else {
      this.enableNextButton(step)
    }
  }

  validateReminderTimes () {
    if (!this.hasReminderErrorTarget) {
      return
    }
    let hours_1 = this.hoursTargets[0].value
    const mins_1 = this.minsTargets[0].value
    const toggle_1 = this.toggleTargets[0].checked

    let hours_2 = this.hoursTargets[1].value
    const mins_2 = this.minsTargets[1].value
    const toggle_2 = this.toggleTargets[1].checked

    this.enableNextButton('Three')
    this.reminderErrorTarget.classList.add('hidden')

    if (toggle_1 == false && toggle_2 == true) {
      return
    }

    if (toggle_1 == true && hours_1 != 12) {
      hours_1 += 12
    } else if (hours_1 == 12) {
      hours_1 = 0
    }

    if (toggle_2 == true && hours_2 != 12) {
      hours_2 += 12
    } else if (hours_2 == 12) {
      hours_2 = 0
    }

    if (hours_1 >= hours_2 && mins_1 >= mins_2) {
      this.disableNextButton('Three')
      this.reminderErrorTarget.classList.remove('hidden')
    }
  }

  checkRadioComboInput (event) {
    // Inputs with radio buttons and custom fields
    let filled = false
    const targetName = event.currentTarget.dataset.target
    const radioButtonTargets = this.radioButtonTargets.filter((target) =>
      target.name.includes(targetName)
    )
    const customTarget = this.radioButtonCustomTargets.find((target) =>
      target.name.includes(targetName)
    )
    const step = radioButtonTargets[0].dataset.step

    radioButtonTargets.forEach((target) => {
      if (target.checked == true) {
        filled = true
      }
    })

    if (customTarget && filled == false) {
      if (customTarget.value.length > 0) {
        filled = true
      }
    } else if (customTarget && filled == true) {
      customTarget.disabled = true
    }

    if (filled) {
      this.enableNextButton(step)
    } else {
      this.disableNextButton(step)
    }
  }

  checkSurgeryDateInput (event) {
    if (
      this.surgeryTypeBlankTarget.checked == true ||
      this.surgeryTypeOtherTarget.value.length > 0 ||
      (this.surgeryTypeTarget.value != 'Other' &&
        this.surgeryTypeTarget.value.length > 0)
    ) {
      this.enableNextButton('Six')
      return
    }

    this.disableNextButton('Six')
  }

  clearCustomField () {
    this.radioButtonCustomTarget.value = ''
    this.radioButtonCustomTarget.disabled = true
  }

  clearRadioField () {
    if (this.radioButtonCustomTarget.disabled == false) {
      return
    }
    this.radioButtonCustomTarget.disabled = false
    this.radioButtonCustomTarget.focus()
    const step = this.radioButtonCustomTarget.dataset.step
    this.disableNextButton(step)
    const radioButtonTarget = this.radioButtonTargets.find(
      (target) =>
        target.checked == true && target.name == 'program[hear_about_us]'
    )
    radioButtonTarget.checked = false
  }

  validateDob (event) {
    const day = this.dobTarget.querySelector(
      '#program_user_attributes_date_of_birth_3i'
    ).value
    const month = String(
      this.dobTarget.querySelector('#program_user_attributes_date_of_birth_2i')
        .value - 1
    )
    const year = this.dobTarget.querySelector(
      '#program_user_attributes_date_of_birth_1i'
    ).value

    if (day == '' || month == '-1' || year == '') {
      this.disableNextButton('Eight')
      return
    }

    const today = new Date()
    const date = new Date(year, month, day)
    const eighteen_years_ago = new Date().setFullYear(today.getFullYear() - 18)

    // Throw error if under 18 yo
    if (!date || date > eighteen_years_ago) {
      this.disableNextButton('Eight')
      this.errorTarget.classList.remove('invisible')
    } else {
      this.enableNextButton('Eight')
      this.errorTarget.classList.add('invisible')
    }
  }

  disableNextButton (step) {
    const target = this.nextButtonTargets.find(
      (target) => target.dataset.step == step
    )
    if (!target) {
      return
    }
    target.disabled = true
    target.classList.add('bg-disable-light')
  }

  enableNextButton (step) {
    const target = this.nextButtonTargets.find(
      (target) => target.dataset.step == step
    )
    if (!target) {
      return
    }
    target.disabled = false
    target.classList.remove('bg-disable-light')
  }

  checkReferredByEmail (event) {
    if (
      this.referredEmailTarget.value.length > 0 &&
      !this.referredEmailTarget.checkValidity()
    ) {
      event.preventDefault()
      event.stopImmediatePropagation()
    }
  }
}
