import { Controller } from "stimulus";
import { enter, leave } from "el-transition";
import MicroModal from "micromodal";

const supporter_id = 0;
export default class extends Controller {
  static targets = [
    "output",
    "input",
    "deleteButton",
    "bedtime",
    "saveButton",
    "modalTriggerButton",
  ];

  initialize() {
    if (document.getElementById("subscription-cta")) {
      return;
    }

    if (document.getElementById("access-restricted")) {
      this.openModal("", "access-restricted");
    }
  }

  openModal(event, target) {
    MicroModal.show(target || event.currentTarget.dataset.modalName);
  }

  closeModal(event) {
    const closeModal = event.currentTarget.closest(".micromodal-slide");
    MicroModal.close(closeModal.id);
  }

  openResetPasswordModal() {
    document.querySelectorAll("[id=edit_user]")[1].reset();
    MicroModal.show("reset-password");

    this.inputTarget.querySelectorAll("input").forEach((input) => {
      input.offsetParent.classList.remove(
        "mdc-text-field--invalid",
        "mdc-text-field--label-floating"
      );
    });
  }

  validatePasswordReset(event) {
    document.getElementById("user_current_password").setCustomValidity("");
    const fields_to_check = [
      "user_current_password",
      "user_password",
      "user_password_confirmation",
    ];

    for (let i = 0; i < fields_to_check.length; i++) {
      const id = fields_to_check[i];
      if (!document.getElementById(id).checkValidity()) {
        event.stopImmediatePropagation();
        break;
      }
    }

    if (
      document.getElementById("user_password").value !=
      document.getElementById("user_password_confirmation").value
    ) {
      document
        .getElementById("user_password_confirmation")
        .setCustomValidity("The password confirmation does not match.");
      event.stopImmediatePropagation();
    } else {
      // input is valid -- reset the error message
      document
        .getElementById("user_password_confirmation")
        .setCustomValidity("");
    }
  }

  bedtimeToggle(event) {
    if (event.currentTarget.value == "true") {
      enter(this.bedtimeTarget);
    } else {
      leave(this.bedtimeTarget);
    }
  }

  surgeryDateChange(event) {
    const month = document.getElementById("user_surgery_date_2i").value - 1;

    const day = document.getElementById("user_surgery_date_3i").value;

    const year = document.getElementById("user_surgery_date_1i").value;

    const newDate = new Date(year, month, day);

    const oldDate = new Date(event.target.dataset.value);

    if (newDate.getTime() === oldDate.getTime()) {
      // display save button
      this.saveButtonTarget.classList.remove("hidden");
      this.modalTriggerButtonTarget.classList.add("hidden");
    } else {
      // display confirmation modal
      this.saveButtonTarget.classList.add("hidden");
      this.modalTriggerButtonTarget.classList.remove("hidden");
    }
  }

  showVersionChangeModal(event) {
    MicroModal.show("surgery-date-alert");
  }

  enableSaveButton() {
    const target = this.saveButtonTarget;
    target.disabled = false;
    target.classList.remove("bg-disable");

    const altTarget = this.modalTriggerButtonTarget;
    altTarget.disabled = false;
    altTarget.classList.remove("bg-disable");
  }

  disableSaveButton() {
    const target = this.saveButtonTarget;
    target.disabled = true;
    target.classList.add("bg-disable");

    const altTarget = this.modalTriggerButtonTarget;
    altTarget.disabled = true;
    altTarget.classList.add("bg-disable");
  }
}
