import { Controller } from "stimulus";

const index = 0;

export default class extends Controller {
  static targets = ["video", "dot"];
  static values = { index: Number };

  initialize() {
    this.indexValue = 0;
    this.videoTargets.forEach((element, index) => {
      if (index == this.indexValue) {
        element.style.display = "block";
      } else {
        element.style.display = "none";
      }
    });
  }

  next() {
    this.hideVideo();
    this.indexValue == this.videoTargets.length - 1
      ? (this.indexValue = 0)
      : (this.indexValue += 1);
    this.showVideo();
  }

  previous() {
    this.hideVideo();
    this.indexValue == 0
      ? (this.indexValue = this.videoTargets.length - 1)
      : (this.indexValue -= 1);
    this.showVideo();
  }

  hideVideo() {
    this.videoTargets[this.indexValue].pause();
    this.videoTargets[this.indexValue].style.display = "none";
    this.dotTargets[this.indexValue].classList.remove("bg-violet");
    this.dotTargets[this.indexValue].classList.add("bg-disable");
  }

  showVideo() {
    this.videoTargets[this.indexValue].style.display = "block";
    this.dotTargets[this.indexValue].classList.remove("bg-disable");
    this.dotTargets[this.indexValue].classList.add("bg-violet");
  }
}
