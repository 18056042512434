// Reconnect ActionCable after switching accounts

import { Controller } from "stimulus";
import { MaterialForms } from "../src/material_forms.js";

export default class extends Controller {
  static targets = ["output", "template", "newButton", "deleteButton"];

  new() {
    let count = this.outputTarget.childElementCount;
    let limit =
      this.outputTarget.dataset.limit == null
        ? 20
        : this.outputTarget.dataset.limit;

    if (count < limit) {
      var templateContent = this.templateTarget.content
        .querySelector("div")
        .cloneNode(true);

      var input = templateContent.getElementsByTagName("input")[0];
      if (input === undefined) {
        input = templateContent.getElementsByTagName("textarea")[0];
      }

      input.disabled = false;
      input.value = "";

      this.outputTarget.appendChild(templateContent);

      MaterialForms.init();
      count += 1;
      if (count == limit) this.newButtonTarget.classList.add("hidden");
    }
  }

  delete(event) {
    event.currentTarget.parentElement.remove();
    this.newButtonTarget.classList.remove("hidden");
  }
}
