import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "emoji"];

  changeEmoji(event) {
    this.emojiTargets.forEach(function(element, index) {
      if (index === parseInt(event.currentTarget.value)) {
        element.style.display = "block";
      } else {
        element.style.display = "none";
      }
    });
  }
}
