document.addEventListener('turbo:load', function(event) {
  const sliders = document.getElementsByClassName('slider-container')

  let observer = new IntersectionObserver(function(entries, observer) {
    // Loop over the entries
      entries.forEach(entry => {
          // If the element is visible
          if (entry.isIntersecting) {
              thumbDisplay(entry.target.querySelector('input'))
              // Do something...
              observer.unobserve(entry.target);
          }
      });
  });

  let i
  for (i = 0; i < sliders.length; i++) {
    displayDots(sliders[i])
    thumbDisplay(sliders[i].querySelector('input'))
    observer.observe(sliders[i]);
  }
})

window.addEventListener('resize', function(event) {
  const sliders = document.getElementsByClassName('slider-container')

  let i
  for (i = 0; i < sliders.length; i++) {
    thumbDisplay(sliders[i].querySelector('input'))
  }
})

function displayDots(slider) {
  const dots = slider.querySelectorAll('.dot')
  const input = slider.querySelector('input')
  const totalDistance = slider.clientWidth
  const totalPercentage = 100 - (38 / totalDistance) * 100

  let i
  for (i = 0; i < dots.length; i++) {
    dots[i].style.left = `${(100 / (dots.length - 1)) * i}%`
  }
  hideInactiveDots(input)
}

function thumbDisplay(input) {
  hideInactiveDots(input)
  const thumbValue = input.parentElement.querySelector('#thumb-value')

  const totalDistance = input.parentElement.clientWidth
  const totalPercentage = 100 - (38 / totalDistance) * 100

  thumbValue.innerHTML = input.value

  const offset = (38 - thumbValue.offsetWidth) / 2

  thumbValue.style.left = `calc(${(totalPercentage /
    (parseInt(input.getAttribute('max')) -
      parseInt(input.getAttribute('min')))) *
    (parseInt(input.value) - parseInt(input.getAttribute('min'))) +
    '%'
    } + ${offset}px)`
}

function hideInactiveDots(input) {
  const dots = input.parentElement.querySelectorAll('.dot')

  let i
  for (i = 0; i < dots.length; i++) {
    if (i == parseInt(input.value) - parseInt(input.getAttribute('min'))) {
      dots[i].classList.add('hidden')
    } else {
      dots[i].classList.remove('hidden')
    }
  }
}

window.displayDots = displayDots
window.thumbDisplay = thumbDisplay
