import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "checkmark"];

  toggleTimeInput() {
    if (
      this.checkmarkTarget
        .querySelectorAll("div")[2]
        .classList.contains("mdc-checkbox--selected")
    ) {
      this.inputTargets.forEach((element) => {
        element.classList.remove("hidden");
      });
    } else {
      this.inputTargets.forEach((element) => {
        element.classList.add("hidden");
      });
    }
  }
}
