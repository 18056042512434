import { Controller } from "stimulus";
import MicroModal from "micromodal";

export default class extends Controller {
  static targets = ["positive", "negative", "download", "submit", "date"];

  highlight(event) {
    event.currentTarget.classList.remove("grayscale");
    const otherIcon =
      event.currentTarget.dataset.daFeedbackTarget === "positive"
        ? this.negativeTarget
        : this.positiveTarget;
    otherIcon.classList.add("grayscale");
  }

  lightDownloadIcon() {
    this.downloadTargets[0].classList.add("hidden");
    this.downloadTargets[1].classList.remove("hidden");
  }

  darkDownloadIcon() {
    this.downloadTargets[0].classList.remove("hidden");
    this.downloadTargets[1].classList.add("hidden");
  }

  openFeedbackModal() {
    MicroModal.show("feedback-modal");
  }

  enableSubmit(event) {
    if (event.currentTarget.value.length > 0) {
      this.submitTarget.disabled = false;
      return;
    }
    this.submitTarget.disabled = true;
  }
}
