import { Controller } from 'stimulus'
import * as filestack from 'filestack-js'
import Rails from '@rails/ujs'
import MicroModal from 'micromodal'

export default class extends Controller {
  static values = {
    path: String
  }

  showSources (event) {
    MicroModal.show('attachment-source-modal')
  }

  open (event) {
    if (document.querySelector('#attachment-source-modal')) {
      MicroModal.close('attachment-source-modal')
    }
    const target = event.currentTarget
    const client = filestack.init(gon.filestack_api_key)
    const path = this.pathValue
    const nextModal = target.dataset.nextModal
    const closeModal = target.dataset.closeModal
    const responsePath = target.dataset.responsePath
    const reloadNeeded = target.dataset.reload

    const options = {
      accept: JSON.parse(target.dataset.acceptFormats),
      fromSources: JSON.parse(target.dataset.fileSources),
      maxSize: 100 * 1024 * 1024,
      videoResolution: "1280x720",
      imageMax: JSON.parse(target.dataset.maxDimensions || '[1000, 1000]'),
      onFileUploadFinished: function (file) {
        Rails.ajax({
          type: 'POST',
          url: path + `?info=${JSON.stringify(file)}`,
          success: function () {
            if (closeModal) {
              MicroModal.close(closeModal)
            }
            if (nextModal) {
              MicroModal.show(nextModal)
            }
            if (responsePath) {
              Rails.ajax({
                type: 'PATCH',
                url: responsePath
              })
            }
            if (reloadNeeded) {
              setTimeout(function () {
                location.reload()
              }, 1000)
            }
          }
        })
      }
    }

    const picker = client.picker(options)
    picker.open()
  }
}
