import Daily_actions_controller from '../daily_actions_controller'

export default class extends Daily_actions_controller {
  static targets = ['timeLeft', 'timerButton']

  startCountdown (event) {
    window.stopTimer = ''
    let timeLeft = 29
    const displayLocation = this.timeLeftTarget
    const hostName = event.currentTarget.dataset.hostName
    const timerEndSound = new Audio(`${hostName}/audios/RSR_chime.mp3`)

    const timerId = setInterval(countdown, 1000)
    const timerButton = this.timerButtonTarget
    timerButton.classList.add('hidden')

    document.querySelector('lottie-player').setAttribute('loop', '')
    document.querySelector('lottie-player').play()

    function countdown () {
      if (timeLeft === -1) {
        clearTimeout(timerId)

        // stop animation
        document.querySelector('lottie-player').removeAttribute('loop')

        // ding sound
        if (!window.stopTimer) {
          timerEndSound.play()
        }

        // show start button
        timerButton.classList.remove('hidden')

        // reset timer display
        displayLocation.innerHTML = ':30'
      } else {
        const precede_count = timeLeft <= 9 ? ':0' : ':'
        displayLocation.innerHTML = precede_count + timeLeft

        timeLeft--
      }
    }
  }

  stopTimer () {
    window.stopTimer = true
  }
}
