import { Controller } from 'stimulus'
import tippy, { inlinePositioning } from 'tippy.js'

export default class extends Controller {
  static targets = [
    'dropdownTrigger',
    'output',
    'dropdown',
    'choice',
    'multipleChoice'
  ]

  initialize () {
    tippy(this.dropdownTriggerTarget, {
      content: this.dropdownTarget.innerHTML,
      allowHTML: true,
      trigger: 'mouseenter',
      arrow: false,
      interactive: true,
      inlinePositioning: true,
      plugins: [inlinePositioning],
      placement: 'bottom-start'
    })
  }

  fillChoice (event) {
    this.outputTarget.innerHTML = event.target.innerHTML
    this.outputTarget.classList.remove('underline')
    this.dropdownTriggerTarget._tippy.hide()
  }

  fillMultipleChoice (event) {
    const checkedFields = this.multipleChoiceTargets.filter(
      (target) => target.checked
    )
    const count = checkedFields.length
    let output = ''

    for (let i = 0; i < count; i++) {
      if (i == 0) {
        output += checkedFields[i].value
      } else if (count > 0 && i == count - 1) {
        output += ' and ' + checkedFields[i].value
      } else {
        output += ', ' + checkedFields[i].value
      }
    }
    if (output == '') {
      output = event.currentTarget.dataset.target
    }
    this.outputTarget.innerHTML = `\n${output}.\n`
  }
}
