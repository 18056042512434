import { Controller } from "stimulus";
import Rails from "@rails/ujs";
import MicroModal from "micromodal";
export default class extends Controller {
  static targets = [
    "form",
    "supporter",
    "unreadCount",
    "field",
    "fieldError",
    "emailError",
    "decline",
    "supporterName",
    "supporterPath",
  ];

  openFirstModal() {
    const firstModal = document
      .getElementById("modal-collection")
      .querySelectorAll(".micromodal-slide")[0];
    MicroModal.show(firstModal.id);
  }

  nextModal(event) {
    // If no openTarget is present, this only closes the specified modal
    MicroModal.close(event.currentTarget.dataset.closeTarget);
    if (event.currentTarget.dataset.openTarget) {
      MicroModal.show(event.currentTarget.dataset.openTarget);
    }
  }

  nextResponseModal(event) {
    this.closeCurrentModal(event)
    const currentModal = event.currentTarget.closest(".micromodal-slide");
    let nextModal = currentModal.nextElementSibling;

    // Skip thanks modal if user hits 'Cancel'
    if (
      nextModal &&
      nextModal.id.includes("thanks") &&
      event.currentTarget.dataset.closeType == "cancel"
    ) {
      nextModal = nextModal.nextElementSibling;
    }
    if (nextModal && nextModal.classList.contains("micromodal-slide")) {
      MicroModal.show(nextModal.id);
    }
  }

  openModal(event) {
    const modalName = event.currentTarget.dataset.target;
    MicroModal.show(modalName);
  }

  openResponse(event) {
    MicroModal.show(event.currentTarget.dataset.modalName);
  }

  cancelForm(event) {
    this.closeCurrentModal(event)
    this.fieldErrorTargets.forEach((error) => {
      error.classList.add('hidden')
    })
    this.fieldTargets.forEach((field) => {
      field.parentNode.classList.remove('mdc-text-field--invalid')
    })
    this.formTarget.reset()
  }

  validateForm(event) {
    let filled = true;
    this.emailErrorTarget.classList.add('hidden')
    this.fieldTargets.forEach((field, i) => {
      if (!field.checkValidity()) {
        this.fieldErrorTargets[i].classList.remove('hidden')
        field.focus();
        field.blur();
        filled = false;
      } else {
        this.fieldErrorTargets[i].classList.add('hidden')
      }
    });

    if (!filled) {
      event.stopImmediatePropagation();
    }
  }

  validateResponse(event) {
    const input = document.getElementById(
      event.currentTarget.dataset.validateTarget
    );
    this.validateInput(event, input, false);
  }

  validateNewResponse(event) {
    const input = this.formTarget.querySelector("textarea");
    this.validateInput(event, input, true);
  }

  validateInput(event, input, newResponse) {
    const valid = input.checkValidity();
    const errorTarget = event.currentTarget.parentNode.previousElementSibling;

    if (!valid) {
      event.preventDefault();
      event.stopImmediatePropagation();
      errorTarget.classList.remove("invisible");
      input.focus();
      input.blur();
    } else {
      errorTarget.classList.add("invisible");
      if (newResponse) {
        this.closeCurrentModal(event)
      } else {
        this.nextModal(event);
      }
    }
  }

  submitForm() {
    const errorTarget = this.emailErrorTarget;
    const emailTarget = this.fieldTargets[1].parentNode;

    Rails.ajax({
      type: "post",
      url: this.formTarget.action,
      data: new FormData(this.formTarget),
      success: function () {
        errorTarget.classList.add("hidden");
        emailTarget.classList.remove("mdc-text-field--invalid");
        window.location.reload();
      },
      error: function (data) {
        errorTarget.innerHTML = JSON.parse(data).errors[0];
        errorTarget.classList.remove("hidden");
        emailTarget.classList.add("mdc-text-field--invalid");
      },
    });
  }

  addSupporter(data) {
    const list = document.getElementById("supporters-list");
    const newCard = document.getElementById("new-supporter").cloneNode(true);
    newCard.style.display = "block";

    newCard.querySelector(".supporter-name").innerHTML = "test";
    newCard.querySelector(".supporter-email").innerHTML = "test";

    this.supportersListTarget.appendChild(newCard);
  }

  fillSupporterDetails(event) {
    const name = event.currentTarget.dataset.name;
    const path = event.currentTarget.dataset.path;
    this.supporterNameTarget.innerHTML = ` ${name} `;
    this.supporterPathTarget.href = path;
  }

  edit(event) {
    if (this.supporterTargets[0].classList.contains("hidden")) {
      this.hideTargets(this.unreadCountTargets);
      this.showTargets(this.supporterTargets);
      event.target.innerHTML = "Done";
    } else {
      this.hideTargets(this.supporterTargets);
      this.showTargets(this.unreadCountTargets);
      event.target.innerHTML = "Edit";
    }
  }

  showTargets(targets) {
    targets.forEach((element) => {
      element.classList.remove("hidden");
    });
  }

  hideTargets(targets) {
    targets.forEach((element) => {
      element.classList.add("hidden");
    });
  }

  closeCurrentModal(event) {
    const closeModal = event.currentTarget.closest(".micromodal-slide");
    MicroModal.close(closeModal.id);
  }
}
