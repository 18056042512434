import Daily_actions_controller from '../daily_actions_controller'

export default class extends Daily_actions_controller {
  static targets = [
    'groundingItemOption',
    'groundingItemOptionCustom',
    'groundingItemError',
    'feelingOption',
    'feelingOptionCustom',
    'feelingError',
    'seeingOption',
    'seeingError',
    'seeingDisplay',
    'hearingOption',
    'hearingOptionCustom',
    'hearingError',
    'hearingDisplay',
    'displayItem',
    'audioPlayer'
  ]

  displaySeeing () {
    const item = this.displayItemTarget.content.querySelector('div')
    this.seeingDisplayTarget.replaceChildren()

    this.seeingOptionTargets.forEach((elem) => {
      if (elem.value !== '') {
        const newItem = item.cloneNode(true)
        newItem.innerHTML = elem.value
        this.seeingDisplayTarget.appendChild(newItem)
      }
    })
  }

  displayHearing () {
    const item = this.displayItemTarget.content.querySelector('div')
    this.hearingDisplayTarget.replaceChildren()
    const customInputs = []
    const defaultInputs = []

    this.hearingOptionTargets.forEach((target) => {
      if (target.checked) {
        defaultInputs.push(target.value)
      }
    })

    this.hearingOptionCustomTargets.forEach((target) => {
      customInputs.push(target.querySelector('input').value)
    })

    const hearingOptions = defaultInputs.concat(customInputs)

    hearingOptions.forEach((elem) => {
      if (elem !== '') {
        const newItem = item.cloneNode(true)
        newItem.innerHTML = elem
        this.hearingDisplayTarget.appendChild(newItem)
      }
    })
  }

  clearGroundingItems () {
    this.groundingItemOptionTargets.forEach((option) => {
      option.checked = ''
    })
  }

  clearCustomField () {
    this.groundingItemOptionCustomTarget.getElementsByTagName(
      'input'
    )[0].value = ''

    this.groundingItemOptionCustomTarget.querySelector(
      '#char-counter'
    ).innerHTML = '0'
  }

  checkGroundingItem (event) {
    let filled = false

    if (
      this.groundingItemOptionCustomTarget.getElementsByTagName('input')[0]
        .value.length !== 0
    ) {
      filled = true
    }

    this.groundingItemOptionTargets.forEach((option) => {
      if (option.checked) {
        filled = true
      }
    })

    if (!filled) {
      this.throwError(event, this.groundingItemErrorTarget)
    } else {
      this.groundingItemErrorTarget.style.display = 'none'
    }
  }

  checkFeeling (event) {
    let filled = false

    this.feelingOptionCustomTargets.forEach((option) => {
      if (option.querySelector('input').value.length !== 0) {
        filled = true
      }
    })

    this.feelingOptionTargets.forEach((option) => {
      if (option.checked) {
        filled = true
      }
    })

    if (!filled) {
      this.throwError(event, this.feelingErrorTarget)
    } else {
      this.feelingErrorTarget.style.display = 'none'
    }
  }

  checkSeeing (event) {
    let filled = true

    this.seeingOptionTargets.forEach((option) => {
      if (option.value.length == 0) {
        this.showRequiredField(option)
        filled = false
      }
    })

    if (!filled) {
      this.throwError(event, this.seeingErrorTarget)
    } else {
      this.seeingErrorTarget.style.display = 'none'
    }
  }

  checkHearing (event) {
    let filled = false
    this.hearingOptionCustomTargets.forEach((option) => {
      if (option.querySelector('input').value.length !== 0) {
        filled = true
      }
    })

    this.hearingOptionTargets.forEach((option) => {
      if (option.checked) {
        filled = true
      }
    })

    if (!filled) {
      this.throwError(event, this.hearingErrorTarget)
    } else {
      this.hearingErrorTarget.style.display = 'none'
    }
  }

  throwError (event, target) {
    event.stopImmediatePropagation()
    target.style.display = 'block'
    target.scrollIntoView({ behavior: 'smooth' })
  }

  showRequiredField (target) {
    target.focus()
    target.blur()
  }
}
