import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["track", "thumb", "point", "realInput"];

  clicked() {
    window.addEventListener("mousemove", this.moveThumb(event));
  }

  moveThumb(event) {
    let closest = this.pointTargets.first;
    let smallest_distance = null;

    this.pointTargets.forEach(function (element, index) {
      const rect = element.getBoundingClientRect();
      const distance = Math.abs(event.x - rect.left);

      if (smallest_distance === null || distance < smallest_distance) {
        smallest_distance = distance;
        closest = element;
      }
    });

    this.thumbTarget.style.left = `${
      closest.getBoundingClientRect().left -
      this.trackTarget.getBoundingClientRect().left -
      14
    }px`;
    this.realInputTarget.value = closest.dataset.value;
    this.realInputTarget.dispatchEvent(new Event("input", { bubbles: true }));
    this.thumbTarget.firstElementChild.innerHTML = this.realInputTarget.value;
  }
}
